import { getUserData } from "@/api/user";
import { STATE_USER } from "@/lib/constants";
import { VuexAction } from "@/lib/types/base";
import { IUserDetails, IUserState } from "@/lib/types/user";

export const moduleUser = {
  namespaced: true,

  // ! Do not mutate the constant (STATE_X) directly
  state: () => ({ ...STATE_USER }),

  mutations: {
    SET_USER_DETAILS (state: IUserState, payload: IUserDetails): void {
      state.details = { ...state.details, ...payload };
    },

    SET_USER_DETAILS_LOADED (state: IUserState, payload: boolean): void {
      state.details_loaded = payload;
    },
  },

  actions: {
    async setUserDetails ({ commit }: VuexAction): Promise<IUserDetails> {
      return new Promise((resolve, reject) => {
        commit("SET_USER_DETAILS_LOADED", false);

        getUserData()
          .then(res => {
            commit("SET_USER_DETAILS", res);
            resolve(res);
          }).catch(err => {
            reject(err);
          }).finally(() => {
            commit("SET_USER_DETAILS_LOADED", true);
          });
      });
    },
  },

  getters: {
    roles: (state: IUserState) => ({
      staff: state.details.is_staff,
      admin: state.details.is_organization_admin,
      superuser: state.details.is_superuser,
      organizationType: state.details.organization_type,
    }),
  },
};
