// eslint-disable-next-line
// @ts-ignore
import { organizationsApi } from "../../plugins/greenlink";
import { IGridOperatorDetails } from "@/lib/types/organizations/grid_operator";
import { IListResponse } from "@/lib/types/base";

export function getGridOperatorsList (opts?: Partial<IGridOperatorDetails>) {
  return new Promise<IListResponse<IGridOperatorDetails>>((resolve, reject) => {
    organizationsApi.organizationsGridoperatorsList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getGridOperatorData (id: string) {
  return new Promise<IGridOperatorDetails>((resolve, reject) => {
    organizationsApi.organizationsGridoperatorsRetrieve(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateGridOperatorData (id: string, patch: Partial<IGridOperatorDetails>) {
  return new Promise<IGridOperatorDetails>((resolve, reject) => {
    organizationsApi.organizationsGridoperatorsUpdate(id, patch, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
