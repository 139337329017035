






















import { defineComponent } from "@vue/composition-api";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/tr";
// import "vue2-datepicker/locale/de";

export default defineComponent({
  name: "GlobalDatePicker",
  components: {
    DatePicker,
  },
  data() {
    return {
      datetime: [] as string[],
      opened: 0,
      closed: 0,
      picked: "",
      monthly: false,
    };
  },
  mounted() {
    this.datetime = this.$store.state.period;

    this.$watch(
      () => [this.datetime, this.$store.state.period],
      ([newDatetime, newPeriod], [oldDatetime, oldPeriod]) => {
        // ! Watch $store.state.period
        if (JSON.stringify(newPeriod) !== JSON.stringify(oldPeriod)) {
          this.datetime = [newPeriod[0], newPeriod[1]];
        }
        if (
          JSON.stringify(newDatetime) !== JSON.stringify(oldDatetime) &&
          JSON.stringify(newDatetime) !== JSON.stringify(newPeriod)
        ) {
          const [startTime, endTime] = newDatetime;
          this.$store.dispatch("setCurrentPeriod", [
            startTime + "T00:00:00+03:00",
            endTime + "T23:59:00+03:00",
          ]);
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    pickFn(date: Date) {
      if (this.opened > this.closed) this.picked = date.toString();
    },
    openFn() {
      this.opened++;
    },
    closeFn() {
      this.closed++;
      this.picked = "";
    },
    disabledAfter(date: Date) {
      const format = "YYYY-MM-DDT00:00:00+03:00";

      const picked = dayjs(this.picked).format(format);
      const tomorrow = dayjs().add(1, "day").format(format);

      const minus31 = (date: Date | string) =>
        dayjs(date).subtract(31, "day").format(format);
      const plus31 = (date: Date | string) =>
        dayjs(date).add(31, "day").format(format);

      if (this.$route.name === "Match") {
        this.monthly = this.$store.state.isOrganizationalMatching;
      } else {
        this.monthly = false;
      }

      if (this.monthly) {
        // ! Monthly Behavior
        return (
          date >= new Date(plus31(picked)) ||
          date <= new Date(minus31(picked)) ||
          date >= new Date(tomorrow)
        );
      } else {
        // ! Normal Behavior
        return date >= new Date(tomorrow);
      }
    },
  },
});
