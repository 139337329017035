
















































































































































































































































































































import { computed, defineComponent } from "@vue/composition-api";
import dayjs from "dayjs";
import { seperateThousand } from "@/utils";
import { getMatchedEnergyData } from "@/api/energy-data/match_data";
import { mapGetters } from "vuex";
import { OrganizationTypes } from "@/lib/types/base";

export default defineComponent({
  name: "ExpandDetails",
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      matchedTxs: [] as any[],
      matchedSites: [] as any[],
      matchedData: [] as any[],
    };
  },
  computed: {
    ...mapGetters({ roles: "user/roles" }),
  },
  mounted() {
    this.matchedTxHash();

    this.$watch(
      () => this.matchedTxs,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.matchedData.length = 0;

          for (const site of this.matchedSites) {
            let siteTx: string;
            let matchedObj: { [key: string]: any; } = {};

            if (this.$route.name?.toLowerCase().includes("consumption")) {
              siteTx = this.matchedTxs.filter(tx => site.id === tx.production_site)[0].tx?.hash ?? "";

              matchedObj = {
                id: site.id,
                name: site.name,
                location: [site.location.latitude, site.location.longitude],
                tx: siteTx,
              };

              /*  */
            } else if (this.$route.name?.toLowerCase().includes("production")) {
              siteTx = this.matchedTxs.filter(tx => site.id === tx.consumption_site)[0].tx?.hash ?? "";

              matchedObj = {
                id: site.id,
                name: site.name,
                location: [site.location.latitude, site.location.longitude],
                tx: siteTx,
              };
            }

            this.matchedData.push(matchedObj);
          }
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    formatDate(date: string | Date) {
      return dayjs(date).format("dddd, D MMMM YYYY");
    },

    formatTime(date: string | Date) {
      return dayjs(date).format("HH:mm");
    },

    formatWhole(date: string | Date) {
      return this.formatDate(date) + ", " + this.formatTime(date);
    },

    openSiteBlank(route: string, params: any) {
      const routeData = this.$router.resolve({ name: route, params, query: this.$route.query });
      window.open(routeData.href, "_blank");
    },

    async fetchMatched(dataType: "consumptionData" | "productionData") {
      const payload = {} as { consumptionData?: number; productionData?: number };

      payload[dataType] = this.data.id;

      getMatchedEnergyData(payload)
        .then(res => {
          if (res.count > 0) (this.matchedTxs = res.results);
          else (this.matchedTxs.length = 0);
        });
    },

    async matchedTxHash() {
      if (this.$route.name?.toLowerCase().includes("consumption")) {
        await this.fetchMatched("consumptionData");
        this.matchedSites = this.data.production_sites_matched;
      } else if (this.$route.name?.toLowerCase().includes("production")) {
        await this.fetchMatched("productionData");
        this.matchedSites = this.data.consumption_sites_matched;
      }
    },
  },
  setup(props) {
    const amountMatched = computed(() => (
      props.data.amount_matched_wind +
      props.data.amount_matched_solar +
      props.data.amount_matched_hydro +
      props.data.amount_matched_marine +
      props.data.amount_matched_thermal +
      props.data.amount_matched_solid +
      props.data.amount_matched_liquid +
      props.data.amount_matched_gaseous
    ));

    return { amountMatched, seperateThousand, OrganizationTypes };
  },
});
