
























































































import { deleteUserData } from "@/api/user";
import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { Notification } from "element-ui";

export default defineComponent({
  name: "UserDetailsCard",
  components: {
    UserCrudForm: () => import("../forms/UserCrudForm.vue"),
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      editModalVisible: false,
      isRouterAlive: true,
      userCrudFormValue: false,
    };
  },
  computed: {
    ...mapGetters({
      roles: "user/roles",
    }),
  },
  methods: {
    personUpdated() {
      this.$emit("onSuccess", true);
      this.editModalVisible = false;
    },
    deleteConsumer() {
      this.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${`${this.details.first_name} ${this.details.last_name}`}</code>?
        `,
        /* Title */
        this.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: this.$i18n.t("labels.delete") as string,
          cancelButtonText: this.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteUserData(this.details.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `User "${this.details.first_name + " " + this.details.last_name}" deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  this.$emit("delete", true);
                  this.personUpdated();
                  done();
                })
                .catch(err => {
                  instance.confirmButtonLoading = false;
                  console.error(JSON.stringify(err.body.detail));
                });
              /*  */
            } else {
              done();
            }
          },
        });
    },

    changeF(newVal: any) {
      this.userCrudFormValue = newVal;
    },
    closeDialog() {
      if (this.userCrudFormValue) {
        this.$confirm(
        /* Message */
        this.$i18n.t("messages.sure_to_close") as string,
        /* Title */
        this.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: "OK",
          cancelButtonText: this.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              this.isRouterAlive = false;
              this.$nextTick(() => {
                this.isRouterAlive = true;
              });
              this.editModalVisible = false;
              instance.confirmButtonLoading = false;
              done();
              /*  */
            } else {
              done();
            }
          },
        });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.editModalVisible = false;
        this.userCrudFormValue = false;
      }
    },
  },
});
