





































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ProducersListFilter",
  model: {
    prop: "filters",
    event: "update:change",
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    filterSets: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data () {
    return {};
  },
});
