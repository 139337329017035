<template>
  <div
    class="organization-details"
    v-if="details"
  >
    <div class="w-100">
      <table class="scan-table">
        <tbody>
          <tr>
            <td>{{ $t("labels.name") }}</td>
            <td>{{ details.name }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.id") }}</td>
            <td>
              <Clipboard :value="details.id" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.bc_address") }}</td>
            <td>
              <Clipboard
                :value="details.blockchain_account_address"
                :page-url="$store.state.addressUrl"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.website_url") }}</td>
            <td>{{ details.website_url }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.location") }}</td>
            <td>
              <details>
                <summary>{{ $t("labels.details") }}</summary>
                <table
                  class="scan-table"
                >
                  <tbody>
                    <tr>
                      <td>{{ $t("labels.latitude") }}</td>
                      <td>{{ details.location.latitude }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.longitude") }}</td>
                      <td>{{ details.location.longitude }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.country") }}</td>
                      <td>{{ details.location.country }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.city") }}</td>
                      <td>{{ details.location.city }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.province") }}</td>
                      <td>{{ details.location.province_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.district") }}</td>
                      <td>{{ details.location.district_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.neighborhood") }}</td>
                      <td>{{ details.location.neighborhood_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.street") }}</td>
                      <td>{{ details.location.street_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.building_number") }}</td>
                      <td>{{ details.location.building_number }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.independent_section_code") }}</td>
                      <td>{{ details.location.independent_section_code }}</td>
                    </tr>
                  </tbody>
                </table>
              </details>
            </td>
          </tr>
          <!--  -->
          <tr v-if="details.retailer">
            <td>{{ $t("common.retailer") }}</td>
            <td>{{ details.retailer_name || details.retailer }}</td>
          </tr>
          <!--  -->
          <tr v-if="typeof details.is_registrant === 'boolean'">
            <td>{{ $t("labels.is_registrant") }}</td>
            <td>{{ details.is_registrant ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <!--  -->
          <tr v-if="typeof details.is_distribution_system_operator === 'boolean'">
            <td>{{ $t("labels.is_DSO") }}</td>
            <td>{{ details.is_distribution_system_operator ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <!--  -->
          <tr v-if="typeof details.is_irec_issuer === 'boolean'">
            <td>{{ $t("labels.is_irec_issuer") }}</td>
            <td>{{ details.is_irec_issuer ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <!--  -->
          <tr>
            <td>{{ $t("labels.is_active") }}</td>
            <td>{{ details.is_active ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.created_at") }}</td>
            <td>
              {{ timeAgo(details.created_at) }}
              ({{ formatDate(details.created_at) }})
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.updated_at") }}</td>
            <td>
              {{ timeAgo(details.updated_at) }}
              ({{ formatDate(details.updated_at) }})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div v-else>
    No details found.
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { formatDate, timeAgo } from "@/plugins/dayjs";

export default defineComponent({
  name: "OrganizationDetails",
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    return { formatDate, timeAgo };
  },
});
</script>
