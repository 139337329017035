

































import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "@vue/composition-api";
import { aggregatedChartLayout, chartColors } from "@/helpers/chart";
import { IConsumptionDataHourlyAggregated } from "@/lib/types/energy-data/consumption_data";
import { ChartDataSets } from "chart.js";
import { IProductionDataHourlyAggregated } from "@/lib/types/energy-data/production_data";
import { arrSum, randomId } from "@/utils";
import { rootInstance } from "@/helpers/composition";

interface IPropsType {
  chartType: "consumption" | "production";
  sourceType: string;
  consumptionData: IConsumptionDataHourlyAggregated[];
  productionData: IProductionDataHourlyAggregated[];
  type: string;
}

export default defineComponent({
  name: "AggregatedChart",
  props: {
    chartType: {
      type: String,
      default: "consumption",
      required: false,
    },
    sourceType: {
      type: String,
      default: "matched",
      required: false,
    },
    consumptionData: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    productionData: {
      type: Array,
      default: () => ([]),
      required: false,
    },
  },
  setup (props: IPropsType, vm) {
    const { root } = rootInstance();

    const id = ref(randomId());

    const state = reactive({
      type:
        props.chartType.length ? props.chartType
          : (props.consumptionData.length && !props.productionData.length)
            ? "consumption"
            : (!props.consumptionData.length && props.productionData.length)
              ? "production"
              : "consumption",
      /*  */
      consumption: [...props.consumptionData],
      production: [...props.productionData],
      empty: false,
      theme: computed<string>(() => root.$store.state.theme),
    });

    onMounted(() => {
      const ctx = document.getElementById(id.value) as HTMLCanvasElement;
      let key = 0;

      watch(
        () => [state.consumption, state.production, state.type, state.theme],
        async () => {
          key++;
          vm.emit("state-change", key++);

          if (state.type === "consumption") {
            const datasets = [] as ChartDataSets[];

            const hour = [] as string[];
            const consumed = [] as number[];
            const solar = [] as number[];
            const wind = [] as number[];
            const hydro = [] as number[];
            const marine = [] as number[];
            const thermal = [] as number[];
            const solid = [] as number[];
            const liquid = [] as number[];
            const gaseous = [] as number[];
            const matched = [] as number[];
            const unmatched = [] as number[];

            state.consumption.forEach(data => {
              hour.push(data.hour);
              consumed.push(data.total_amount_consumed ? data.total_amount_consumed / 1000 : 0);
              solar.push(data.total_amount_matched_solar ? data.total_amount_matched_solar / 1000 : 0);
              wind.push(data.total_amount_matched_wind ? data.total_amount_matched_wind / 1000 : 0);
              hydro.push(data.total_amount_matched_hydro ? data.total_amount_matched_hydro / 1000 : 0);
              marine.push(data.total_amount_matched_marine ? data.total_amount_matched_marine / 1000 : 0);
              thermal.push(data.total_amount_matched_thermal ? data.total_amount_matched_thermal / 1000 : 0);
              solid.push(data.total_amount_matched_solid ? data.total_amount_matched_solid / 1000 : 0);
              liquid.push(data.total_amount_matched_liquid ? data.total_amount_matched_liquid / 1000 : 0);
              gaseous.push(data.total_amount_matched_gaseous ? data.total_amount_matched_gaseous / 1000 : 0);
              /*  */
              matched.push(
                data.total_amount_matched_solar / 1000 +
                data.total_amount_matched_wind / 1000 +
                data.total_amount_matched_hydro / 1000 +
                data.total_amount_matched_marine / 1000 +
                data.total_amount_matched_thermal / 1000 +
                data.total_amount_matched_solid / 1000 +
                data.total_amount_matched_liquid / 1000 +
                data.total_amount_matched_gaseous / 1000
              );
              unmatched.push(
                data.total_amount_consumed / 1000 - (
                  data.total_amount_matched_solar / 1000 +
                  data.total_amount_matched_wind / 1000 +
                  data.total_amount_matched_hydro / 1000 +
                  data.total_amount_matched_marine / 1000 +
                  data.total_amount_matched_thermal / 1000 +
                  data.total_amount_matched_solid / 1000 +
                  data.total_amount_matched_liquid / 1000 +
                  data.total_amount_matched_gaseous / 1000
                )
              );
            });

            if (arrSum(matched) + arrSum(unmatched) === 0) state.empty = true;
            else state.empty = false;

            if (arrSum(solar)) {
              datasets.push({
                label: root.$i18n.t("common.solar") as string,
                data: solar,
                backgroundColor: chartColors.Solar,
              });
            }
            if (arrSum(wind)) {
              datasets.push({
                label: root.$i18n.t("common.wind") as string,
                data: wind,
                backgroundColor: chartColors.Wind,
              });
            }
            if (arrSum(hydro)) {
              datasets.push({
                label: root.$i18n.t("common.hydro") as string,
                data: hydro,
                backgroundColor: chartColors["Hydro-electric Head"],
              });
            }
            if (arrSum(marine)) {
              datasets.push({
                label: root.$i18n.t("common.marine") as string,
                data: marine,
                backgroundColor: chartColors.Marine,
              });
            }
            if (arrSum(thermal)) {
              datasets.push({
                label: root.$i18n.t("common.thermal") as string,
                data: thermal,
                backgroundColor: chartColors.Thermal,
              });
            }
            if (arrSum(solid)) {
              datasets.push({
                label: root.$i18n.t("common.solid") as string,
                data: solid,
                backgroundColor: chartColors.Solid,
              });
            }
            if (arrSum(liquid)) {
              datasets.push({
                label: root.$i18n.t("common.liquid") as string,
                data: liquid,
                backgroundColor: chartColors.Liquid,
              });
            }
            if (arrSum(gaseous)) {
              datasets.push({
                label: root.$i18n.t("common.gaseous") as string,
                data: gaseous,
                backgroundColor: chartColors.Gaseous,
              });
            }
            if (arrSum(unmatched)) {
              datasets.push({
                label: root.$i18n.t("common.unmatched") as string,
                data: unmatched,
                backgroundColor: chartColors.Unmatched,
              });
            }

            aggregatedChartLayout(ctx, datasets, state.theme);

            /**
             * ! PRODUCTION
            **/
          } else if (state.type === "production") {
            const datasets = [] as ChartDataSets[];

            const hour = [] as string[];
            const matched = [] as number[];
            const unmatched = [] as number[];

            state.production.forEach(data => {
              hour.push(data.hour);
              matched.push(data.total_amount_matched / 1000);
              unmatched.push(data.total_amount_produced / 1000 - data.total_amount_matched / 1000);
            });

            if (arrSum(matched) + arrSum(unmatched) === 0) state.empty = true;
            else state.empty = false;

            if (arrSum(matched)) {
              datasets.push({
                label: root.$i18n.t("common.matched") as string,
                data: matched,
                backgroundColor: chartColors[props.sourceType] || chartColors.Matched,
              });
            }
            if (arrSum(unmatched)) {
              datasets.push({
                label: root.$i18n.t("common.unmatched") as string,
                data: unmatched,
                backgroundColor: chartColors.Unmatched,
              });
            }

            aggregatedChartLayout(ctx, datasets, state.theme);
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
    });

    return {
      ...toRefs(state),
      id,
    };
  },
});
