<template>
  <el-dropdown
    :hide-timeout="0"
    :show-timeout="0"
    placement="bottom-end"
    trigger="click"
    class="dropdown--user"
    @command="handleCommand"
  >
    <button class="d-flex align-items-center">
      <span class="user__img d-inline-flex align-items-center justify-content-center">
        {{ user.first_name.slice(0,1) }}{{ user.last_name.slice(0,1) }}
      </span>
    </button>

    <el-dropdown-menu slot="dropdown">
      <div
        class="pb-2 pt-4"
        style="width: 360px"
      >
        <div class="px-3 mb-4">
          <p class="font-semibold m-0">
            {{ user.first_name }} {{ user.last_name }}
          </p>
          <p
            class="text-xs m-0 mt-1 truncate"
            v-if="organization !==null && organization.name"
          >
            {{ organization.name }}
          </p>

          <div class="mt-3">
            <label class="d-block text-sm font-normal">{{ $t("labels.theme") }}</label>
            <ThemeSwitch />
          </div>
          <div class="mt-3">
            <label class="d-block text-sm font-normal">{{ $t("labels.language") }}</label>
            <LocaleChanger />
          </div>
          <div class="mt-3">
            <label class="d-block text-sm font-normal">Timezone</label>
            <div class="text-xs font-medium mt-1">
              {{ localTimezone }}
            </div>
          </div>
        </div>

        <div class="divider"></div>

        <template v-if="(roles.admin || roles.superuser) && [OrganizationTypes.RETAILER, OrganizationTypes.CONSUMER].includes(roles.organizationType)">
          <el-dropdown-item command="users">
            {{ $t("labels.users") }}
          </el-dropdown-item>
        </template>

        <el-dropdown-item command="settings">
          {{ $t("labels.settings") }}
        </el-dropdown-item>

        <div class="divider"></div>

        <el-dropdown-item command="logout">
          {{ $t("labels.logout") }}
        </el-dropdown-item>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import ThemeSwitch from "@/components/ThemeChanger.vue";

import { mapState } from "vuex";
import { camelToSplit } from "../utils";
import LocaleChanger from "./LocaleChanger.vue";
import { OrganizationTypes } from "@/lib/types/base";
import { localTimezone } from "@/plugins/dayjs";
import { signOut } from "@/plugins/firebase";
import { Notification } from "element-ui";

export default {
  name: "UserDropdown",
  components: {
    ThemeSwitch,
    LocaleChanger,
  },
  computed: {
    ...mapState({
      user: state => state.user.details,
      organization: state => state.retailer ? state.retailer.details
        : state.gridOperator ? state.gridOperator.details
          : state.consumer ? state.consumer.details
            : state.producer ? state.producer.details
              : null,
    }),
    roles() {
      return this.$store.getters["user/roles"];
    },
  },
  methods: {
    logout() {
      signOut().then(() => {
        this.$router.push({ name: "LoginPage" });
      }).catch(e => {
        Notification.error({
          title: "Error logging out",
          message: e.message,
        });
      });
    },
    handleCommand(command) {
      if (command === "users") this.$router.push({ name: "PeopleList" });
      if (command === "settings") this.$router.push({ name: "Settings" });
      if (command === "logout") this.logout();
    },
  },
  setup() {
    return { camelToSplit, OrganizationTypes, localTimezone };
  },
};
</script>
