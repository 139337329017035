

















































































































































import { computed, defineComponent, reactive, toRefs, watch } from "@vue/composition-api";
import { getProductionSitesList } from "@/api/energy-assets/production_site";

import { dynamicSort, slugify } from "@/utils";
import { IIssuerDetails } from "@/lib/types/organizations/issuer";
import { IProducerDetails } from "@/lib/types/organizations/producer";
import { IGridOperatorDetails } from "@/lib/types/organizations/grid_operator";
import { IProductionSiteDetails } from "@/lib/types/energy-assets/production_site";
import { OrganizationTypes } from "@/lib/types/base";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "ProductionSitesList",
  components: {
    ProductionSitesListFilter: () => import("@/components/production-site/ProductionSitesListFilter.vue"),
    ProductionSiteUploadForm: () => import("@/components/forms/ProductionSiteUploadForm.vue"),
    ProductionSiteCrudForm: () => import("@/components/forms/ProductionSiteCrudForm.vue"),
  },
  props: {
    producerId: {
      type: String,
      default: null,
      required: true,
    },
  },
  setup (props) {
    const { root } = rootInstance();

    const state = reactive({
      productionSites: [] as IProductionSiteDetails[],
      productionSitesLoaded: false,
      limit: 24,
      offset: 0,
      search: "",
      filterSets: reactive({
        city: [] as string[],
        issuer: [] as string[],
        producer: [] as string[],
        retailer: [] as string[],
        gridOperator: [] as string[],
      }),
      filters: reactive({
        city: [] as string[],
        issuer: [] as string[],
        producer: [] as string[],
        retailer: [] as string[],
        gridOperator: [] as string[],
      }),
      currentTab: "crud",
      openFilter: false,
      createModalVisible: false,
      isRouterAlive: true,
      productionSiteFormValue: false,
    });

    const roles = computed(() => root.$store.getters["user/roles"]);

    const hasFilters = computed(() => {
      return Object.values(state.filterSets as Record<string, any[]>).some((element: any[]) => {
        return element.length > 1;
      });
    });

    const productionSitesListDataFn = async () => {
      state.productionSitesLoaded = false;

      await getProductionSitesList({
        producer: props.producerId,
        limit: Number.MAX_SAFE_INTEGER,
        offset: state.offset,
      })
        .then(res => {
          if (res.results) {
            state.productionSites = res.results.sort(dynamicSort("-created_at"));
          }

          state.productionSitesLoaded = true;
        })
        // .catch(err => alert(JSON.stringify(err.body)));
        .catch(() => console.error("ProductionSitesList.vue productionSitesListDataFn"));
    };

    productionSitesListDataFn();

    const filteredList = computed(() => {
      let results = state.productionSites
        ? [...state.productionSites.sort(dynamicSort("-created_at"))]
        : [];

      if (state.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.name).includes(slugify(state.search)) ||
            slugify(res.blockchain_account_address).includes(slugify(state.search)) ||
            slugify(res.eic).includes(slugify(state.search)) ||
            slugify(res.id).includes(slugify(state.search))
          );
        });
      }

      if (state.filters.city.length) {
        results = results.filter(res => {
          return state.filters.city.includes(res.location.city);
        });
      }

      if (state.filters.issuer.length) {
        results = results.filter(res => {
          if (res.issuer as IIssuerDetails) return state.filters.issuer.includes((res.issuer as IIssuerDetails).name);
          else return res.issuer === null;
        });
      }

      if (state.filters.producer.length) {
        results = results.filter(res => {
          if ((res.producer as IProducerDetails)) return state.filters.producer.includes((res.producer as IProducerDetails).name);
          else return res.producer === null;
        });
      }

      if (state.filters.gridOperator.length) {
        results = results.filter(res => {
          if ((res.grid_operator as IGridOperatorDetails)) return state.filters.gridOperator.includes((res.grid_operator as IGridOperatorDetails).name);
          else return res.grid_operator === null;
        });
      }

      return results;
    });

    const siteCreated = () => {
      productionSitesListDataFn();
      state.isRouterAlive = false;
      root.$nextTick(() => {
        state.isRouterAlive = true;
      });
      state.createModalVisible = false;
    };

    watch(
      () => state.productionSites,
      newVal => {
        if (newVal.length) {
          const citySet = new Set<string>();
          const issuerSet = new Set<string>();
          const producerSet = new Set<string>();
          const retailerSet = new Set<string>();
          const gridOperatorSet = new Set<string>();

          state.productionSites.forEach(site => {
            citySet.add(site.location.city);
            producerSet.add(site.producer ? (site.producer as Record<string, any>).name : "-");
            issuerSet.add(site.issuer ? (site.issuer as Record<string, any>).name : "-");
            gridOperatorSet.add(site.grid_operator ? (site.grid_operator as Record<string, any>).name : "-");
          });

          state.filterSets.city = [...citySet].sort();
          state.filterSets.issuer = [...issuerSet].sort();
          state.filterSets.producer = [...producerSet].sort();
          state.filterSets.retailer = [...retailerSet].sort();
          state.filterSets.gridOperator = [...gridOperatorSet].sort();
        }
      },
      { deep: true }
    );
    const changeF = (newVal: any) => {
      state.productionSiteFormValue = newVal;
    };
    const closeDialog = () => {
      if (state.productionSiteFormValue) {
        root.$confirm(
        /* Message */
        root.$i18n.t("messages.sure_to_close") as string,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: "OK",
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              state.currentTab = "crud";
              state.isRouterAlive = false;
              root.$nextTick(() => {
                state.isRouterAlive = true;
              });
              state.createModalVisible = false;
              instance.confirmButtonLoading = false;
              done();
              /*  */
            } else {
              done();
            }
          },
        });
      } else {
        state.currentTab = "crud";
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.createModalVisible = false;
        state.productionSiteFormValue = false;
      }
    };
    return { ...toRefs(state), roles, OrganizationTypes, filteredList, siteCreated, hasFilters, closeDialog, changeF };
  },
});
