
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import i18n from "../i18n";

import { authGuard } from "@/auth/authGuard";
import { OrganizationTypes } from "@/lib/types/base";

import Applayout from "@/views/app/AppLayout.vue";
import Consumers from "@/views/app/pages/consumers/index.vue";
import Producers from "@/views/app/pages/producers/index.vue";
import NotFound from "@/views/NotFound.vue";

// import { authenticationGuard } from "@/auth/authenticationGuard";
// import { Message } from "element-ui";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Applayout,
    redirect: { name: "Overview" },
    beforeEnter: authGuard,
    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import("../views/app/pages/Overview.vue"),
      },
      {
        path: "people",
        name: "PeopleList",
        component: () => import("../views/app/pages/people/PeopleList.vue"),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: "consumers",
        component: Consumers,
        redirect: { name: "ConsumersList" },
        meta: {
          organizationType: [OrganizationTypes.RETAILER],
        },
        children: [
          {
            path: "",
            name: "ConsumersList",
            component: () =>
              import("../views/app/pages/consumers/ConsumersList.vue"),
          },
          {
            path: ":id",
            name: "Consumer",
            component: () =>
              import("../views/app/pages/consumers/Consumer.vue"),
          },
        ],
      },
      {
        path: "producers",
        component: Producers,
        redirect: { name: "ProducersList" },
        meta: {
          organizationType: [OrganizationTypes.RETAILER],
        },
        children: [
          {
            path: "",
            name: "ProducersList",
            component: () =>
              import("../views/app/pages/producers/ProducersList.vue"),
          },
          {
            path: ":id",
            name: "Producer",
            component: () =>
              import("../views/app/pages/producers/Producer.vue"),
          },
        ],
      },
      {
        path: "consumption-sites",
        name: "ConsumptionSitesList",
        component: () =>
          import(
            "../views/app/pages/consumption-sites/ConsumptionSitesList.vue"
          ),
        meta: {
          organizationType: [
            OrganizationTypes.RETAILER,
            OrganizationTypes.CONSUMER,
          ],
        },
      },
      {
        path: "consumption-sites/:id",
        name: "ConsumptionSite",
        component: () =>
          import("../views/app/pages/consumption-sites/ConsumptionSite.vue"),
        meta: {
          organizationType: [
            OrganizationTypes.RETAILER,
            OrganizationTypes.CONSUMER,
          ],
        },
      },
      {
        path: "production-sites/:id",
        name: "ProductionSite",
        component: () =>
          import("../views/app/pages/production-sites/ProductionSite.vue"),
        meta: {
          organizationType: [
            OrganizationTypes.RETAILER,
            OrganizationTypes.PRODUCER,
          ],
        },
      },
      {
        path: "certificates",
        name: "Certificates",
        component: () =>
          import("../views/app/pages/certificates/Certificates.vue"),
        meta: {
          organizationType: [
            OrganizationTypes.CONSUMER,
          ],
        },
      },
      {
        path: "match",
        name: "Match",
        component: () => import("../views/app/pages/match/Match.vue"),
      },
      {
        path: "logs",
        name: "Logs",
        component: () => import("../views/app/pages/logs/Logs.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../views/app/pages/settings/Settings.vue"),
      },
    ],
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/views/Maintenance.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "LoginPage" },
    component: () => import("@/views/auth/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "LoginPage",
        component: () => import("@/views/auth/pages/LoginPage.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPasswordPage",
        component: () => import("@/views/auth/pages/ForgotPasswordPage.vue"),
      },
      {
        path: "verify-email",
        name: "VerifyEmailPage",
        component: () => import("@/views/auth/pages/VerifyEmailPage.vue"),
      },
      {
        path: "reset-password",
        name: "ResetPasswordPage",
        component: () => import("@/views/auth/pages/ResetPasswordPage.vue"),
      },
    ],
  },
  /* ! 404 */
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
