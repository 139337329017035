var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('labels.name'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__item",class:[
      errors.length ? 'not-valid' : '',
      'required'
    ],attrs:{"data-name":"name"}},[_c('label',{staticClass:"form__item__label",attrs:{"for":"epias-autocomplete"}},[_vm._v(" "+_vm._s(_vm.$t('labels.name'))+" ")]),_c('el-autocomplete',{attrs:{"id":"epias-autocomplete","value-key":"shortName","fetch-suggestions":_vm.querySearch},on:{"select":_vm.handleSelect,"change":_vm.handleChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.shortName)+" ")])]}}],null,true),model:{value:(_vm.epiasSite),callback:function ($$v) {_vm.epiasSite=$$v},expression:"epiasSite"}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(errors.length)?_c('span',{staticClass:"form__item__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }