import { getRetailerData } from "@/api/organizations/retailer";
import { STATE_RETAILER } from "@/lib/constants";
import { VuexAction } from "@/lib/types/base";
import { IRetailerDetails, IRetailerState } from "@/lib/types/organizations/retailer";

export const moduleRetailer = {
  namespaced: true,

  // ! Do not mutate the constant (STATE_X) directly
  state: () => ({ ...STATE_RETAILER }),

  mutations: {
    SET_RETAILER_DETAILS (state: IRetailerState, payload: IRetailerDetails): void {
      state.details = { ...state.details, ...payload };
    },

    SET_RETAILER_DETAILS_LOADED (state: IRetailerState, payload: boolean): void {
      state.details_loaded = payload;
    },
  },

  actions: {
    setRetailerDetails ({ commit, rootState }: VuexAction): Promise<IRetailerDetails> {
      return new Promise((resolve, reject) => {
        commit("SET_RETAILER_DETAILS_LOADED", false);
        const organizationId = rootState.user.details.organization;

        getRetailerData(organizationId)
          .then(res => {
            commit("SET_RETAILER_DETAILS", res);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("SET_RETAILER_DETAILS_LOADED", true);
          });
      });
    },
  },

  getters: {},
};
