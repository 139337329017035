// eslint-disable-next-line
// @ts-ignore
import { usersApi } from "../plugins/greenlink.js";
import { IListResponse } from "@/lib/types/base.js";
import { IUserDetails } from "@/lib/types/user.js";

export function getPeopleList (opts?: Partial<IUserDetails>) {
  return new Promise<IListResponse<IUserDetails>>((resolve, reject) => {
    usersApi.usersList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function createPersonData (payload: Partial<IUserDetails>) {
  return new Promise<IUserDetails>((resolve, reject) => {
    usersApi.usersRegistrationCreate(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updatePersonData (id: string, payload: Partial<IUserDetails>) {
  return new Promise<IUserDetails>((resolve, reject) => {
    usersApi.usersPartialUpdate(id, payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
