var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"locale-changer mt-2"},_vm._l((_vm.langs),function(lang){return _c('div',{key:lang.iso2,staticClass:"locale-changer__radio",class:[
      _vm.language === lang.iso2 ? 'is-active' : null,
      lang.disabled ? 'is-disabled' : null
    ]},[_c('label',{directives:[{name:"tippy",rawName:"v-tippy",value:({
        content: lang.disabled ? ((lang.name) + " - " + (_vm.$t('common.not_available')))
          : _vm.language === lang.iso2 ? ((lang.name) + " - " + (_vm.$t('common.current')))
            : lang.name
      }),expression:"{\n        content: lang.disabled ? `${lang.name} - ${$t('common.not_available')}`\n          : language === lang.iso2 ? `${lang.name} - ${$t('common.current')}`\n            : lang.name\n      }"}],class:lang.disabled ? 'cursor-not-allowed' : 'cursor-pointer',attrs:{"for":lang.iso2}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.language),expression:"language"}],staticClass:"sr-only",attrs:{"id":lang.iso2,"type":"radio","disabled":lang.disabled},domProps:{"value":lang.iso2,"checked":_vm._q(_vm.language,lang.iso2)},on:{"change":function($event){_vm.language=lang.iso2}}}),_c('SvgInjector',{attrs:{"src":require(("@/assets/img/flags/" + (lang.iso2) + ".svg")),"height":"32px"}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }