<template>
  <FormItem
    data-cy="modal-city-select"
    :label="$t('labels.city')"
    :options="options"
    :placeholder="$t('placeholders.select_sth', { msg: $t('labels.city') })"
    :vv-name="$t('labels.city')"
    required
    :value="value"
    @input="handleChange($event)"
    vv-rules="required"
  >
    <template #options="slot">
      <el-option
        :data-cy="`modal-city-${item.city}`"
        v-for="item in slot.options"
        :key="item.plate_code"
        :label="item.city"
        :value="item.city"
      >
      </el-option>
    </template>
  </FormItem>
</template>

<script>
import cities from "@/helpers/cities";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
import { rootInstance } from "@/helpers/composition";
import { dynamicSort } from "@/utils";

export default {
  name: "CitySelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, { emit }) {
    const { root } = rootInstance();
    const state = reactive({
      city: "",
      options: [],
    });

    const handleChange = e => {
      emit("input", e);
    };

    onMounted(() => {
      root.$watch(
        () => props.country,
        newVal => {
          const country = cities.find(item => item.name === newVal);

          if (country !== undefined) {
            state.options = country.cities.sort(dynamicSort("city"));
          } else {
            state.options = [];
          }
        },
        { immediate: true }
      );
    });

    return {
      ...toRefs(state),
      handleChange,
    };
  },
};
</script>
