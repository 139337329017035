import { getFirebaseUser } from "@/plugins/firebase";
import { NavigationGuard } from "vue-router";

/**
 * The example is taken from here:
 * https://stackoverflow.com/a/72922667/10787404
 */
export const authGuard: NavigationGuard = async (to, _from, next) => {
  const user = await getFirebaseUser();
  const redirectQuery = to.fullPath ? { redirect: to.fullPath } : {};

  if (!user) {
    next({ name: "LoginPage", query: redirectQuery });
  } else {
    next();
  }
};
