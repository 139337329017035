
























































































import { defineComponent } from "@vue/composition-api";
import { getPeopleList } from "@/api/people";

import { OrganizationTypes } from "@/lib/types/base";
import { IUserDetails } from "@/lib/types/user";
import { dynamicSort, slugify } from "@/utils";

export default defineComponent({
  name: "ConsumerUsers",
  components: {
    UserCrudForm: () => import("@/components/forms/UserCrudForm.vue"),
    UserDetailsCard: () => import("@/components/people/UserDetailsCard.vue"),
  },
  props: {
    organization: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      people: [] as IUserDetails[] | null,
      peopleLoaded: false,
      createModalVisible: false,
      search: "",
      isRouterAlive: true,
      userCrudFormValue: false,
    };
  },
  computed: {
    roles(): { [key: string]: string } {
      return this.$store.getters["user/roles"];
    },
    filteredList(): IUserDetails[] {
      let results = this.people
        ? [...(this.people as IUserDetails[]).sort(dynamicSort("-created_at"))]
        : [];

      if (this.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.first_name).includes(slugify(this.search)) ||
            slugify(res.last_name).includes(slugify(this.search)) ||
            res.id.includes(slugify(this.search))
          );
        });
      }

      return results;
    },
  },
  async mounted() {
    this.peopleLoaded = false;
    await this.peopleListDataFn();
    this.peopleLoaded = true;
  },
  methods: {
    async peopleListDataFn() {
      this.peopleLoaded = false;

      await getPeopleList({ organization: this.$route.params.id })
        .then(res => {
          const userIndex: number | null = (res.results && res.results.findIndex(x => x.id === this.$store.state.user.details.id));

          (res.results && userIndex && userIndex > -1) && res.results.splice(userIndex, 1);

          if (res.results && typeof userIndex === "number") (this.people = res.results);
        })
        // .catch(err => alert(JSON.stringify(err.body)));
        .catch(() => console.error("ConsumerUsers.vue peopleListDataFn"));

      this.peopleLoaded = true;
    },

    personCreated() {
      this.peopleListDataFn();
      this.createModalVisible = false;
    },

    personUpdated() {
      this.peopleListDataFn();
    },
    changeF(newVal: any) {
      this.userCrudFormValue = newVal;
    },
    closeDialog() {
      if (this.userCrudFormValue) {
        this.$confirm(
          /* Message */
          this.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          this.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: this.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                this.isRouterAlive = false;
                this.$nextTick(() => {
                  this.isRouterAlive = true;
                });
                this.createModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.createModalVisible = false;
        this.userCrudFormValue = false;
      }
    },
  },
  setup() {
    return { OrganizationTypes };
  },
});
