// eslint-disable-next-line
// @ts-ignore
import { energyDataApi } from "../../plugins/greenlink.js";
import { IProductionData, IProductionDataOpts } from "@/lib/types/energy-data/production_data";

export function getProductionDataList (opts?: Partial<IProductionDataOpts> & { orderBy?: [string] | string }) {
  return new Promise<IProductionData>((resolve, reject) => {
    energyDataApi.energyDataProductionDataList(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response.body);
      } else {
        resolve(response.body);
      }
    });
  });
}
