var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datalist datalist--aggregated"},[_c('el-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.consumptionData.length)?_c('el-tab-pane',{attrs:{"label":_vm.$t('common.consumption'),"name":"consumption"}},[_c('el-table',{staticClass:"aligned",attrs:{"data":_vm.consumptionData,"row-key":"id","max-height":"650"}},[_c('el-table-column',{attrs:{"prop":"hour","label":_vm.$t('labels.hour'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.hour)+" ")]}}],null,false,456846047)}),_c('el-table-column',{attrs:{"prop":"total_amount_consumed","label":((_vm.$t('labels.sum_consumed')) + " [kW]"),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatThousand(row.total_amount_consumed))+" ")]}}],null,false,468849029)}),_c('el-table-column',{attrs:{"label":((_vm.$t('labels.sum_matched')) + " [kW]")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatThousand(row.total_amount_matched_solar + row.total_amount_matched_wind + row.total_amount_matched_hydro + row.total_amount_matched_marine + row.total_amount_matched_thermal + row.total_amount_matched_solid + row.total_amount_matched_liquid + row.total_amount_matched_gaseous))+" ")]}}],null,false,2542543980)})],1)],1):_vm._e(),(_vm.productionData.length)?_c('el-tab-pane',{attrs:{"label":_vm.$t('common.production'),"name":"production"}},[_c('el-table',{staticClass:"aligned",attrs:{"data":_vm.productionData,"max-height":"650","row-key":"id"}},[_c('el-table-column',{attrs:{"prop":"hour","label":_vm.$t('labels.hour'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.hour)+" ")]}}],null,false,456846047)}),_c('el-table-column',{attrs:{"prop":"total_amount_produced","label":((_vm.$t('labels.sum_produced')) + " [kW]"),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatThousand(row.total_amount_produced))+" ")]}}],null,false,22107027)}),_c('el-table-column',{attrs:{"prop":"total_amount_matched","label":((_vm.$t('labels.sum_matched')) + " [kW]"),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatThousand(row.total_amount_matched))+" ")]}}],null,false,2077516095)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }