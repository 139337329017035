

























import cities from "@/helpers/cities";
import { defineComponent } from "@vue/composition-api";

 interface IOption {
  id: string;
  value: string;
  name: string;
  lat: number;
  lon: number;
}

export default defineComponent({
  name: "CountrySelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      options: [] as IOption[],
    };
  },
  mounted() {
    this.options = cities.map(item => {
      return {
        id: item.short,
        name: item.name,
        value: item.name,
        lat: item.latitude,
        lon: item.longitude,
      };
    });
  },
  methods: {
    handleChange (value : any) {
      const selected = this.options.filter(item => item.name === value);
      this.$emit("selected", selected);
      this.$emit("input", value);
    },
  },
});
