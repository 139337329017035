




































































import { defineComponent } from "@vue/composition-api";
import { chartColors, pieChartLayout } from "../../helpers/chart";
import { arrSum, capitalize, dynamicSort, randomId, seperateThousand } from "@/utils";

interface IData {
  [key: string]: {
    source: string;
    value: number;
    color: string;
  }
}

export default defineComponent({
  name: "PieChart",
  props: {
    propsData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    showTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartId: randomId(),
      tableData: [] as any[],
      totalAmountMatched: 0,
    };
  },
  computed: {
    theme(): string {
      return this.$store.state.theme;
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId) as HTMLCanvasElement;

    this.$watch(
      () => [this.propsData, this.theme],
      () => {
        const sources: string[] = [];
        const values: number[] = [];
        const colors: string[] = [];
        this.tableData = [] as any[];

        if (
          this.propsData &&
          Object.keys(this.propsData).length &&
          typeof this.propsData.total_amount_consumed === "number"
        ) {
          const matchedObj: IData = {
            solar: {
              source: this.$i18n.t("common.solar") as string,
              value: this.propsData.total_amount_matched_solar,
              color: chartColors.Solar,
            },

            wind: {
              source: this.$i18n.t("common.wind") as string,
              value: this.propsData.total_amount_matched_wind,
              color: chartColors.Wind,
            },

            hydro: {
              source: this.$i18n.t("common.hydro") as string,
              value: this.propsData.total_amount_matched_hydro,
              color: chartColors["Hydro-electric Head"],
            },

            marine: {
              source: this.$i18n.t("common.marine") as string,
              value: this.propsData.total_amount_matched_marine,
              color: chartColors.Marine,
            },

            thermal: {
              source: this.$i18n.t("common.thermal") as string,
              value: this.propsData.total_amount_matched_thermal,
              color: chartColors.Thermal,
            },

            solid: {
              source: this.$i18n.t("common.solid") as string,
              value: this.propsData.total_amount_matched_solid,
              color: chartColors.Solid,
            },

            liquid: {
              source: this.$i18n.t("common.liquid") as string,
              value: this.propsData.total_amount_matched_liquid,
              color: chartColors.Liquid,
            },

            gaseous: {
              source: this.$i18n.t("common.gaseous") as string,
              value: this.propsData.total_amount_matched_gaseous,
              color: chartColors.Gaseous,
            },
          };

          for (const i in matchedObj) {
            const sourceName = matchedObj[i].source;
            const sourceAmount = matchedObj[i].value;
            const sourceColor = matchedObj[i].color;

            sources.push(capitalize(sourceName));
            values.push(sourceAmount);
            colors.push(sourceColor);

            this.tableData.push({
              source: sourceName,
              value: sourceAmount,
              color: sourceColor,
            });
          }

          this.tableData = this.tableData.sort(dynamicSort("-value"));
          this.totalAmountMatched = arrSum(values);

          const chartData = {
            datasets: [
              {
                data: values,
                backgroundColor: colors,
              },
            ],
            labels: sources,
          };
          if (ctx !== null) {
            pieChartLayout(ctx, chartData);
          }
        } else {
          const produced = {
            source: this.$i18n.t("common.matched") as string,
            value: this.propsData.total_amount_matched || this.propsData.total_amount_matched_production,
            color: chartColors.Matched,
          };

          const unmatched = {
            source: this.$i18n.t("common.unmatched") as string,
            value: this.propsData.total_amount_produced - (this.propsData.total_amount_matched || this.propsData.total_amount_matched_production),
            color: chartColors.Unmatched,
          };

          const chartData = {
            datasets: [
              {
                data: [produced.value, unmatched.value],
                backgroundColor: [produced.color, unmatched.color],
              },
            ],
            labels: [produced.source, unmatched.source],
          };
          if (ctx !== null) {
            pieChartLayout(ctx, chartData);
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
  },

  setup() {
    return {
      capitalize,
      seperateThousand,
    };
  },
});
