














import { localeChanged, localize } from "vee-validate";
import { defineComponent } from "@vue/composition-api";
import { rootInstance } from "./helpers/composition";
import {
  usePreferredColorScheme,
  usePreferredLanguages
} from "./helpers/use_web";
import { defaultPeriod } from "@/helpers/date";

export default defineComponent({
  name: "App",
  metaInfo() {
    const theme = this.$store.state.theme;
    const language = this.$store.state.language;

    return {
      title: "GreenLink",
      htmlAttrs: {
        lang: language,
      },
      bodyAttrs: {
        class: [`theme--${theme}`],
      },
    };
  },
  setup() {
    const { root } = rootInstance();
    const defaultTheme = usePreferredColorScheme();
    const languages = usePreferredLanguages();

    // ! Initialize color theme before the app is mounted
    if (localStorage.getItem("theme") === null) {
      root.$store.dispatch("setCurrentTheme", defaultTheme.value);
    } else {
      root.$store.dispatch("setCurrentTheme", localStorage.getItem("theme"));
    }

    if (localStorage.getItem("period") === null) {
      root.$store.dispatch("setCurrentPeriod", defaultPeriod().period);
    } else {
      root.$store.dispatch(
        "setCurrentPeriod",
        JSON.parse(localStorage.getItem("period") as string)
      );
    }

    // ! Initialize language pref. before the app is mounted
    if (localStorage.getItem("language") === null) {
      const initLang = languages.value[0].split("-")[0].toLowerCase();

      root.$store.dispatch("setCurrentLanguage", initLang);
      root.$i18n.locale = initLang;
      localize(initLang);
    } else {
      const lang = localStorage.getItem("language");
      root.$store.dispatch("setCurrentLanguage", lang);
      (root as any).$i18n.locale = lang;
      lang && localize(lang);
    }

    localeChanged();
    // throw new Error("sentry_error");

    return {};
  },
});
