





























import dayjs from "dayjs";
import { IConsumptionDataResult } from "@/lib/types/energy-data/consumption_data";
import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { IProductionSiteDetails } from "@/lib/types/energy-assets/production_site";
import Map from "../Map.vue";

export default defineComponent({
  name: "ConsumptionMathingList",
  components: {
    ConsumptionMatchingTable: () => import("./ConsumptionMatchingTable.vue"),
    Map,
  },
  setup() {
    const siteDetails = ref<IProductionSiteDetails[]>([]);
    const siteLocations = ref<number[][]>([]);
    const siteDetailsLoaded = ref(false);

    const selectedRow = reactive({
      consumptionStart: "",
      consumptionEnd: "",
      center: {
        latitude: null as unknown as number,
        longitude: null as unknown as number,
      },
      sitesMatched: [] as any[],
    });

    const formatDateTime = (date: string | Date): string => {
      return `${dayjs(date).format("D MMM YYYY")} ${dayjs(date).format("HH:mm")}`;
    };

    const selected = (row: IConsumptionDataResult) => {
      selectedRow.consumptionStart = formatDateTime(row.consumption_start);
      selectedRow.consumptionEnd = formatDateTime(row.consumption_end);
      selectedRow.center.latitude = row.consumption_site.location.latitude as number;
      selectedRow.center.longitude = row.consumption_site.location.longitude as number;
      selectedRow.sitesMatched = row.production_sites_matched;
    };

    onMounted(() => {
      watch(
        selectedRow,
        async row => {
          siteDetails.value = [];
          siteLocations.value = [];
          siteDetailsLoaded.value = false;

          for (const site of row.sitesMatched) {
            siteDetails.value.push(site);
            siteLocations.value.push([site.location.latitude, site.location.longitude]);
          }

          siteDetailsLoaded.value = true;
        });
    });

    return { selected, selectedRow, siteDetails, siteDetailsLoaded, siteLocations };
  },
});
