import { onMounted, reactive } from "@vue/composition-api";
import { Loader } from "@googlemaps/js-api-loader";

export function geocode() {
  const state = reactive({
    geocoder: null as any,
    address: "",
    results: [],
  });

  const loader = new Loader({
    apiKey: "AIzaSyBUIvb0KWWZc6IcJOCIctAK0yRrFVoOEYw",
    // version: "weekly",
    // libraries: ["places"],
    language: "en",
  });

  const submit = (addressArg: string) => {
    return new Promise((resolve, reject) => {
      state.geocoder
        .geocode({ address: typeof addressArg === "object" ? state.address : addressArg })
        .then((res: any) => {
          state.results = res.results;
          resolve(res);
        })
        .catch((err: any) => {
          reject(new Error(err));
        });
    });
  };

  const clear = () => {
    state.address = "";
    state.results = [];
  };

  onMounted(async () => {
    await loader
      .load()
      .then(async google => {
        state.geocoder = await new google.maps.Geocoder();
      })
      .catch(() => {
        // alert(e);
        console.error("geocode.ts onMounted");
      });
  });

  return { state, submit, clear };
}
