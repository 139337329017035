


































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import axios from "axios";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { UserActivityCategories, UserActivityMethods } from "@/lib/types/logs";

type State = {
  data: Record<string, any>;
  error: null | string;
  isLoading: boolean;
  page: number;
  perPage: number;
  category: string;
  method: string;
  isDrawerOpen: boolean;
  relatedLogId: null | string;
};

export default defineComponent({
  name: "UserActivity",
  props: {
    refreshTriggerer: {
      type: Number,
      default: 0,
    },
  },
  components: {
    RelatedLog: () => import("./RelatedLog.vue"),
  },
  setup(props) {
    const state = reactive<State>({
      data: {},
      error: null,
      isLoading: true,
      page: 1,
      perPage: 25,
      category: "",
      method: "",
      isDrawerOpen: false,
      relatedLogId: null,
    });

    const pageCount = computed(() =>
      Math.ceil(state.data.count / state.perPage)
    );
    const categories = UserActivityCategories;
    const methods = UserActivityMethods;

    const cellClassName = ({ row, column }) => {
      if (row.related_log_id && column.property === "related_log_type_name") {
        return "UserActivity__related-task-column";
      }
    };

    const onCellClick = (row, cell) => {
      if (row.related_log_id && cell.property === "related_log_type_name") {
        state.relatedLogId = row.related_log_id;
        state.isDrawerOpen = true;
      }
    };

    const fetchData = async () => {
      state.isLoading = true;
      const defaultParams = {
        order_by: "-date_time",
        limit: state.perPage,
        offset: (state.page - 1) * state.perPage,
      };

      const params = {
        ...defaultParams,
        ...(state.category.length && { model_name: state.category }),
        ...(state.method.length && { event: state.method }),
      };

      await axios({
        method: "get",
        url: process.env.VUE_APP_API_PATH + "/logs/activities",
        params,
      })
        .then((res: any) => {
          if (res) {
            state.data = {
              ...res.data,
              results: res.data.results.map(result => ({
                ...result,
                details: {
                  ...result.details,
                  message: (result.details.message || "").replace(/\s+/g, " "),
                },
              })),
            };
          } else {
            state.data = {};
          }
        })
        .catch(err => {
          state.error = err.response.data;
        })
        .finally(() => {
          state.isLoading = false;
        });
    };

    const currentChange = (i: number): void => {
      state.page = i;
    };

    const clearFilters = () => {
      state.category = "";
      state.method = "";
      state.page = 1;
      fetchData();
    };

    onMounted(async () => {
      await fetchData();
    });

    watch(
      () => [state.perPage, state.page, props.refreshTriggerer],
      () => fetchData()
    );
    watch(
      () => [state.category, state.method],
      () => {
        state.page = 1;
        fetchData();
      }
    );

    return {
      ...toRefs(state),
      categories,
      methods,
      formatDate,
      timeAgo,
      cellClassName,
      onCellClick,
      clearFilters,
      pageCount,
      currentChange,
    };
  },
});
