






















































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { formatDate } from "@/plugins/dayjs";
import axios from "axios";
import dayjs from "dayjs";
import { Notification } from "element-ui";

interface IVerifiedData {
  info: string;
  eic: string;
  total_amount: number;
  total_hours: number;
  zero_values: number;
}

export default defineComponent({
  name: "getViaEic",
  components: {
    FormBase: () => import("../FormBase.vue"),
    DateTimePicker: () => import("../DateTimePicker.vue"),
  },
  props: {
    eic: {
      type: String,
      default: "",
      required: true,
    },
    consumerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, { emit }) {
    const formSubmitted = ref(false);
    const formRef = ref(null);
    const date = ref("");

    const epiasForm = reactive({
      consumption_sites: [props.eic],
      consumption_start: computed(() =>
        dayjs(date.value).format("YYYY-MM-DD[T00:00:00+03:00]")
      ),
      consumption_end: computed(() =>
        dayjs(date.value)
          .add(1, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD[T00:00:00+03:00]")
      ),
      consumer_id: computed(() => props.consumerId),
      preview: true,
      is_bulk: false,
    });

    const fault = reactive({
      error: {},
    });

    const verifiedData = reactive<IVerifiedData>({
      info: "",
      eic: "",
      total_amount: 0,
      total_hours: 0,
      zero_values: 0,
    });

    const initialVerifiedData = Object.freeze({ ...verifiedData });
    const totalAmountFound = computed(
      () =>
        verifiedData.total_amount +
        verifiedData.total_hours +
        verifiedData.zero_values
    );

    const getViaEicFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await axios({
        method: "post",
        url:
          process.env.VUE_APP_API_PATH +
          "/energy-data/consumption-data/import-data/",
        data: {
          ...epiasForm,
        },
      })
        .then(res => {
          if (epiasForm.preview === true) {
            epiasForm.preview = false;
            verifiedData.info = res.data.detail[0].info;
            verifiedData.eic = res.data.detail[0].eic;
            verifiedData.total_amount = res.data.detail[0].total_amount;
            verifiedData.total_hours = res.data.detail[0].total_hours;
            verifiedData.zero_values = res.data.detail[0].zero_values;
          } else {
            Notification({
              title: "Operation successful",
              message: res.data.detail,
              type: "success",
              duration: 5000,
              showClose: true,
              position: "bottom-left",
            });

            epiasForm.consumption_start = "";
            epiasForm.consumption_end = "";
            epiasForm.preview = true;

            emit("onSuccess", true);
          }
        })
        .catch(err => {
          const error = err.response.data;
          fault.error = { ...error };
        })
        .finally(() => {
          formSubmitted.value = false;
        });
    };

    watch(date, (newDate, oldDate) => {
      if (new Date(newDate).getTime() !== new Date(oldDate).getTime()) {
        for (const i in verifiedData) {
          verifiedData[i] = initialVerifiedData[i];
        }

        epiasForm.preview = true;
      }
    });

    return {
      date,
      epiasForm,
      fault,
      verifiedData,
      totalAmountFound,
      formSubmitted,
      formRef,
      getViaEicFn,
      formatDate,
    };
  },
});
