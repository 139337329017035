import { initializeApp } from "firebase/app";
import {
  getAuth as _getAuth,
  signInWithEmailAndPassword,
  signOut as _signOut,
  onAuthStateChanged,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  type User
} from "firebase/auth";

const tenantId = process.env.VUE_APP_FB_TENANT_ID;

const firebaseConfig = {
  apiKey: "AIzaSyC1aLkY_riF7Uq8EzqzQIitNlfj-OmOcmA",
  authDomain: "greenlink-platform-396912.firebaseapp.com",
  projectId: "greenlink-platform-396912",
  storageBucket: "greenlink-platform-396912.appspot.com",
  messagingSenderId: "568197063926",
  appId: "1:568197063926:web:e4774b11fa5088cb20b8d7",
};

const firebase = initializeApp(firebaseConfig);
const auth = _getAuth(firebase);
auth.tenantId = tenantId || "greenlink-platform-396912";

function getInstance() {
  return firebase;
}

function getAuth() {
  return auth;
}

function sendEmailVerification() {
  if (!auth.currentUser) {
    throw new Error("User is not signed in");
  }

  return _sendEmailVerification(auth.currentUser);
}

function sendPasswordResetEmail(email: string) {
  return _sendPasswordResetEmail(auth, email);
}

function signIn(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

function signOut() {
  return _signOut(auth);
}

// More details: https://firebase.google.com/docs/auth/web/service-worker-sessions?hl=en&authuser=1#service_worker_changes
function getFirebaseUser() {
  return new Promise<User | null>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export {
  getInstance,
  getAuth,
  getFirebaseUser,
  sendEmailVerification,
  sendPasswordResetEmail,
  signIn,
  signOut
};
