/* eslint-disable */
import dayjs from "dayjs";

export function defaultPeriod() {
  /**
   * ex: ["2020-11-01", "2020-11-08"]
   */
  let period: string[];
  let queryObject: Record<string, string> = {};
  const queryParams = window.location.search.slice(1).split("&"); // * slice (?) mark from the URL, split start & end dates
  queryParams.forEach(param => {
    // ["start=YYYY-MM-DD", "end=YYYY-MM-DD"]
    queryObject[param.split("=")[0]] = param.split("=")[1];
  })

  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  const currentMonthStart = dayjs().format("YYYY-MM-[01]");

  // ? Today is the first day of the current month
  if (dayjs(today).valueOf() === dayjs(currentMonthStart).valueOf()) {

    let prevMonthStart, prevMonthEnd;

    prevMonthStart = dayjs(currentMonthStart).subtract(1, "month").format("YYYY-MM-DD");
    prevMonthEnd = dayjs(currentMonthStart).subtract(1, "day").format("YYYY-MM-DD");

    period = [prevMonthStart + "T00:00:00+03:00", prevMonthEnd + "T23:59:00+03:00"];

    // ? Yesterday was the first day of the current month
  } else if (dayjs(yesterday).valueOf() === dayjs(currentMonthStart).valueOf()) {

    period = [currentMonthStart + "T00:00:00+03:00", currentMonthStart + "T23:59:00+03:00"];

  } else {

    period = [currentMonthStart + "T00:00:00+03:00", yesterday + "T23:59:00+03:00"];
  }

  if (
    queryObject.start &&
    queryObject.end &&
    (new Date(queryObject.start).getTime() <= new Date(queryObject.end).getTime()) &&
    new Date(queryObject.start).toString().toLowerCase() !== "invalid date" &&
    new Date(queryObject.end).toString().toLowerCase() !== "invalid date"
  ) {
    period = [queryObject.start + "T00:00:00+03:00", queryObject.end + "T23:59:00+03:00"];
  }

  return { period };
}
