






















































































import { defineComponent, reactive, ref, watch, computed } from "@vue/composition-api";
import axios from "axios";
import { IError } from "@/lib/types/base";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import { formatDate } from "@/plugins/dayjs";
import { capitalize } from "@/utils";
import { Notification } from "element-ui";

export default defineComponent({
  name: "ConsumptionSiteSelectForm",
  components: {
    FormBase: () => import("../FormBase.vue"),
    FormItem: () => import("../FormItem.vue"),
    ConsumptionSitesList: () => import("@/components/ConsumptionSitesList.vue"),
    DatePicker,
  },
  props: {
    consumerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const date = ref("");

    const fault = reactive<{ error: Partial<IError["errors"]> }>({
      error: {},
    });

    const formData = reactive({
      consumer_id: props.consumerId,
      start_date: computed(() => dayjs(date.value).format("YYYY-MM-DD")),
      end_date: computed(() => dayjs(date.value).add(1, "month").subtract(1, "day").format("YYYY-MM-DD")),
    });

    const getConsumptionSiteList = async () => {
      fault.error = {};
      isLoading.value = true;

      await axios({
        method: "post",
        url: process.env.VUE_APP_API_PATH + "/energy-assets/consumption-site-contracts/imports/",
        data: {
          ...formData,
        },
      })
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Consumption sites will be available soon", // TODO: fix message
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          emit("onSuccess", true);
        })
        .catch(err => {
          const error = err.response.data;
          fault.error = { ...error };
        })
        .finally(() => { isLoading.value = false });
    };

    return {
      isLoading,
      fault,
      date,
      formData,
      formatDate,
      capitalize,
      getConsumptionSiteList,
    };
  },
});
