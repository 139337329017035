

























































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { IUserDetails } from "@/lib/types/user";
import { updateUserData } from "@/api/user";
import { Notification } from "element-ui";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "AccountSettingsForm",
  components: {
    FormBase,
    FormItem,
  },
  setup (_props, vm) {
    const { root } = rootInstance();
    let changeForm = false;

    const initialForm = reactive<Partial<IUserDetails>>({
      first_name: root.$store.state.user.details.first_name,
      last_name: root.$store.state.user.details.last_name,
      // username: root.$store.state.user.details.username,
      phone_number: root.$store.state.user.details.phone_number,
      // is_staff: root.$store.state.user.details.is_staff,
      // is_organization_admin: root.$store.state.user.details.is_organization_admin,
      // is_active: root.$store.state.user.details.is_active,
    }) as Partial<IUserDetails>;

    const formData = reactive<Partial<IUserDetails>>({
      first_name: root.$store.state.user.details.first_name,
      last_name: root.$store.state.user.details.last_name,
      // username: root.$store.state.user.details.username,
      phone_number: root.$store.state.user.details.phone_number,
      // is_staff: root.$store.state.user.details.is_staff,
      // is_organization_admin: root.$store.state.user.details.is_organization_admin,
      // is_active: root.$store.state.user.details.is_active,
    }) as Partial<IUserDetails>;

    // const initialFormData = { ...formData };

    const fault = reactive({
      error: {},
    });

    const formSubmitted = ref(false);

    const updateUserFn = () => {
      vm.emit("formData", formData);

      fault.error = {};
      formSubmitted.value = true;

      const patchedUser: Partial<IUserDetails> = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone_number,
        // is_staff: formData.is_staff,
        // is_organization_admin: formData.is_organization_admin,
        // is_active: formData.is_active,
      };

      // if (initialFormData.username !== formData.username) {
      //   patchedUser.username = formData.username;
      // }

      updateUserData(patchedUser)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Account updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("updated", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    onMounted(() => {
      watch(
        () => root.$store.state.user.details,
        (newVal, oldVal) => {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            formData.first_name = newVal.first_name;
            formData.last_name = newVal.last_name;
            // formData.username = newVal.username;
            formData.phone_number = newVal.phone_number;
            formData.is_staff = newVal.is_staff;
            formData.is_organization_admin = newVal.is_organization_admin;
            formData.is_active = newVal.is_active;
          }
        },
        { deep: true }
      );

      watch(
        () => formData,
        newVal => {
          changeForm = false;
          for (const key of Object.keys(newVal)) {
            if (key !== "location" && (newVal as any)[key] !== (initialForm as any)[key]) {
              changeForm = true;
            }
          }
          vm.emit("changeForm", changeForm);
        },
        { deep: true }
      );
    });

    return { formData, updateUserFn, fault, formSubmitted, changeForm, initialForm };
  },
});
