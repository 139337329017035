const Icon = (window as any).L.Icon;

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: "leaflet/dist/images/marker-icon-2x.png",
  iconUrl: "leaflet/dist/images/marker-icon.png",
  shadowUrl: "leaflet/dist/images/marker-shadow.png",
});

const TrackerIcon = Icon.extend({
  options: {
    shadowUrl: "leaflet/dist/images/marker-shadow.png",
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -12],
    shadowSize: [0, 0],
    shadowAnchor: [16, 0],
  },
});

export const homeIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/home.svg") });
export const consumerIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/energy.svg") });

export const solarIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/solar.svg") });
export const windIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/wind.svg") });
export const hydroIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/hydro.svg") });
export const solidIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/biomass.svg") });
export const marineIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/energy.svg") });
export const thermalIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/energy.svg") });
export const liquidIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/energy.svg") });
export const gaseousIcon = new TrackerIcon({ iconUrl: require("../assets/img/icons/map/energy.svg") });
