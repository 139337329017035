<template>
  <div
    class="loader"
    title="6"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="16px"
      viewBox="0 0 24 18"
      style="enable-background:new 0 0 50 50;"
      xml:space="preserve"
    >
      <rect
        x="0"
        y="0"
        width="4"
        height="16"
        fill="#333"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="7"
        y="0"
        width="4"
        height="16"
        fill="#333"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="14"
        y="0"
        width="4"
        height="16"
        fill="#333"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: "TheLoader",
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/abstracts/_variables.scss";

.loader {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
  height: 17px;
  overflow: hidden;
}

svg path,
svg rect {
  fill: $primary-button;
  border-radius: $border-radius;
}
</style>
