var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card__header"},[_vm._t("title",function(){return [_c(_vm.titleTag,{tag:"component",staticClass:"card__title"},[(_vm.titleClick)?[_c('router-link',{attrs:{"to":{ name: _vm.titleClick }}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:( _vm.titleType === 'chart' )?[_c('el-select',{staticClass:"select",attrs:{"size":"small"},on:{"change":function($event){return _vm.$emit('chart-change', _vm.value)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]:[_vm._v(" "+_vm._s(_vm.title)+" ")]],2)]}),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_vm._t("actions"),(_vm.info)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          content: _vm.info,
          trigger: 'click',
          hideOnClick: 'toggle',
          placement: 'left-start',
          maxWidth: 280,
          arrow: true,
          interactive: true,
          theme: 'material',
        }),expression:"{\n          content: info,\n          trigger: 'click',\n          hideOnClick: 'toggle',\n          placement: 'left-start',\n          maxWidth: 280,\n          arrow: true,\n          interactive: true,\n          theme: 'material',\n        }"}]},[_c('svg-injector',{staticClass:"text-primary",attrs:{"src":require('@/assets/img/icons/info.svg'),"alt":"","height":"28px"}})],1):_vm._e()],2)],2),_c('div',{staticClass:"card__body"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }