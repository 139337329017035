var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":_vm.vvVid,"rules":_vm.vvRules,"name":_vm.vvName,"skip-if-empty":_vm.skipIfEmpty,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__item",class:[
      _vm.itemClass,
      _vm.name,
      errors.length ? 'not-valid' : '',
      _vm.required ? 'required' : ''
    ],attrs:{"data-name":_vm.name}},[_c('label',{staticClass:"form__item__label",class:_vm.labelClass,attrs:{"for":_vm.id}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2),(_vm.options.length)?_c('el-select',_vm._b({attrs:{"id":_vm.id,"name":_vm.name,"filterable":_vm.filterable,"autocomplete":"off","value":_vm.value},on:{"change":_vm.handleInput}},'el-select',_vm.$attrs,false),[_vm._t("options",function(){return _vm._l((_vm.filteredOptions),function(item,index){return _c('el-option',{key:item.id || index,attrs:{"label":(Array.isArray(_vm.options) && item) || item.name,"value":(Array.isArray(_vm.options) && item) || item.id}})})},{"options":_vm.filteredOptions})],2):(_vm.numberInput)?_c('el-input-number',_vm._b({attrs:{"id":_vm.id,"name":_vm.name,"value":_vm.value,"required":_vm.required},on:{"input":_vm.handleInput}},'el-input-number',_vm.$attrs,false)):_c('el-input',_vm._b({attrs:{"id":_vm.id,"name":_vm.name,"value":_vm.value,"required":_vm.required,"autocomplete":"off"},on:{"input":_vm.handleInput}},'el-input',_vm.$attrs,false)),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(errors.length)?_c('span',{staticClass:"form__item__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):(_vm.message && !errors.length)?_c('span',{staticClass:"form__item__message"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }