<template>
  <div class="locale-changer mt-2">
    <div
      v-for="lang in langs"
      :key="lang.iso2"
      class="locale-changer__radio"
      :class="[
        language === lang.iso2 ? 'is-active' : null,
        lang.disabled ? 'is-disabled' : null
      ]"
    >
      <label
        :for="lang.iso2"
        :class="lang.disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
        v-tippy="{
          content: lang.disabled ? `${lang.name} - ${$t('common.not_available')}`
            : language === lang.iso2 ? `${lang.name} - ${$t('common.current')}`
              : lang.name
        }"
      >
        <input
          v-model="language"
          :id="lang.iso2"
          class="sr-only"
          type="radio"
          :value="lang.iso2"
          :disabled="lang.disabled"
        >
        <SvgInjector
          :src="require(`@/assets/img/flags/${lang.iso2}.svg`)"
          height="32px"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { greenlinkClient } from "../plugins/greenlink";

import { localize as vvLocalize } from "vee-validate";
import vvEn from "vee-validate/dist/locale/en.json";
import vvTr from "vee-validate/dist/locale/tr.json";
import vvDe from "vee-validate/dist/locale/de.json";

import eLocale from "element-ui/lib/locale";
import eEn from "element-ui/lib/locale/lang/en";
import eTr from "element-ui/lib/locale/lang/tr-TR";
import eDe from "element-ui/lib/locale/lang/de";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/tr";
import "vue2-datepicker/locale/de";

import dayjs from "dayjs";
require("dayjs/locale/en");
require("dayjs/locale/tr");
require("dayjs/locale/de");

export default {
  name: "LocaleChanger",
  data () {
    return {
      langs: [
        { iso2: "tr", name: this.$t("languages.turkish"), disabled: false },
        { iso2: "en", name: this.$t("languages.english"), disabled: false },
        // { iso2: "de", name: this.$t("languages.german"), disabled: false },
      ],
      language: localStorage.getItem("language"),
    };
  },
  watch: {
    language (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.dispatch("setCurrentLanguage", newVal);
        this.$root.$i18n.locale = newVal;
      }
    },
    "$store.state.language": {
      handler (newVal) {
        greenlinkClient.defaultHeaders = {
          ...greenlinkClient.defaultHeaders,
          "Accept-Language": `${newVal},en;q=0.5`,
        };

        if (new RegExp(/^en/).test(newVal)) {
          dayjs.locale("en");
          vvLocalize("en", vvEn);
          eLocale.use(eEn);
          this.$root.$i18n.locale = "en";
          DatePicker.locale("en");
          /*  */
        } else if (new RegExp(/^tr/).test(newVal)) {
          dayjs.locale("tr");
          vvLocalize("tr", vvTr);
          eLocale.use(eTr);
          this.$root.$i18n.locale = "tr";
          DatePicker.locale("tr");
          /*  */
        } else if (new RegExp(/^de/).test(newVal)) {
          dayjs.locale("de");
          vvLocalize("de", vvDe);
          eLocale.use(eDe);
          this.$root.$i18n.locale = "de";
          DatePicker.locale("de");
          /*  */
        } else {
          dayjs.locale("en");
          vvLocalize("en", vvEn);
          eLocale.use(eEn);
          this.$root.$i18n.locale = "en";
          DatePicker.locale("en");
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
