import { onMounted, onUnmounted, ref, computed, Ref } from "@vue/composition-api";

export function usePreferredLanguages() {
  const value = ref(navigator.languages);

  function handler() {
    value.value = navigator.languages;
  }

  onMounted(() => {
    window.addEventListener("languagechange", handler);
  });

  onUnmounted(() => {
    window.removeEventListener("languagechange", handler);
  });

  return value;
}

function useMedia(query: string) {
  let mediaQuery!: MediaQueryList;

  // try to fetch initial value (avoid SSR issues)
  if (typeof window !== "undefined") {
    mediaQuery = window.matchMedia(query);
  }

  const matches = ref(mediaQuery ? mediaQuery.matches : false);
  function handler(event: MediaQueryListEvent) {
    matches.value = event.matches;
  }

  onMounted(() => {
    if (!mediaQuery) {
      mediaQuery = window.matchMedia(query);
    }

    matches.value = mediaQuery.matches;
    mediaQuery.addListener(handler);
  });

  onUnmounted(() => {
    mediaQuery.removeListener(handler);
  });

  return matches;
}

export function usePreferredColorScheme(): Ref<"dark" | "light" | "no-preference"> {
  const queries = {
    light: "(prefers-color-scheme: light)",
    dark: "(prefers-color-scheme: dark)",
    "no-preference": "(prefers-color-scheme: no-preference)",
  };

  const isDark = useMedia(queries.dark);
  const isLight = useMedia(queries.light);

  const theme = computed(() => {
    if (isDark.value) {
      return "dark";
    }

    if (isLight.value) {
      return "light";
    }

    return "no-preference";
  });

  return theme;
}
