// eslint-disable-next-line
// @ts-ignore
import { energyDataApi } from "../../plugins/greenlink.js";
import { IConsumptionData, IConsumptionDataOpts } from "@/lib/types/energy-data/consumption_data.js";

export function getConsumptionDataList(opts?: Partial<IConsumptionDataOpts> & { orderBy?: [string] | string }) {
  return new Promise<IConsumptionData>((resolve, reject) => {
    energyDataApi.energyDataConsumptionDataList(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response.body);
      } else {
        resolve(response.body);
      }
    });
  });
}
