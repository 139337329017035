































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { rootInstance } from "@/helpers/composition";

import { Notification } from "element-ui";
import ProducerCrudForm from "../forms/ProducerCrudForm.vue";
import { deleteProducerData } from "@/api/organizations/producer";
import { OrganizationTypes } from "@/lib/types/base";

export default defineComponent({
  name: "ProducerDetails",
  components: { ProducerCrudForm },
  props: {
    details: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, vm) {
    const { root } = rootInstance();

    const editModalVisible = ref(false);
    const roles = root.$store.getters["user/roles"];

    const deleteProducer = () => {
      root.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${props.details.name}</code>?
          <br>
          This action can only be <span class="font-medium">reversed by a superadmin</span>.
        `,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: root.$i18n.t("labels.delete") as string,
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteProducerData(props.details.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `Producer "${props.details.name}" deleted successfully`,
                    type: "success",
                  });

                  root.$router.push({ name: "ProducersList" });
                  vm.emit("delete", true);
                  done();
                })
                .catch(err => {
                  alert(JSON.stringify(err.body.detail));
                  instance.confirmButtonLoading = false;
                });
              /*  */
            } else {
              done();
            }
          },
        });
    };

    const siteUpdated = () => {
      vm.emit("update", true);
      editModalVisible.value = false;
    };

    return { formatDate, timeAgo, roles, editModalVisible, deleteProducer, siteUpdated, OrganizationTypes };
  },
});
