<template>
  <component
    :is="is"
    class="tippy-wrapper"
  >
    <slot />
  </component>
</template>

<script>
import { randomId } from "@/utils";
export default {
  name: "Tippy",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    is: {
      type: String,
      default: "span",
    },
  },
  data () {
    return {
      id: randomId(),
    };
  },
};
</script>
