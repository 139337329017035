// eslint-disable-next-line
// @ts-ignore
import { organizationsApi } from "../../plugins/greenlink";
import { IRetailerDetails } from "@/lib/types/organizations/retailer";
import { IListResponse } from "@/lib/types/base";

export function getRetailersList (opts?: Partial<IRetailerDetails>) {
  return new Promise<IListResponse<IRetailerDetails>>((resolve, reject) => {
    organizationsApi.organizationsRetailersList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getRetailerData (id: string) {
  return new Promise<IRetailerDetails>((resolve, reject) => {
    organizationsApi.organizationsRetailersRetrieve(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateRetailerData (id: string, patch: Partial<IRetailerDetails>) {
  return new Promise<IRetailerDetails>((resolve, reject) => {
    organizationsApi.organizationsRetailersUpdate(id, patch, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
