







































































































































































































































import { computed, defineComponent, reactive, toRefs, watch, ref } from "@vue/composition-api";
import { getConsumptionSitesContracts, getConsumptionSitesList, IConsumptionSiteContracts } from "@/api/energy-assets/consumption_site";

import { rootInstance } from "@/helpers/composition";
import { IListResponse, OrganizationTypes } from "@/lib/types/base";
import { IConsumptionSiteDetails } from "@/lib/types/energy-assets/consumption_site";
import { formatDate } from "@/plugins/dayjs";
import { debounce } from "lodash";

import VueScrollTo from "vue-scrollto";
import XLSX from "xlsx";
import { isDevMode, seperateThousand } from "@/utils";

export default defineComponent({
  name: "ConsumptionSitesList",
  components: {
    ConsumptionSitesListFilter: () => import("@/components/consumption-site/ConsumptionSitesListFilter.vue"),
    ConsumptionSiteCrudForm: () => import("@/components/forms/ConsumptionSiteCrudForm.vue"),
    ConsumptionSiteUploadForm: () => import("@/components/forms/ConsumptionSiteUploadForm.vue"),
    ConsumptionSiteSelectForm: () => import("@/components/forms/ConsumptionSiteSelectForm.vue"),
    ConsumptionDataBulkImport: () => import("@/views/app/pages/consumers/ConsumptionDataBulkImport.vue"),
  },
  props: {
    consumerId: {
      type: String,
      default: null,
      required: true,
    },
    bulkImport: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = rootInstance();

    const state = reactive({
      data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      } as IListResponse<IConsumptionSiteContracts | IConsumptionSiteDetails>,
      isLoading: false,
      page: 1,
      limit: 24,
      search: "",
      openFilter: false,
      createModalVisible: false,
      uploadModalVisible: false,
      currentTab: "crud",
      isEffective: false,

      workbook: {} as XLSX.WorkBook,
      sheetName: "",
      sheetTitles: [] as string[],
      isRouterAlive: true,
      consumptionSiteFormValue: false,
    });

    const roles = computed(() => root.$store.getters["user/roles"]);
    const offset = computed(() => (state.page - 1) * state.limit);
    const tableRef = ref();

    const betweenDates = (period: Array<string>) => {
      return period.map(date => formatDate(date)).join("-");
    };

    const consumptionSitesListDataFn = async () => {
      state.isLoading = true;

      state.isEffective
        ? await getConsumptionSitesContracts({
          consumer_id: props.consumerId,
          limit: state.limit,
          offset: offset.value,
          search: state.search,
          start_date: root.$store.state.period[0].split("T")[0],
          end_date: root.$store.state.period[1].split("T")[0],
          order_by: "-created_at",
        })
          .then(res => { state.data = res })
          .catch(() => console.error("ConsumptionSitesList.vue consumptionSitesListDataFn Contracts"))
          .finally(() => { state.isLoading = false })
        : await getConsumptionSitesList({
          consumer: props.consumerId,
          limit: state.limit,
          offset: offset.value,
          search: state.search,
          orderBy: ["-created_at"],
        })
          .then(res => { state.data = res })
          .catch(() => console.error("ConsumptionSitesList.vue consumptionSitesListDataFn Sites"))
          .finally(() => { state.isLoading = false });
    };

    const siteCreated = () => {
      consumptionSitesListDataFn();
      state.currentTab = "crud";
      state.isRouterAlive = false;
      root.$nextTick(() => {
        state.isRouterAlive = true;
      });
      state.createModalVisible = false;
      state.uploadModalVisible = false;
    };

    const handleImport = (e: any) => {
      const files = e.target.files;
      const f = files[0];
      const reader = new FileReader();

      state.sheetName = "";

      reader.onload = function (e) {
        const data = new Uint8Array((e.target as any).result);
        const workbook = XLSX.read(data, { type: "array" });

        state.workbook = workbook;
      };

      reader.readAsArrayBuffer(f);
    };

    const handleSheetTitles = () => {
      const reg = new RegExp(/[a-zA-Z]1$/, "i");
      const sheetContent = state.workbook.Sheets[state.sheetName];

      Object.keys(sheetContent).forEach(key => {
        reg.test(key) && state.sheetTitles.push(sheetContent[key].v);
      });
    };

    const handlePageChange = (page: number) => {
      state.page = page;
      consumptionSitesListDataFn();
    };

    const handleSearch = () => {
      state.page = 1;
      consumptionSitesListDataFn();
    };

    consumptionSitesListDataFn();

    watch(
      () => [state.sheetName, state.isEffective],
      ([newSheetName, newEffective], [oldSheetName, oldEffective]) => {
        if (newSheetName !== oldSheetName) {
          state.sheetTitles.length = 0;
          handleSheetTitles();
        }

        if (newEffective !== oldEffective) {
          state.data = {
            count: 0,
            next: null,
            previous: null,
            results: [],
          };
          consumptionSitesListDataFn();
        }
      }
    );

    watch(
      offset,
      debounce((newOffset, oldOffset) => {
        const offsetChanged = newOffset !== oldOffset;

        if (offsetChanged) {
          VueScrollTo.scrollTo(tableRef.value.$el, 300, {
            container: document.querySelector("[class*='AppLayout_mainWrapper']")!,
          });
        }
      }, 100)
    );

    const changeF = (newVal: any) => {
      state.consumptionSiteFormValue = newVal;
    };

    const closeDialog = () => {
      if (state.consumptionSiteFormValue) {
        root.$confirm(
          /* Message */
          root.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          root.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: root.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                state.currentTab = "crud";
                state.isRouterAlive = false;
                root.$nextTick(() => {
                  state.isRouterAlive = true;
                });
                state.createModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          });
      } else {
        state.currentTab = "crud";
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.createModalVisible = false;
        state.consumptionSiteFormValue = false;
      }
    };

    return {
      ...toRefs(state),
      roles,
      tableRef,
      OrganizationTypes,
      siteCreated,
      consumptionSitesListDataFn,
      handleSearch,
      handleImport,
      handlePageChange,
      closeDialog,
      changeF,
      formatDate,
      seperateThousand,
      isDevMode,
    };
  },
});
