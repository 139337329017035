




































import { sendEmailVerification, signOut } from "@/plugins/firebase";
import { defineComponent } from "@vue/composition-api";
import { Notification } from "element-ui";

export default defineComponent({
  name: "EmailVerificationRequiredMessage",
  props: {
    emailVerified: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSendingEmail: false,
    };
  },
  methods: {
    logout() {
      signOut().then(() => {
        this.$emit("logout", null);
        // Hack to prevent error and redirect to login page every time
        this.$router.replace({ name: "LoginPage" }).catch(() => {
          this.$router.replace({ name: "LoginPage" });
        });
      });
    },
    async sendEmailVerificationRequest() {
      try {
        this.isSendingEmail = true;

        await sendEmailVerification().then(() => {
          Notification({
            title: "Operation successful",
            message: "Please check your inbox and verify your account",
            type: "success",
            duration: 0,
            showClose: true,
            position: "bottom-left",
          });
        });
      } catch (e: any) {
        Notification({
          title: (e instanceof Error && e.message.includes("auth/too-many-requests")) ? "Too many requests" : "Error",
          message: (e instanceof Error && e.message.includes("auth/too-many-requests")) ? "Please try again later" : e.message,
          type: "error",
          duration: 0,
          showClose: true,
          position: "bottom-left",
        });
      } finally {
        this.isSendingEmail = false;
      }
    },
  },
});
