<template>
  <img
    ref="svgRef"
    class="sevege"
    :src="$props.src"
    :alt="$props.alt"
    :style="{
      'width': typeof width === 'number' ? width + 'px' : width,
      'height': typeof height === 'number' ? height + 'px' : height,
    }"
  >
</template>

<script>
import svgInjector from "svg-injector";

export default {
  name: "SvgInjector",
  props: {
    src: {
      type: String,
      default: "",
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: "32px",
    },
  },
  mounted () {
    svgInjector(document.querySelectorAll(".sevege"), { pngFallback: this.src });
  },
};
</script>
