<template>
  <div
    class="consumption-site-details"
    v-if="details"
  >
    <div class="w-100">
      <table class="scan-table">
        <tbody>
          <tr>
            <td>{{ $t("labels.name") }}</td>
            <td>{{ details.name }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.id") }}</td>
            <td>
              <Clipboard :value="details.id" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.bc_address") }}</td>
            <td>
              <Clipboard
                :value="details.blockchain_account_address"
                :page-url="$store.state.addressUrl"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.eic") }}</td>
            <td><Clipboard :value="details.eic" /></td>
          </tr>
          <tr>
            <td>{{ $t("labels.power_plant_id") }}</td>
            <td>{{ details.power_plant_id }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.energy_source") }}</td>
            <td>{{ details.energy_source }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.technology_or_fuel_type") }}</td>
            <td>{{ details.technology_or_fuel_type }}</td>
          </tr>
          <tr>
          </tr><tr>
            <td>{{ $t("labels.capacity") }}</td>
            <td>
              {{ seperateThousand(details.capacity) }}

              <span class="text-dark-l3 text-cs">
                [≈ {{ seperateThousand(details.capacity / (10 ** 6)) }} GWh]
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ $tc("labels.producer") }}</td>
            <td>
              <HoverPopper :text="details.producer ? details.producer.name : '-'">
                <ProducerDetails
                  :details="details.producer"
                  :hide-header="true"
                />
              </HoverPopper>
            </td>
          </tr>
          <!-- <tr>
            <td>{{ $t("labels.grid_operator") }}</td>
            <td>
              <HoverPopper :text="details.grid_operator ? details.grid_operator.name : '-'">
                <OrganizationDetails :details="details.grid_operator" />
              </HoverPopper>
            </td>
          </tr> -->
          <tr>
            <td>{{ $t("labels.issuer") }}</td>
            <td>
              <HoverPopper :text="details.issuer ? details.issuer.name : '-'">
                <OrganizationDetails :details="details.issuer" />
              </HoverPopper>
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.location") }}</td>
            <td>
              <details>
                <summary>{{ $t("labels.details") }}</summary>
                <table
                  class="scan-table"
                >
                  <tbody>
                    <tr>
                      <td>{{ $t("labels.latitude") }}</td>
                      <td>{{ details.location.latitude }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.longitude") }}</td>
                      <td>{{ details.location.longitude }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.country") }}</td>
                      <td>{{ details.location.country }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.city") }}</td>
                      <td>{{ details.location.city }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.province") }}</td>
                      <td>{{ details.location.province_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.district") }}</td>
                      <td>{{ details.location.district_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.neighborhood") }}</td>
                      <td>{{ details.location.neighborhood_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.street") }}</td>
                      <td>{{ details.location.street_name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.building_number") }}</td>
                      <td>{{ details.location.building_number }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("labels.independent_section_code") }}</td>
                      <td>{{ details.location.independent_section_code }}</td>
                    </tr>
                  </tbody>
                </table>
              </details>
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.is_licensed") }}</td>
            <td>{{ details.is_licensed ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.is_active") }}</td>
            <td>{{ details.is_active ? $t("labels.yes") : $t("labels.no") }}</td>
          </tr>
          <tr>
            <td>{{ $t("labels.created_at") }}</td>
            <td>
              {{ timeAgo(details.created_at) }}
              ({{ formatDate(details.created_at) }})
            </td>
          </tr>
          <tr>
            <td>{{ $t("labels.updated_at") }}</td>
            <td>
              {{ timeAgo(details.updated_at) }}
              ({{ formatDate(details.updated_at) }})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div v-else>
    ...
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { seperateThousand } from "@/utils";
import HoverPopper from "../HoverPopper.vue";
import ProducerDetails from "../producer/ProducerDetails.vue";
import OrganizationDetails from "../organization/OrganizationDetails.vue";

export default defineComponent({
  components: { HoverPopper, ProducerDetails, OrganizationDetails },
  name: "ConsumptionSiteDetails",
  props: {
    details: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup () {
    return { formatDate, timeAgo, seperateThousand };
  },
});
</script>
