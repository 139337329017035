<template>
  <div class="geocode-wrapper card p-3">
    <div>
      <form
        class="d-flex flex-nowrap"
        @submit.prevent="submit"
      >
        <el-input
          class="mr-2"
          v-model="address"
          :placeholder="$t('placeholders.enter_your', { msg: $t('labels.address') })"
        />
        <el-button
          type="primary"
          native-type="submit"
        >
          {{ $t("placeholders.search") }}
        </el-button>
      </form>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="results.length">
        <ol class="my-3">
          <li
            class="text-sm mb-2"
            v-for="address in results"
            :key="address.place_id"
            style="position:relative"
          >
            <span class="d-block truncate">
              <strong>{{ `${$t("labels.address")}: ` }}</strong> {{ address.formatted_address }}
              <el-button
                style="position:absolute;right:0; height:30px;"
                @click="updateCoordinates(address)"
              >
                {{ $t("labels.use_coordinates") }}
              </el-button>
            </span>
            <span class="d-block">
              <strong>{{ `${$t("labels.latitude")}: ` }}</strong> {{ address.geometry.location.lat() }},
              <strong>{{ `${$t("labels.longitude")}: ` }}</strong> {{ address.geometry.location.lng() }}
            </span>
          </li>
        </ol>
        <el-button @click="clear">
          {{ $t("labels.clear") }}
        </el-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, toRefs, onMounted, reactive } from "@vue/composition-api";
import { geocode } from "../composables/geocode";
import { rootInstance } from "@/helpers/composition";
export default defineComponent({
  name: "GeocodeWrapper",
  setup(_props, { emit }) {
    const { state, submit, clear } = geocode();
    onMounted(() => {
      clear();
    });
    const { root } = rootInstance();

    const updateCoordinates = address => {
      const lat = address.geometry.location.lat();
      const lng = address.geometry.location.lng();
      const country = address.address_components.find(c => c.types.includes("country"))?.long_name;
      const city = address.address_components.find(c => c.types.includes("administrative_area_level_1"))?.long_name;
      const state = address.address_components.find(c => c.types.includes("administrative_area_level_2"))?.long_name;
      const neighborhood = address.address_components.find(c => c.types.includes("administrative_area_level_4"))?.long_name;
      const street = address.address_components.find(c => c.types.includes("route"))?.long_name;
      const streetNumber = address.address_components.find(c => c.types.includes("street_number"))?.long_name;

      const location = reactive({
        lat,
        lng,
        country,
        city,
        state,
        neighborhood,
        street,
        streetNumber,
      });

      root.$confirm(
        /* Message */
        `
         <div class="text-center">
          ${root.$i18n.t("placeholders.coordinates_will_be_applied")}
          <br />
          <br />
          <span class="d-block">
            <strong>${root.$i18n.t("labels.latitude")}:</strong> ${lat}
          </span>
          <span class="d-block">
            <strong>${root.$i18n.t("labels.longitude")}:</strong> ${lng}
          </span>
          <span class="d-block">
            <strong>${root.$i18n.t("labels.country")}:</strong> ${country}
          </span>
          <span class="d-block">
            <strong>${root.$i18n.t("labels.city")}:</strong> ${city}
          </span>
          ${state ? `<span class="d-block">
            <strong>${root.$i18n.t("labels.district")}:</strong> ${state}
          </span>` : ""}
          ${neighborhood ? `<span class="d-block">
            <strong>${root.$i18n.t("labels.neighborhood")}:</strong> ${neighborhood}
          </span>` : ""}
          ${street ? `<span class="d-block">
            <strong>${root.$i18n.t("labels.street")}:</strong> ${street}
          </span>` : ""}
          ${streetNumber ? `<span class="d-block">
            <strong>${root.$i18n.t("labels.building_number")}:</strong> ${streetNumber}
          </span>` : ""}
          <br />
          <span>${root.$i18n.t("placeholders.do_you_confirm")}</span>
        </div>
        `,
        {
          confirmButtonText: root.$i18n.t("labels.update"),
          cancelButtonText: root.$i18n.t("labels.cancel"),
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              emit("location", location);
              instance.confirmButtonLoading = false;
              done();
            } else {
              done();
            }
          },
        });
    };

    return { ...toRefs(state), submit, clear, updateCoordinates };
  },
});
</script>

<style lang="scss">
// .geocode-wrapper {
// }
</style>
