
































































































































































import { defineComponent } from "@vue/composition-api";
import { Notification } from "element-ui";
import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import {
  createProductionSiteData,
  updateProductionSiteData
} from "@/api/energy-assets/production_site";
import { ILocation } from "@/lib/types/location";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ProductionSiteCrudForm",
  components: {
    FormBase,
    FormItem,
    ProducerSelect: () => import("@/components/selectbox/ProducerSelect.vue"),
    EnergySourceSelect: () =>
      import("@/components/selectbox/EnergySourceSelect.vue"),
    TechnologySelect: () =>
      import("@/components/selectbox/TechnologySelect.vue"),
    IssuerSelect: () => import("@/components/selectbox/IssuerSelect.vue"),
    EpiasSelect: () => import("@/components/selectbox/EpiasSelect.vue"),
    LocationForm: () => import("./LocationForm.vue"),
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    operation: {
      type: String,
      default: null,
      required: true,
      validator: function (val: string) {
        return ["create", "update"].indexOf(val) !== -1;
      },
    },
    id: {
      type: String,
      default: null,
      required: true,
    },
    producerId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      epiasSite: {
        id: "",
        eic: "",
        name: "",
        shortName: "",
      },
      initialForm: {},
      formData: {
        name: this.$props.details.name || "",
        eic: this.$props.details.eic || "",
        power_plant_id: this.$props.details.power_plant_id || null,
        capacity: this.$props.details.capacity || null,
        energy_source: this.$props.details.energy_source || "",
        technology_or_fuel_type:
          this.$props.details.technology_or_fuel_type || "",
        producer:
        /* this.check(this.$props.details.producer) */ this.producerId ||
          null,
        grid_operator: this.check(this.$props.details.grid_operator) || null,
        issuer: this.check(this.$props.details.issuer) || null,
        location: this.$props.details.location || {
          latitude: null,
          longitude: null,
          country: "" as ILocation["country"],
          city: "",
          province_name: "",
          province_code: "",
          district_name: "",
          district_code: "",
          neighborhood_name: "",
          neighborhood_code: "",
          street_name: "",
          street_code: "",
          building_number: "",
          independent_section_code: "",
          updated_at: "",
          created_at: "",
          id: "",
        },
        is_active:
          this.$props.details.is_active !== undefined
            ? this.$props.details.is_active
            : true,
        is_licensed:
          this.$props.details.is_licensed !== undefined
            ? this.$props.details.is_licensed
            : true,
      },
      error: {},
      editLocationModal: false,
      formSubmitted: false,
      issuer_count: 0,
      changeForm: false,
      changeLocation: false,
    };
  },
  mounted() {
    this.$emit("changeForm", this.changeForm);
    this.initialForm = cloneDeep(this.formData);

    this.$watch(
      () => this.epiasSite,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.formData.eic = newVal.eic;
          this.formData.power_plant_id = newVal.id;
        }
      }
    );

    this.$watch(
      () => this.formData.energy_source,
      (newVal, oldVal) => {
        if (newVal !== oldVal) this.formData.technology_or_fuel_type = "";
      }
    );
    this.$watch(
      () => this.formData,
      newVal => {
        if (this.formData.capacity === "") {
          this.formData.capacity = null;
        }
        if (this.formData.power_plant_id === "") {
          this.formData.power_plant_id = null;
        }
        if (this.formData.location.latitude === "") {
          this.formData.location.latitude = null;
        }
        if (this.formData.location.longitude === "") {
          this.formData.location.longitude = null;
        }

        this.$emit(
          "changeForm",
          JSON.stringify(newVal) !== JSON.stringify(this.initialForm)
        );
        this.changeForm = false;
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    check(datum: any) {
      if (typeof datum === "string") return datum;
      else if (typeof datum === "object" && datum !== null) return datum.id;
      else return null;
    },

    issuerCount(e: number) {
      this.issuer_count = e;
    },

    selectedSite(e: any) {
      this.formData.eic = e.eic || "-";
      this.formData.power_plant_id = e.id;
      this.formData.name = e.shortName;
    },

    async createProductionSiteFn() {
      this.error = {};
      this.formSubmitted = true;
      if (this.formData.capacity === null) {
        this.formData.capacity = 0;
      }
      await createProductionSiteData(this.formData)
        .then(res => {
          Notification({
            title: "Operation successful",
            message: "Production site created successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          this.$emit("onSuccess", res);
        })
        .catch(err => {
          this.error = { ...err.body };
        });

      this.formSubmitted = false;
    },

    async updateProductionSiteFn() {
      this.error = {};
      this.formSubmitted = true;

      await updateProductionSiteData(this.$route.params.id, this.formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Production site updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          this.$emit("onSuccess", true);
        })
        .catch(err => {
          this.error = { ...err.body };
        });

      this.formSubmitted = false;
    },
  },
});
