





























































































import dayjs from "dayjs";
import { defineComponent } from "@vue/composition-api";
import { ElTable } from "element-ui/types/table";
import { seperateThousand } from "@/utils";
import { IProductionDataResult } from "@/lib/types/energy-data/production_data";
import { getProductionDataList } from "@/api/energy-data/production_data";

export default defineComponent({
  name: "ProductionMatchingTable",
  components: {
    ExpandDetails: () => import("@/components/ExpandDetails.vue"),
  },

  data () {
    return {
      results: [] as IProductionDataResult[],
      count: 0,
      page: 1,
    };
  },

  computed: {
    dates (): string {
      return (
        dayjs(this.$store.state.period[0]).format("D MMMM YYYY, HH:mm") + " ~ " +
        dayjs(this.$store.state.period[1]).format("D MMMM YYYY, HH:mm")
      );
    },
  },

  async beforeMount() {
    await this.getResults(24, 0);
  },

  methods: {
    async getResults(limit = 24, offset = 0): Promise<void> {
      await getProductionDataList({
        productionSite: this.$route.params.id,
        productionStartAfter: this.$store.state.period[0],
        productionStartBefore: this.$store.state.period[1],
        orderBy: ["-production_start"],
        limit,
        offset,
      })
        .then(res => {
          if (res.results) {
            this.results = res.results;
            this.count = res.count;
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.error("getConsumptionDataList", err.body);
        });
    },

    formatDate (date: string | Date): string {
      return dayjs(date).format("D MMM YYYY");
    },

    formatTime (date: string | Date): string {
      return dayjs(date).format("HH:mm");
    },

    rowClick (row: any): void {
      (this.$refs.table as ElTable).toggleRowExpansion(row);
    },

    expandChange (row: any, expanded: any): void {
      const rows = [...expanded];

      if (rows.length > 1) {
        rows.pop();
        rows.forEach(row => (this.$refs.table as ElTable).toggleRowExpansion(row));
      }

      this.$emit("selected", row);
    },

    currentChange (i: number): void {
      this.page = i; // starts from 1, not index-based
      this.results.length = 0; // reset results everytime to put fresh data

      if (i === 1) this.getResults(24, 0);
      else if (i > 1) this.getResults(24, (i - 1) * 24);
    },
  },
  setup () {
    return { seperateThousand };
  },
});
