import { OrganizationTypes } from "../types/base";
import { ILocation } from "../types/location";
import { IUserState } from "../types/user";
import { IRetailerState } from "../types/organizations/retailer";
import { IGridOperatorState } from "../types/organizations/grid_operator";
import { IConsumerState } from "../types/organizations/consumer";
import { IProducerState } from "../types/organizations/producer";

export const INIT_LOCATION: ILocation = {
  latitude: null,
  longitude: null,
  country: "" as ILocation["country"],
  city: "",
  province_name: "",
  province_code: "",
  district_name: "",
  district_code: "",
  neighborhood_name: "",
  neighborhood_code: "",
  street_name: "",
  street_code: "",
  building_number: "",
  independent_section_code: "",
  updated_at: "",
  created_at: "",
  id: "",
};

export const STATE_USER: IUserState = {
  details: {
    id: "",
    email: "",
    username: null,
    last_login: "",
    created_at: "",
    updated_at: "",
    organization: "",
    organization_type: "" as OrganizationTypes,
    first_name: "",
    last_name: "",
    phone_number: "",
    is_staff: false,
    is_organization_admin: false,
    is_superuser: false,
    is_active: false,
  },
  details_loaded: false,
};

export const STATE_RETAILER: IRetailerState = {
  details: {
    id: "",
    created_at: "",
    updated_at: "",
    blockchain_account_address: "",
    website_url: "",
    name: "",
    location: {} as ILocation,
    is_active: false,
    is_registrant: false,
  },
  details_loaded: false,
};

export const STATE_GRID_OPERATOR: IGridOperatorState = {
  details: {
    id: "",
    created_at: "",
    updated_at: "",
    blockchain_account_address: "",
    website_url: "",
    name: "",
    location: {} as ILocation,
    is_active: false,
    is_distribution_system_operator: false,
  },
  details_loaded: false,
};

export const STATE_CONSUMER: IConsumerState = {
  details: {
    id: "",
    created_at: "",
    updated_at: "",
    blockchain_account_address: "",
    website_url: "",
    partner_id: "",
    name: "",
    retailer: "",
    energy_source_max_distance: null,
    location: {} as ILocation,
    is_active: false,
  },
  details_loaded: false,
};

export const STATE_PRODUCER: IProducerState = {
  details: {
    id: "",
    blockchain_account_address: "",
    created_at: "",
    updated_at: "",
    retailer: null,
    name: "",
    website_url: "",
    location: {} as ILocation,
    is_active: false,
  },
  details_loaded: false,
};

export const STATE_EMPTY = {
  user: { ...STATE_USER },
  retailer: { ...STATE_RETAILER },
  gridOperator: { ...STATE_GRID_OPERATOR },
  consumer: { ...STATE_CONSUMER },
  producer: { ...STATE_PRODUCER },
};
