






























import { randomId } from "@/utils";
import { defineComponent } from "@vue/composition-api";
import techTypes from "../../helpers/tech_type";

export default defineComponent({
  name: "TechnologySelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    source: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      items: [...techTypes],
      id: randomId(),
      attrs: {},
    };
  },
  computed: {
    options () {
      const filtered: { value: string; label: string; source: string[] }[] = [];

      this.items.forEach((item, index) => {
        if (item.source.some(source => this.source === source)) {
          filtered.push(this.items[index]);
        }
      });

      return filtered;
    },
  },
  watch: {
    "$props.required": {
      handler (newVal) {
        if (newVal !== null && newVal !== undefined) {
          this.attrs = {
            "vv-rules": "required",
          };
        } else {
          this.attrs = {};
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange (e: string) {
      this.$emit("input", e);
    },
  },
});
