export default [
  {
    name: "Turkey",
    short: "TR",
    latitude: 39.0015406,
    longitude: 30.6891795,
    cities: [
      {
        city: "Adana",
        plate_code: " 01",
        phone_area: "0322",
        states: [
          "Aladağ",
          "Seyhan",
          "Yüreğir",
          "Çukurova",
          "Sarıçam",
          "Ceyhan",
          "Kozan",
          "İmamoğlu",
          "Karataş",
          "Karaisalı",
          "Pozantı",
          "Yumurtalık",
          "Tufanbeyli",
          "Feke",
          "Saimbeyli",
        ],
      },
      {
        city: "Adıyaman",
        plate_code: "02",
        phone_area: "416",
        states: [
          "Merkez",
          "Kahta",
          "Besni",
          "Gölbaşı",
          "Gerger",
          "Sincik",
          "Çelikhan",
          "Tut",
          "Samsat",
        ],
      },
      {
        city: "Afyonkarahisar",
        plate_code: "03",
        phone_area: "272",
        states: [
          "Merkez",
          "Sandıklı",
          "Dinar",
          "Bolvadin",
          "Sinanpaşa",
          "Emirdağ",
          "Şuhut",
          "Çay",
          "İhsaniye",
          "İscehisar",
          "Sultandağı",
          "Çobanlar",
          "Dazkırı",
          "Başmakçı",
          "Hocalar",
          "Bayat",
          "Evciler",
          "Kızılören",
        ],
      },
      {
        city: "Ağrı",
        plate_code: "04",
        phone_area: "472",
        states: [
          "Merkez",
          "Patnos",
          "Doğubayazıt",
          "Diyadin",
          "Eleşkirt",
          "Tutak",
          "Taşlıçay",
          "Hamur",
        ],
      },
      {
        city: "Amasya",
        plate_code: "05",
        phone_area: "358",
        states: [
          "Merkez",
          "Merzifon",
          "Suluova",
          "Taşova",
          "Göynücek",
          "Hamamözü",
        ],
      },
      {
        city: "Ankara",
        plate_code: "06",
        phone_area: "312",
        states: [
          "Çankaya",
          "Keçiören",
          "Yenimahalle",
          "Mamak",
          "Etimesgut",
          "Sincan",
          "Altındağ",
          "Pursaklar",
          "Gölbaşı",
          "Polatlı",
          "Çubuk",
          "Kahramankazan",
          "Beypazarı",
          "Haymana",
          "Elmadağ",
          "Akyurt",
          "Şereflikoçhisar",
          "Bala",
          "Kızılcahamam",
          "Nallıhan",
          "Ayaş",
          "Çamlıdere",
          "Kalecik",
          "Güdül",
          "Evren",
        ],
      },
      {
        city: "Antalya",
        plate_code: "07",
        phone_area: "242",
        states: [
          "Kepez",
          "Muratpaşa",
          "Alanya",
          "Manavgat",
          "Konyaaltı",
          "Serik",
          "Aksu",
          "Kumluca",
          "Döşemealtı",
          "Kaş",
          "Korkuteli",
          "Gazipaşa",
          " Finike",
          "Kemer",
          "Elmalı",
          "Demre",
          "Akseki",
          "Gündoğmuş",
          "İbradi",
        ],
      },
      {
        city: "Artvin",
        plate_code: "08",
        phone_area: "466",
        states: [
          "Merkez",
          "Hopa",
          "Borçka",
          "Arhavi",
          "Yusufeli",
          "Şavşat",
          "Aksu",
          "Kumluca",
          "Ardanuç",
          "Kemalpaşa",
          "Murgul",
        ],
      },
      {
        city: "Aydın",
        plate_code: "09",
        phone_area: "256",
        states: [
          "Efeler",
          "Nazilli",
          "Söke",
          "Kuşadası",
          "Didim",
          "İncirliova",
          "Çine",
          "Germencik",
          "Bozdoğan",
          "Köşk",
          "Kuyucak",
          "Koçarlı",
          "Sultanhisar",
          "Karacasu",
          "Buharkent",
          "Yenipazar",
          "Karpuzlu",
        ],
      },
      {
        city: "Balıkesir",
        plate_code: "10",
        phone_area: "266",
        states: [
          "Altıeylül",
          "Karesi",
          "Edremit",
          "Bandırma",
          "Gönen",
          "Ayvalık",
          "Burhaniye",
          "Bigadiç",
          "Susurluk",
          "Dursunbey",
          "Sındırgı",
          "Erdek",
          "İvrindi",
          "Havran",
          "Kepsut",
          "Manyas",
          "Savaştepe",
          "Gömeç",
          "Balya",
          "Marmara",
        ],
      },
      {
        city: "Bilecik",
        plate_code: "11",
        phone_area: "228",
        states: [
          "Merkez",
          "Bozüyük",
          "Osmaneli",
          "Söğüt",
          "Gölpazarı",
          "Pazaryeri",
          "İnhisar",
          "Yenipazar",
        ],
      },
      {
        city: "Bingöl",
        plate_code: "12",
        phone_area: "426",
        states: [
          "Merkez",
          "Genç",
          "Solhan",
          "Karlıova",
          "Adaklı",
          "Kiğı",
          "Yedisu",
          "Yayladere",
        ],
      },
      {
        city: "Bitlis",
        plate_code: "13",
        phone_area: "434",
        states: [
          "Tatvan",
          "Merkez",
          "Güroymak",
          "Ahlat",
          "Hizan",
          "Mutki",
          "Adilcevaz",
        ],
      },
      {
        city: "Bolu",
        plate_code: "14",
        phone_area: "374",
        states: [
          "Merkez",
          "Gerede",
          "Mudurnu",
          "Göynük",
          "Mengen",
          "Yeniçağa",
          "Dörtdivan",
          "Seben",
          "Kıbrıscık",
        ],
      },
      {
        city: "Burdur",
        plate_code: "15",
        phone_area: "248",
        states: [
          "Merkez",
          "Bucak",
          "Gölhisar",
          "Yeşilova",
          "Çavdır",
          "Tefenni",
          "Ağlasun",
          "Karamanlı",
          "Altınyayla",
          "Çeltikçi",
          "Kemer",
        ],
      },
      {
        city: "Bursa",
        plate_code: "16",
        phone_area: "224",
        states: [
          "Osmangazi",
          "Yıldırım",
          "Nilüfer",
          "İnegöl",
          "Gemlik",
          "Mustafakemalpa",
          "Mudanya",
          "Gürsu",
          "Karacabey",
          "Orhangazi",
          "Kestel",
          "Yenişehir",
          "İznik",
          "Orhaneli",
          "Keles",
          "Büyükorhan",
          "Harmancık",
        ],
      },
      {
        city: "Çanakkale",
        plate_code: "17",
        phone_area: "286",
        states: [
          "Merkez",
          "Biga",
          "Çan",
          "Gelibolu",
          "Ayvacık",
          "Ezine",
          "Yenice",
          "Bayramiç",
          "Lapseki",
          "Gökçeada",
          "Eceabat",
          "Bozcaada",
        ],
      },
      {
        city: "Çankırı",
        plate_code: "18",
        phone_area: "376",
        states: [
          "Merkez",
          "Orta",
          "Çerkeş",
          "Ilgaz",
          "Şabanözü",
          "Kurşunlu",
          "Yapraklı",
          "Kızılırmak",
          "Eldivan",
          "Atkaracalar",
          "Korgun",
          "Bayramören",
        ],
      },
      {
        city: "Çorum",
        plate_code: "19",
        phone_area: "364",
        states: [
          "Merkez",
          "Sungurlu",
          "Osmancık",
          "İskilip",
          "Alaca",
          "Bayat",
          "Kargı",
          "Mecitözü",
          "Ortaköy",
          "Uğurludağ",
          "Dodurga",
          "Oğuzlar",
          "Laçin",
          "Boğazkale",
        ],
      },
      {
        city: "Denizli",
        plate_code: "20",
        phone_area: "258",
        states: [
          "Pamukkale",
          "Merkezefendi",
          "Çivril",
          "Acıpayam",
          "Tavas",
          "Honaz",
          "Sarayköy",
          "Buldan",
          "Kale",
          "Çal",
          "Çameli",
          "Serinhisar",
          "Bozkurt",
          "Güney",
          "Çardak",
          "Bekilli",
          "Beyağaç",
          "Babadağ",
          "Baklan",
        ],
      },
      {
        city: "Diyarbakır",
        plate_code: "21",
        phone_area: "412",
        states: [
          "Bağlar",
          "Kayapınar",
          "Yenişehir",
          "Ergani",
          "Bismil",
          "Sur",
          "Silvan",
          "Çınar",
          "Çermik",
          "Dicle",
          "Kulp",
          "Hani",
          "Lice",
          "Eğil",
          "Hazro",
          "Kocaköy",
          "Çüngüş",
        ],
      },
      {
        city: "Edirne",
        plate_code: "22",
        phone_area: "284",
        states: [
          "Merkez",
          "Keşan",
          "Uzunköprü",
          "İpsala",
          "Havsa",
          "Meriç",
          "Enez",
          "Süloğlu",
          "Lalapaşa",
        ],
      },
      {
        city: "Elazığ",
        plate_code: "23",
        phone_area: "424",
        states: [
          "Merkez",
          "Kovancılar",
          "Karakoçan",
          "Palu",
          "Baskil",
          "Arıcak",
          "Maden",
          "Sivrice",
          "Keban",
          "Alacakaya",
          "Ağın",
        ],
      },
      {
        city: "Erzincan",
        plate_code: "24",
        phone_area: "446",
        states: [
          "Merkez",
          "Tercan",
          "Üzümlü",
          "Refahiye",
          "Çayırlı",
          "İliç",
          "Kemah",
          "Kemaliye",
          "Otlukbeli",
        ],
      },
      {
        city: "Erzurum",
        plate_code: "25",
        phone_area: "42",
        states: [
          "Yakutiye",
          "Palandöken",
          "Aziziye",
          "Horasan",
          "Oltu",
          "Pasinler",
          "Karayazı",
          "Hınıs",
          "Tekman",
          "Aşkale",
          "Karaçoban",
          "Şenkaya",
          "Çat",
          "Tortum",
          "Köprüköy",
          "İspir",
          "Narman",
          "Uzundere",
          "Olur",
          "Pazaryolu",
        ],
      },
      {
        city: "Eskişehir",
        plate_code: "26",
        phone_area: "222",
        states: [
          "Odunpazarı",
          "Tepebaşı",
          "Sivrihisar",
          "Çifteler",
          "Seyitgazi",
          "Alpu",
          "Mihalıççık",
          "Mahmudiye",
          "Beylikova",
          "İnönü",
          "Günyüzü",
          "Sarıcakaya",
          "Mihalgazi",
          "Han",
        ],
      },
      {
        city: "Gaziantep",
        plate_code: "27",
        phone_area: "342",
        states: [
          "Şahinbey",
          "Şehitkamil",
          "Nizip",
          "İslahiye",
          "Nurdağı",
          "Araban",
          "Oğuzeli",
          "Yavuzeli",
          "Karkamış",
        ],
      },
      {
        city: "Giresun",
        plate_code: "28",
        phone_area: "454",
        states: [
          "Merkez",
          "Bulancak",
          "Espiye",
          "Görele",
          "Tirebolu",
          "Şebinkarahisar",
          "Keşap",
          "Dereli",
          "Yağlıdere",
          "Piraziz",
          "Eynesil",
          "Alucra",
          "Çamoluk",
          "Güce",
          "Doğankent",
          "Çanakçı",
        ],
      },
      {
        city: "Gümüşhane",
        plate_code: "29",
        phone_area: "456",
        states: [
          "Merkez",
          "Kelkit",
          "Şiran",
          "Kürtün",
          "Torul",
          "Köse",
        ],
      },
      {
        city: "Hakkari",
        plate_code: "30",
        phone_area: "438",
        states: [
          "Yüksekova",
          "Merkez",
          "Şemdinli",
          "Derecik",
          "Çukurca",
        ],
      },
      {
        city: "Hatay",
        plate_code: "31",
        phone_area: "326",
        states: [
          "Antakya",
          "İskenderun",
          "Defne",
          "Dörtyol",
          "Samandağ",
          "Kırıkhan",
          "Reyhanlı",
          "Arsuz",
          "Altınözü",
          "Hassa",
          "Payas",
          "Erzin",
          "Yayladağı",
          "Belen",
          "Kumlu",
        ],
      },
      {
        city: "Isparta",
        plate_code: "32",
        phone_area: "246",
        states: [
          "Merkez",
          "Yalvaç",
          "Eğirdir",
          "Şarkikaraağaç",
          "Gelendost",
          "Keçiborlu",
          "Senirkent",
          "Sütçüler",
          "Gönen",
          "Uluborlu",
          "Atabey",
          "Aksu",
          "Yenişarbademli",
        ],
      },
      {
        city: "Mersin",
        plate_code: "33",
        phone_area: "324",
        states: [
          "Tarsus",
          "Toroslar",
          "Akdeniz",
          "Yenişehir",
          "Mezitli",
          "Erdemli",
          "Silifke",
          "Anamur",
          "Mut",
          "Bozyazı",
          "Gülnar",
          "Aydıncık",
          "Çamlıyayla",
        ],
      },
      {
        city: "İstanbul",
        plate_code: "34",
        phone_area: "212-216",
        states: [
          "Esenyurt",
          "Küçükçekmece",
          "Bağcılar",
          "Pendik",
          "Ümraniye",
          "Bahçelievler",
          "Üsküdar",
          "Sultangazi",
          "Maltepe",
          "Gaziosmanpaşa",
          "Kartal",
          "Kadıköy",
          "Esenler",
          "Kağıthane",
          "Fatih",
          "Avcılar",
          "Başakşehir",
          "Ataşehir",
          "Sancaktepe",
          "Eyüp",
          "Sarıyer",
          "Beylikdüzü",
          "Sultanbeyli",
          "Güngören",
          "Zeytinburnu",
          "Şişli",
          "Bayrampaşa",
          "Arnavutköy",
          "Tuzla",
          "Çekmeköy",
          "Büyükçekmece",
          "Beykoz",
          "Beyoğlu",
          "Bakırköy",
          "Silivri",
          "Beşiktaş",
          "Çatalca",
          "Şile",
          "Adalar",
        ],
      },
      {
        city: "İzmir",
        plate_code: "35",
        phone_area: "232",
        states: [
          "Buca",
          "Karabağlar",
          "Bornova",
          "Konak",
          "Karşıyaka",
          "Bayraklı",
          "Çiğli",
          "Torbalı",
          "Menemen",
          "Gaziemir",
          "Ödemiş",
          "Kemalpaşa",
          "Bergama",
          "Aliağa",
          "Menderes",
          "Tire",
          "Balçova",
          "Urla",
          "Narlıdere",
          "Dikili",
          "Kiraz",
          "Seferihisar",
          "Çeşme",
          "Bayındır",
          "Selçuk",
          "Foça",
          "Güzelbahçe",
          "Kınık",
          "Beydağ",
          "Karaburun",
        ],
      },
      {
        city: "Kars",
        plate_code: "36",
        phone_area: "474",
        states: [
          "Merkez",
          "Kağızman",
          "Sarıkamış",
          "Selim",
          "Digor",
          "Arpaçay",
          "Akyaka",
          "Susuz",
        ],
      },
      {
        city: "Kastamonu",
        plate_code: "37",
        phone_area: "366",
        states: [
          "Merkez",
          "Tosya",
          "Taşköprü",
          "Cide",
          "İnebolu",
          "Araç",
          "Bozkurt",
          "Daday",
          "Azdavay",
          "Çatalzeytin",
          "Doğanyurt",
          "Küre",
          "Pınarbaşı",
          "İhsangazi",
          "Şenpazar",
          "Abana",
          "Hanönü",
          "Seydiler",
          "Ağlı",
        ],
      },
      {
        city: "Kayseri",
        plate_code: "38",
        phone_area: "352",
        states: [
          "Melikgazi",
          "Kocasinan",
          "Talas",
          "Develi",
          "Yahyalı",
          "Bünyan",
          "Pınarbaşı",
          "Tomarza",
          "Yeşilhisar",
          "Sarıoğlan",
          "Hacılar",
          "Sarız",
          "Felahiye",
          "Akkışla",
          "Özvatan",
        ],
      },
      {
        city: "Kırklareli",
        plate_code: "39",
        phone_area: "288",
        states: [
          "Lüleburgaz",
          "Merkez",
          "Babaeski",
          "Vize",
          "Pınarhisar",
          "Demirköy",
          "Pehlivanköy",
          "Kofçaz",
        ],
      },
      {
        city: "Kırşehir",
        plate_code: "40",
        phone_area: "386",
        states: [
          "Merkez",
          "Kaman",
          "Mucur",
          "Çiçekdağı",
          "Akpınar",
          "Boztepe",
          "Akçakent",
        ],
      },
      {
        city: "Kocaeli",
        plate_code: "41",
        phone_area: "262",
        states: [
          "Gebze",
          "İzmit",
          "Darıca",
          "Körfez",
          "Gölcük",
          "Derince",
          "Çayırova",
          "Kartepe",
          "Başiskele",
          "Karamürsel",
          "Kandıra",
          "Dilovası",
        ],
      },
      {
        city: "Konya",
        plate_code: "42",
        phone_area: "332",
        states: [
          "Selçuklu",
          "Meram",
          "Karatay",
          "Ereğli",
          "Akşehir",
          "Beyşehir",
          "Çumra",
          "Seydişehir",
          "Ilgın",
          "Cihanbeyli",
          "Kulu",
          "Karapınar",
          "Kadınhanı",
          "Sarayönü",
          "Bozkır",
          "Yunak",
          "Hüyük",
          "Doğanhisar",
          "Altınekin",
          "Hadim",
          "Çeltik",
          "Güneysınır",
          "Emirgazi",
          "Taşkent",
          "Tuzlukçu",
          "Derebucak",
          "Akören",
          "Ahırlı",
          "Derbent",
          "Halkapınar",
          "Yalıhüyük",
        ],
      },
      {
        city: "Kütahya",
        plate_code: "43",
        phone_area: "274",
        states: [
          "Merkez",
          "Tavşanlı",
          "Simav",
          "Gediz",
          "Emet",
          "Altıntaş",
          "Domaniç",
          "Hisarcık",
          "Aslanapa",
          "Çavdarhisar",
          "Şaphane",
          "Pazarlar",
          "Dumlupınar",
        ],
      },
      {
        city: "Malatya",
        plate_code: "44",
        phone_area: "422",
        states: [
          "Yeşilyurt",
          "Battalgazi",
          "Doğanşehir",
          "Akçadağ",
          "Darende",
          "Hekimhan",
          "Yazıhan",
          "Pütürge",
          "Arapgir",
          "Kuluncak",
          "Arguvan",
          "Kale",
          "Doğanyol",
        ],
      },
      {
        city: "Manisa",
        plate_code: "45",
        phone_area: "236",
        states: [
          "Yunusemre",
          "Akhisar",
          "Şehzadeler",
          "Turgutlu",
          "Salihli",
          "Soma",
          "Alaşehir",
          "Saruhanlı",
          "Kula",
          "Demirci",
          "Kırıkağaç",
          "Sarıgöl",
          "Gördes",
          "Selendi",
          "Ahmetli",
          "Gölmarmara",
          "Köprübaşı",
        ],
      },
      {
        city: "Kahramanmaraş",
        plate_code: "46",
        phone_area: "344",
        states: [
          "Onikişubat",
          "Dulkadiroğlu",
          "Elbistan",
          "Afşin",
          "Türkoğlu",
          "Pazarcık",
          "Göksun",
          "Andırın",
          "Çağlayancerit",
          "Nurhak",
          "Ekinözü",
        ],
      },
      {
        city: "Mardin",
        plate_code: "47",
        phone_area: "482",
        states: [
          "Kızıltepe",
          "Artuklu",
          "Midyat",
          "Nusaybin",
          "Derik",
          "Mazıdağı",
          "Dargeçit",
          "Savur",
          "Yeşilli",
          "Ömerli",
        ],
      },
      {
        city: "Muğla",
        plate_code: "48",
        phone_area: "252",
        states: [
          "Bodrum",
          "Fethiye",
          "Milas",
          "Menteşe",
          "Marmaris",
          "Seydikemer",
          "Ortaca",
          "Yatağan",
          "Dalaman",
          "Köyceğiz",
          "Ula",
          "Datça",
          "Kavaklıdere",
        ],
      },
      {
        city: "Muş",
        plate_code: "49",
        phone_area: "436",
        states: [
          "Merkez",
          "Bulanık",
          "Malazgirt",
          "Varto",
          "Korkut",
          "Hasköy",
        ],
      },
      {
        city: "Nevşehir",
        plate_code: "50",
        phone_area: "384",
        states: [
          "Merkez",
          "Ürgüp",
          "Avanos",
          "Gülşehir",
          "Derinkuyu",
          "Acıgöl",
          "Kozaklı",
          "Hacıbektaş",
        ],
      },
      {
        city: "Niğde",
        plate_code: "51",
        phone_area: "388",
        states: [
          "Merkez",
          "Bor",
          "Çiftlik",
          "Ulukışla",
          "Altunhisar",
          "Çamardı",
        ],
      },
      {
        city: "Ordu",
        plate_code: "52",
        phone_area: "452",
        states: [
          "Altınordu",
          "Ünye",
          "Fatsa",
          "Perşembe",
          "Kumru",
          "Korgan",
          "Gölköy",
          "Ulubey",
          "Mesudiye",
          "Akkuş",
          "Aybastı",
          "Gürgentepe",
          "İkizce",
          "Çatalpınar",
          "Çaybaşı",
          "Çamaş",
          "Kabataş",
          "Kabadüz",
          "Gülyalı",
        ],
      },
      {
        city: "Rize",
        plate_code: "53",
        phone_area: "464",
        states: [
          "Merkez",
          "Çayeli",
          "Ardeşen",
          "Pazar",
          "Fındıklı",
          "Kalkandere",
          "Güneysu",
          "İyidere",
          "İkizdere",
          "Derepazarı",
          "Çamlıhemşin",
          "Hemşin",
        ],
      },
      {
        city: "Sakarya",
        plate_code: "54",
        phone_area: "264",
        states: [
          "Adapazarı",
          "Serdivan",
          "Akyazı",
          "Erenler",
          "Hendek",
          "Karasu",
          "Geyve",
          "Arifiye",
          "Sapanca",
          "Pamukova",
          "Ferizli",
          "Kocaali",
          "Kaynarca",
          "Söğütlü",
          "Karapürçek",
          "Taraklı",
        ],
      },
      {
        city: "Samsun",
        plate_code: "55",
        phone_area: "362",
        states: [
          "İlkadım",
          "Atakum",
          "Bafra",
          "Çarşamba",
          "Canik",
          "Vezirköprü",
          "Terme",
          "Tekkeköy",
          "Havza",
          "19 Mayıs",
          "Alaçam",
          "Salıpazarı",
          "Ayvacık",
          "Kavak",
          "Asarcık",
          "Ladik",
          "Yakakent",
        ],
      },
      {
        city: "Siirt",
        plate_code: "56",
        phone_area: "484",
        states: [
          "Merkez",
          "Kurtalan",
          "Pervari",
          "Baykan",
          "Şirvan",
          "Eruh",
          "Tillo",
        ],
      },
      {
        city: "Sinop",
        plate_code: "57",
        phone_area: "368",
        states: [
          "Merkez",
          "Boyabat",
          "Gerze",
          "Ayancık",
          "Durağan",
          "Türkeli",
          "Erfelek",
          "Saraydüzü",
          "Dikmen",
        ],
      },
      {
        city: "Sivas",
        plate_code: "58",
        phone_area: "346",
        states: [
          "Merkez",
          "Şarkışla",
          "Yıldızeli",
          "Suşehri",
          "Gemerek",
          "Zara",
          "Kangal",
          "Gürün",
          "Divriği",
          "Koyulhisar",
          "Hafik",
          "Ulaş",
          "Altınyayla",
          "İmranlı",
          "Akıncılar",
          "Gölova",
          "Doğanşar",
        ],
      },
      {
        city: "Tekirdağ",
        plate_code: "59",
        phone_area: "282",
        states: [
          "Çorlu",
          "Süleymanpaşa",
          "Çerkezköy",
          "Kapaklı",
          "Ergene",
          "Malkara",
          "Saray",
          "Şarköy",
          "Hayrabolu",
          "Muratlı",
          "Marmaraereğlisi",
        ],
      },
      {
        city: "Tokat",
        plate_code: "60",
        phone_area: "356",
        states: [
          "Merkez",
          "Erbaa",
          "Turhal",
          "Niksar",
          "Zile",
          "Reşadiye",
          "Almus",
          "Pazar",
          "Yeşilyurt",
          "Artova",
          "Sulusaray",
          "Başçiftlik",
        ],
      },
      {
        city: "Trabzon",
        plate_code: "61",
        phone_area: "462",
        states: [
          "Ortahisar",
          "Akçaabat",
          "Araklı",
          "Of",
          "Yomra",
          "Arsin",
          "Vakfıkebir",
          "Sürmene",
          "Maçka",
          "Beşikdüzü",
          "Çarşıbaşı",
          "Çaykara",
          "Tonya",
          "Düzköy",
          "Şalpazarı",
          "Hayrat",
          "Köprübaşı",
          "Dernekpazarı",
        ],
      },
      {
        city: "Tunceli",
        plate_code: "62",
        phone_area: "428",
        states: [
          "Merkez",
          "Pertek",
          "Mazgirt",
          "Çemişgezek",
          "Ovacık",
          "Hozat",
          "Pülümür",
          "Nazımiye",
        ],
      },
      {
        city: "Şanlıurfa",
        plate_code: "63",
        phone_area: "414",
        states: [
          "Eyyübiye",
          "Haliliye",
          "Siverek",
          "Viranşehir",
          "Karaköprü",
          "Akçakale",
          "Suruç",
          "Birecik",
          "Harran",
          "Ceylanpınar",
          "Bozova",
          "Hilvan",
        ],
      },
      {
        city: "Uşak",
        plate_code: "64",
        phone_area: "276",
        states: [
          "Merkez",
          "Banaz",
          "Eşme",
          "Sivaslı",
          "Ulubey",
          "Karahallı",
        ],
      },
      {
        city: "Van",
        plate_code: "65",
        phone_area: "432",
        states: [
          "İpekyolu",
          "Erciş",
          "Tuşba",
          "Edremit",
          "Özalp",
          "Çaldıran",
          "Başkale",
          "Muradiye",
          "Gürpınar",
          "Gevaş",
          "Saray",
          "Çatak",
          "Bahçesaray",
        ],
      },
      {
        city: "Yozgat",
        plate_code: "66",
        phone_area: "354",
        states: [
          "Merkez",
          "Sorgun",
          "Akdağmadeni",
          "Yerköy",
          "Boğazlıyan",
          "Sarıkaya",
          "Çekerek",
          "Şefaatli",
          "Saraykent",
          "Çayıralan",
          "Kadışehri",
          "Aydıncık",
          "Yenifakılı",
          "Çandır",
        ],
      },
      {
        city: "Zonguldak",
        plate_code: "67",
        phone_area: "372",
        states: [
          "Ereğli",
          "Merkez",
          "Çaycuma",
          "Devrek",
          "Kozlu",
          "Alaplı",
          "Kilimli",
          "Gökçebey",
        ],
      },
      {
        city: "Aksaray",
        plate_code: "68",
        phone_area: "382",
        states: [
          "Merkez",
          "Ortaköy",
          "Eskil",
          "Gülağaç",
          "Güzelyurt",
          "Sultanhanı",
          "Ağaçören",
          "Sarıyahşi",
        ],
      },
      {
        city: "Bayburt",
        plate_code: "69",
        phone_area: "458",
        states: [
          "Merkez",
          "Demirözü",
          "Aydıntepe",
        ],
      },
      {
        city: "Karaman",
        plate_code: "70",
        phone_area: "338",
        states: [
          "Merkez",
          "Ermenek",
          "Sarıveliler",
          "Ayrancı",
          "Kazımkarabekir",
          "Başyayla",
        ],
      },
      {
        city: "Kırıkkale",
        plate_code: "71",
        phone_area: "318",
        states: [
          "Merkez",
          "Yahşihan",
          "Keskin",
          "Delice",
          "Sulakyurt",
          "Bahşili",
          "Balışeyh",
          "Karakeçili",
          "Çelebi",
        ],
      },
      {
        city: "Batman",
        plate_code: "72",
        phone_area: "488",
        states: [
          "Merkez",
          "Kozluk",
          "Sason",
          "Beşiri",
          "Gercüş",
          "Hasankeyf",
        ],
      },
      {
        city: "Şırnak",
        plate_code: "73",
        phone_area: "486",
        states: [
          "Cizre",
          "Silopi",
          "Merkez",
          "İdil",
          "Uludere",
          "Beytüşşebap",
          "Güçlükonak",
        ],
      },
      {
        city: "Bartın",
        plate_code: "74",
        phone_area: "378",
        states: [
          "Merkez",
          "Ulus",
          "Amasra",
          "Kurucaşile",
        ],
      },
      {
        city: "Ardahan",
        plate_code: "75",
        phone_area: "478",
        states: [
          "Merkez",
          "Göle",
          "Çıldır",
          "Hanak",
          "Posof",
          "Damal",
        ],
      },
      {
        city: "Iğdır",
        plate_code: "76",
        phone_area: "476",
        states: [
          "Merkez",
          "Tuzluca",
          "Aralık",
          "Karakoyunlu",
        ],
      },
      {
        city: "Yalova",
        plate_code: "77",
        phone_area: "226",
        states: [
          "Merkez",
          "Çiftlikköy",
          "Çınarcık",
          "Altınova",
          "Armutlu",
          "Termal",
        ],
      },
      {
        city: "Karabük",
        plate_code: "78",
        phone_area: "370",
        states: [
          "Merkez",
          "Safranbolu",
          "Yenice",
          "Eskipazar",
          "Eflani",
          "Ovacık",
        ],
      },
      {
        city: "Kilis",
        plate_code: "79",
        phone_area: "348",
        states: [
          "Merkez",
          "Musabeyli",
          "Elbeyli",
          "Polateli",
        ],
      },
      {
        city: "Osmaniye",
        plate_code: "80",
        phone_area: "328",
        states: [
          "Merkez",
          "Kadirli",
          "Düziçi",
          "Bahçe",
          "Toprakkale",
          "Sumbas",
          "Hasanbeyli",
        ],
      },
      {
        city: "Düzce",
        plate_code: "81",
        phone_area: "380",
        states: [
          "Merkez",
          "Akçakoca",
          "Kaynaşlı",
          "Gölyaka",
          "Çilimli",
          "Yığılca",
          "Gümüşova",
          "Cumayeri",
        ],
      },
    ],
  },
];
