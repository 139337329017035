<template>
  <ValidationProvider
    :vid="vvVid"
    :rules="vvRules"
    :name="vvName"
    :skip-if-empty="skipIfEmpty"
    v-slot="{ errors }"
    slim
  >
    <div
      class="form__item"
      :class="[
        itemClass,
        name,
        errors.length ? 'not-valid' : '',
        required ? 'required' : ''
      ]"
      :data-name="name"
    >
      <label
        :for="id"
        class="form__item__label"
        :class="labelClass"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </label>

      <el-select
        v-if="options.length"
        v-bind="$attrs"
        :id="id"
        :name="name"
        :filterable="filterable"
        autocomplete="off"
        :value="value"
        @change="handleInput"
      >
        <slot
          name="options"
          :options="filteredOptions"
        >
          <el-option
            v-for="(item, index) in filteredOptions"
            :key="item.id || index"
            :label="(Array.isArray(options) && item) || item.name"
            :value="(Array.isArray(options) && item) || item.id"
            :data-cy="`${dropdownName}-${item}`"
          >
          </el-option>
        </slot>
      </el-select>

      <el-input-number
        v-else-if="numberInput"
        v-bind="$attrs"
        :id="id"
        :name="name"
        :value="value"
        :required="required"
        @input="handleInput"
      />

      <el-input
        v-else
        v-bind="$attrs"
        :id="id"
        :name="name"
        :value="value"
        :required="required"
        autocomplete="off"
        @input="handleInput"
      />

      <transition
        name="fade"
        mode="out-in"
      >
        <span
          class="form__item__error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </span>
        <span
          class="form__item__message"
          v-else-if="message && !errors.length"
        >
          {{ message }}
        </span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { randomId, slugify } from "../utils";

export default defineComponent({
  name: "FormItem",
  inheritAttrs: false,
  props: {
    dropdownName: {
      type: String,
      default: undefined,
    },
    vvVid: {
      type: String,
      default: undefined,
    },
    vvRules: {
      type: [String, Object],
      default: undefined,
    },
    vvName: {
      type: String,
      default: "",
      required: true,
    },
    skipIfEmpty: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    itemClass: {
      type: [String, Object, Array],
      default: "",
    },
    labelClass: {
      type: [String, Object, Array],
      default: "",
    },
    id: {
      type: String,
      default: randomId(),
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    numberInput: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: "",
    };
  },
  computed: {
    filteredOptions() {
      if (this.filter.length) {
        return this.options.filter(option => {
          const name = slugify(option.name);
          const filteredName = slugify(this.filter);

          return name.includes(filteredName);
        });
      } else {
        return this.options;
      }
    },
  },
  methods: {
    filterMethod(e) {
      this.filter = e;
    },
    handleInput (e) {
      this.$emit("input", e);
    },
  },
});
</script>
