// eslint-disable-next-line
// @ts-ignore
import { IListResponse } from "@/lib/types/base.js";
import { IMatchRequest } from "@/lib/types/energy-data/match.js";
import { energyDataApi } from "../../plugins/greenlink.js";

export function matchEnergyData(
  opts: {
    start_time: string;
    end_time: string;
    consumption_site: string;
    production_site: string;
  }
) {
  return new Promise<void>((resolve, reject) => {
    energyDataApi.energyDataMatchedEnergyDataMatchCreate(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response.body);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getMatchedEnergyData(
  opts: {
    id?: string;
    retailer?: string;
    amountMatched?: number;
    consumer?: string;
    consumptionData?: number; // integer
    consumptionSite?: string;
    producer?: string;
    productionData?: number; // integer
    productionSite?: string;
    limit?: number;
    offset?: number;
  }
) {
  return new Promise<any>((resolve, reject) => {
    energyDataApi.energyDataMatchedEnergyDataList({ ...opts }, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response.body);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getMatchRequest(
  opts?: Partial<IMatchRequest> & {
    limit?: number;
    offset?: number;
    orderBy?: string[];
    startAfter?: string;
    startBefore?: string;
  }
) {
  return new Promise<IListResponse<IMatchRequest>>((resolve, reject) => {
    energyDataApi.energyDataMatchRequestList(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response.body);
      } else {
        resolve(response.body);
      }
    });
  });
}
