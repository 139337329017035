

































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Notification } from "element-ui";

import { ILocation } from "@/lib/types/location";
import { formattedAddress } from "../../helpers/formats";
import { cloneDeep } from "lodash";
import { IProducerDetails } from "@/lib/types/organizations/producer";
import { createProducerData, updateProducerData } from "@/api/organizations/producer";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";

export default defineComponent({
  name: "ProducerCrudForm",
  components: {
    FormBase,
    FormItem,
    LocationForm: () => import("./LocationForm.vue"),
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    operation: {
      type: String,
      default: null,
      required: true,
      validator: function (val: string) {
        return ["create", "update"].indexOf(val) !== -1;
      },
    },
    id: {
      type: String,
      default: null,
      required: true,
    },
  },
  setup(props: { details: IProducerDetails }, vm: any) {
    // const { root } = rootInstance();

    const editLocationModal = ref(false);
    const propsDetails = cloneDeep(props.details);
    const formRef = ref(null);
    let changeForm = false;
    let initialForm = {};
    const formData = reactive<Partial<IProducerDetails>>({
      name: propsDetails.name || "",
      website_url: propsDetails.website_url || "",
      // retailer: root.$store.state.user.details.id,
      location: propsDetails.location || {
        latitude: null,
        longitude: null,
        country: "" as ILocation["country"],
        city: "",
        province_name: "",
        province_code: "",
        district_name: "",
        district_code: "",
        neighborhood_name: "",
        neighborhood_code: "",
        street_name: "",
        street_code: "",
        building_number: "",
        independent_section_code: "",
        updated_at: "",
        created_at: "",
        id: "",
      } as ILocation,
      is_active: propsDetails.is_active !== undefined ? propsDetails.is_active : true,
    });

    const fault = reactive({
      error: {},
    });

    const formSubmitted = ref(false);

    const createProducerFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await createProducerData(formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Producer created successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    const updateProducerFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await updateProducerData(propsDetails.id, formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Producer updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };
    onMounted(() => {
      vm.emit("changeForm", changeForm);
      initialForm = cloneDeep(formData);
      watch(
        () => formData,
        newVal => {
          if (formData && formData.location) {
            if ((formData.location.latitude as unknown as string) === "") { formData.location.latitude = null }
            if ((formData.location.longitude as unknown as string) === "") { formData.location.longitude = null }
          }

          vm.emit("changeForm", JSON.stringify(newVal) !== JSON.stringify(initialForm));
          changeForm = false;
        },
        { deep: true }
      );
    });

    return { formData, formRef, editLocationModal, createProducerFn, updateProducerFn, fault, formSubmitted, formattedAddress, changeForm, initialForm };
  },
});
