import Vue from "vue";
import Vuex from "vuex";
import { hideAll } from "tippy.js";

import { moduleUser } from "./modules/user";
import { moduleRetailer } from "./modules/organizations/retailer";
import { moduleConsumer } from "./modules/organizations/consumer";
import { IGlobalState, OrganizationTypes } from "@/lib/types/base";
// import {  STATE_EMPTY } from "@/lib/constants";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    period: [] as string[],
    theme: "",
    language: "",
    isOrganizationalMatching: false,
    txUrl: process.env.VUE_APP_EXPLORER_TX_URL,
    addressUrl: process.env.VUE_APP_EXPLORER_ADDRESS_URL,
    tokenUrl: process.env.VUE_APP_EXPLORER_TOKEN_URL,
  } as IGlobalState,
  mutations: {
    SET_PERIOD(state, payload) {
      Vue.set(state, "period", payload);
    },
    SET_THEME(state, payload) {
      Vue.set(state, "theme", payload);
    },
    SET_LANGUAGE(state, payload) {
      Vue.set(state, "language", payload);
    },
    SET_ORGANIZATIONAL_MATCHING(state, payload) {
      Vue.set(state, "isOrganizationalMatching", payload);
    },
  },
  actions: {
    setCurrentPeriod({ commit }, payload) {
      localStorage.setItem("period", JSON.stringify(payload));
      commit("SET_PERIOD", payload);
      hideAll();
    },
    setCurrentTheme({ commit }, payload) {
      localStorage.setItem("theme", payload);
      commit("SET_THEME", payload);
      hideAll();
    },
    setCurrentLanguage({ commit }, payload) {
      localStorage.setItem("language", payload);
      commit("SET_LANGUAGE", payload);
      hideAll();
    },
    setOrganizationalMatching({ commit }, payload) {
      commit("SET_ORGANIZATIONAL_MATCHING", payload);
    },
  },
  getters: {
    isEnerjisa: state => {
      return (
        (state.retailer &&
          state.retailer.details.name.toLowerCase().includes("enerjisa")) ||
        (state.consumer &&
          state.consumer.details.retailer_name &&
          state.consumer.details.retailer_name
            .toLowerCase()
            .includes("enerjisa"))
      );
    },
  },
  modules: {
    user: moduleUser,
  },
});

store.watch(
  state => state.user,
  newVal => {
    const userId = newVal ? newVal.details.id : "";
    const organizationType = newVal ? newVal.details.organization_type : "";

    if (userId.length !== 0) {
      if (
        organizationType === OrganizationTypes.RETAILER &&
        !store.hasModule("retailer")
      ) {
        store.registerModule("retailer", moduleRetailer);
      } else if (
        organizationType === OrganizationTypes.CONSUMER &&
        !store.hasModule("consumer")
      ) {
        store.registerModule("consumer", moduleConsumer);
      }
    } else if (userId.length < 1) {
      if (store.hasModule("retailer")) store.unregisterModule("retailer");
      if (store.hasModule("consumer")) store.unregisterModule("consumer");
    }
  },
  { deep: true, immediate: true }
);

export default store;
