


























































































































import { ICertificate, ICertificatePayload } from "@/api/certificates";
import { rootInstance } from "@/helpers/composition";
import { defineComponent, reactive, ref, watch, onMounted } from "@vue/composition-api";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Upload, Notification } from "element-ui";
import ProducerSelect from "../selectbox/ProducerSelect.vue";

interface IElementFile {
  name: string;
  percentage: number;
  raw: File;
  size: number;
  status: string;
  uid: number;
}

enum CertificateTypes {
  IREC = "I-REC"
}

export default defineComponent({
  name: "CertificatesUploadForm",
  components: {
    FormBase: () => import("../FormBase.vue"),
    FormItem: () => import("../FormItem.vue"),
    ProducerSelect,
  },
  props: {
    consumerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props: { consumerId: string; }, { emit }) {
    const { root } = rootInstance();
    const uploadRef = ref<Upload | null>(null);
    const selectedFile = ref<File>();
    const isLoading = ref(false);
    const fileList = ref([]);

    let changeForm = false;

    const form = reactive<Omit<ICertificatePayload, "upload">>({
      consumer: props.consumerId,
      content_type: "Energy attribute certificates",
      eac_type: CertificateTypes.IREC,
      name: "",
      producer: "",
      period_end: "",
      period_start: "",
    });

    const initialForm = Object.freeze({ ...form });

    const progress = reactive<{ loaded: number; total: number; percentage: number; done: boolean; }>({
      loaded: 0,
      total: 0,
      percentage: 0,
      done: false,
    });

    const fault = reactive({
      error: {},
    });

    const handleImport = (e: IElementFile) => {
      selectedFile.value = e.raw;
      form.name = e.raw.name
        .replace(/\s{1,}/g, "_")
        .replace(/(\s*)(.pdf)$/g, "");
    };

    /**
     * ? creates FormData(),
     * ? uploads the data via axios,
     * ? sets upload progress
    **/
    const handleUpload = async () => {
      isLoading.value = true;
      progress.done = false;

      const formData = new FormData();

      for (const item of Object.keys(form)) {
        formData.append(item, form[item]);
      }

      selectedFile.value && formData.append("upload", selectedFile.value);

      await axios({
        method: "post",
        baseURL: process.env.VUE_APP_API_PATH + "/logs/certificates/",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: function (progressEvent: ProgressEvent) {
          // Do whatever you want with the native progress event
          if (progressEvent.lengthComputable) {
            progress.total = progressEvent.total;
            progress.loaded = progressEvent.loaded;
            progress.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        },
      })
        .then((res: AxiosResponse<ICertificate>) => {
          Notification({
            title: "Operation successful",
            message: `The certificate ${res.data.name} uploaded successfully`,
            type: "success",
            position: "bottom-left",
          });

          emit("onSuccess", true);
          progress.done = true;
          handleRemove();
        })
        .catch((err: AxiosError<any>) => {
          if (err.response) {
            root.$message({
              message: err.response.data.errors.custom[0],
              center: true,
              type: "error",
            });

            // fault.error = err.response.data;
          }

          progress.done = false;
        })
        .finally(() => {
          progress.percentage = 0;
          isLoading.value = false;
        });
    };

    const handleRemove = () => {
      for (const _i of Object.keys(progress)) {
        const i = _i as keyof typeof progress;

        if (typeof progress[i] === "number") (progress as any)[i] = 0;
        else if (typeof progress[i] === "boolean") (progress as any)[i] = false;
      }

      selectedFile.value = undefined;
      if (uploadRef.value !== null) uploadRef.value.clearFiles();

      for (const item of Object.keys(form)) {
        form[item] = initialForm[item];
      }
    };

    onMounted(() => {
      emit("changeForm", changeForm);

      watch(
        selectedFile,
        newFile => {
          if (newFile) {
            changeForm = true;
          }

          emit("changeForm", changeForm);
          changeForm = false;
        },
        { deep: true }
      );
    });

    return {
      isLoading,
      form,
      fileList,
      fault,
      handleImport,
      handleUpload,
      handleRemove,
      uploadRef,
      selectedFile,
      progress,
      changeForm,
      CertificateTypes,
    };
  },
});
