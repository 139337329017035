<template>
  <span class="icon-text">
    <SvgInjector
      class="icon-text__icon"
      :class="iconClass"
      :src="require(`${iconUrl}`)"
    />
    <span
      class="icon-text__text"
      :class="textClass"
    >
      {{ text }}
    </span>
  </span>
</template>

<script>
export default {
  name: "IconText",
  props: {
    iconUrl: {
      type: String,
      default: null,
      required: true,
    },
    iconClass: {
      type: String,
      default: null,
      required: false,
    },
    text: {
      type: String,
      default: null,
      required: true,
    },
    textClass: {
      type: String,
      default: null,
      required: false,
    },
  },
};
</script>
