// eslint-disable-next-line
// @ts-ignore
import { energyAssetsApi } from "../../plugins/greenlink.js";
import { IFilterQuery, IListResponse } from "@/lib/types/base";
import { IProductionSiteDetails } from "@/lib/types/energy-assets/production_site";

export function getProductionSitesList (opts: Partial<IProductionSiteDetails> & IFilterQuery) {
  return new Promise<IListResponse<IProductionSiteDetails>>((resolve, reject) => {
    energyAssetsApi.energyAssetsProductionSitesList(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getProductionSiteData (id: string) {
  return new Promise<IProductionSiteDetails>((resolve, reject) => {
    energyAssetsApi.energyAssetsProductionSitesRetrieve(id, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

// ! CRUD Operations
export function createProductionSiteData (payload: Partial<IProductionSiteDetails>) {
  return new Promise<IProductionSiteDetails>((resolve, reject) => {
    energyAssetsApi.energyAssetsProductionSitesCreate(payload, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateProductionSiteData (id: string, payload: Partial<IProductionSiteDetails>) {
  return new Promise<IProductionSiteDetails>((resolve, reject) => {
    const opts = {
      patchedProductionSiteUpdate: payload,
    };

    energyAssetsApi.energyAssetsProductionSitesPartialUpdate(id, opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteProductionSiteData (id: string) {
  return new Promise<null>((resolve, reject) => {
    energyAssetsApi.energyAssetsProductionSitesDestroy(id, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
