import Chart from "chart.js";
import i18n from "../i18n";
import { arrSum, seperateThousand } from "@/utils";
import store from "../store";

export const chartColors: Record<string, string> = {
  Wind: "#9DC9E9",
  Solar: "#ffc759",
  "Hydro-electric Head": "#0081a7",
  Marine: "#519e8a",
  Thermal: "#f07167",
  Solid: "#68534d",
  Liquid: "#b38cb4",
  Gaseous: "#666a86",
  Others: "#F9F5EF",
  Matched: "#36B37E",
  Unmatched: "#C5CAD3",
};

export function aggregatedChartLayout(
  ctx:
    | string
    | CanvasRenderingContext2D
    | HTMLCanvasElement
    | ArrayLike<CanvasRenderingContext2D | HTMLCanvasElement>,
  datasets: Chart.ChartDataSets[],
  theme = store.state.theme
) {
  return new Chart(ctx, {
    type: "bar",
    data: {
      labels: [
        "12AM",
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
      ],
      datasets: [...datasets],
    },
    options: {
      animation: {
        duration: 800,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              fontColor: theme === "light" ? "black" : "white",
            },
            gridLines: {
              display: false,
              color: theme === "light" ? "black" : "white",
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "kWh",
              fontColor: theme === "light" ? "black" : "white",
            },
            ticks: {
              callback: label => {
                return `${seperateThousand(Number(label))}`;
              },
              beginAtZero: true,
              fontColor: theme === "light" ? "black" : "white",
            },
            gridLines: {
              color: "#C6CBD2",
            },
          },
        ],
      },
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          fontColor: theme === "light" ? "black" : "white",
          fontFamily: "sans-serif",
          fontSize: 12,
          usePointStyle: true,
          padding: 16,
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: ([tooltipItem]: any, data: any) => {
            if (tooltipItem.index < 23) {
              return `${data.labels[tooltipItem.index]}-${
                data.labels[tooltipItem.index + 1]
              }`;
            } else {
              return `${data.labels[tooltipItem.index]}-${data.labels[0]}`;
            }
          },
          label: (tooltipItem: any, data: any) => {
            const total = data.datasets[0].data.map((num: any, idx: any) => {
              if (data.datasets.length > 1) {
                for (let i = 1; i < data.datasets.length; i++) {
                  num += data.datasets[i].data[idx];
                }
                return num;
              } else {
                return num;
              }
            });
            return Number(tooltipItem.value)
              ? `${
                  data.datasets[tooltipItem.datasetIndex].label
                }: ${seperateThousand(Number(tooltipItem.value))} kWh  (${(
                  (Number(tooltipItem.value) / total[tooltipItem.index]) *
                  100
                ).toFixed(2)} %)`
              : "";
          },
          footer: (tooltipItem: any, data: any) => {
            const total = tooltipItem.reduce(
              (a: any, b: any) => a + parseInt(b.value),
              0
            );
            let matchedData = 0;
            for (let i = 0; i < data.datasets.length - 1; i++) {
              if (tooltipItem.length > 0) {
                matchedData += data.datasets[i].data[tooltipItem[0].index];
              } else {
                matchedData += data.datasets[i].data[tooltipItem.index];
              }
            }
            const percentage = total > 0 ? (matchedData / total) * 100 : 0;
            return `${i18n.t(
              "labels.total_matched_percentage"
            )}: ${percentage.toFixed(2)} %\n${i18n.t(
              "labels.total_aggregated_data"
            )}: ${seperateThousand(total)} kWh `;
          },
        },
        titleFontSize: 16,
        titleMarginBottom: 8,
        cornerRadius: 8,
        bodyFontSize: 15,
        footerFontSize: 15,
        footerMarginTop: 8,
        xPadding: 8,
        yPadding: 8,
        // displayColors: false,
      },
    },
  });
}

export function pieChartLayout(
  ctx:
    | string
    | CanvasRenderingContext2D
    | HTMLCanvasElement
    | ArrayLike<CanvasRenderingContext2D | HTMLCanvasElement>,
  datasets: Chart.ChartData
) {
  return new Chart(ctx, {
    type: "doughnut",
    data: {
      ...datasets,
    },
    options: {
      animation: {
        duration: 800,
      },
      cutoutPercentage: 75,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: ([tooltipItem]: any, data: any) => {
            return `${data.labels[tooltipItem.index]}`;
          },
          label: (tooltipItem: any, data: any) => {
            return `${seperateThousand(
              data.datasets[0].data[tooltipItem.index]
            )} kWh (${(
              (data.datasets[0].data[tooltipItem.index] /
                arrSum(data.datasets[0].data)) *
              100
            ).toFixed(2)} %)`;
          },
        },
        titleFontSize: 16,
        titleMarginBottom: 8,
        cornerRadius: 8,
        bodyFontSize: 15,
        xPadding: 8,
        yPadding: 8,
        // displayColors: false,
      },
    },
  });
}
