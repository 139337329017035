





















import { defineComponent } from "@vue/composition-api";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/tr";

export default defineComponent({
  name: "Datepicker",
  components: {
    DatePicker,
  },
  props: {
    start: {
      type: Boolean,
      default: false,
    },
    end: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "datetime",
    },
    partnerFormat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datetime: "",
    };
  },
  computed: {
    customFormat() {
      if (this.type === "datetime") {
        return "DD MMM YYYY, HH:mm";
      } else {
        return "DD MMM YYYY";
      }
    },
    customValueType() {
      if (this.type === "datetime") {
        return "YYYY-MM-DDTHH:mm:ss+03:00";
      } else {
        if (this.start) {
          return "YYYY-MM-DDT00:00:00+03:00";
        }
        if (this.end) {
          if (this.partnerFormat) return "YYYY-MM-DDT00:00:00+03:00";
          else return "YYYY-MM-DDT23:59:00+03:00";
        }
      }
    },
  },
  mounted() {
    this.datetime = this.$store.state.period;

    this.$watch(
      () => this.datetime,
      (newVal, oldVal) => {
        if (isEqual(newVal, oldVal)) {
          return newVal;
        }
      }
    );
  },
  methods: {
    handleChange(date: string) {
      this.datetime = date;
      this.$emit("input", date);
    },
    disabledAfter(date: Date) {
      const tomorrow = dayjs()
        .add(1, "day")
        .format("YYYY-MM-DDT00:00:00+03:00");

      return date >= new Date(tomorrow);
    },
  },
});
