// eslint-disable-next-line
// @ts-ignore
import { energyAssetsApi } from "../../plugins/greenlink.js";
import { IConsumptionSiteDetails } from "@/lib/types/energy-assets/consumption_site";
import { IFilterQuery, IListResponse } from "@/lib/types/base";
import axios from "axios";

export interface IConsumptionSiteContracts {
  id: string;
  consumption_site: IConsumptionSiteDetails;
  amount_consumed: number | null;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
}

export function getConsumptionSitesList (opts?: Partial<IConsumptionSiteDetails> & IFilterQuery & {
  orderBy?: [string] | string;
  search?: string;
}) {
  return new Promise<IListResponse<IConsumptionSiteDetails>>((resolve, reject) => {
    energyAssetsApi.energyAssetsConsumptionSitesList(opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getConsumptionSitesContracts(params: Record<string, any>) {
  return new Promise<IListResponse<IConsumptionSiteContracts>>((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_PATH + "/energy-assets/consumption-site-contracts", { params })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getConsumptionSiteData (id: string) {
  return new Promise<IConsumptionSiteDetails>((resolve, reject) => {
    energyAssetsApi.energyAssetsConsumptionSitesRetrieve(id, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

// ! CRUD Operations
export function createConsumptionSiteData (payload: Partial<IConsumptionSiteDetails>) {
  return new Promise<IConsumptionSiteDetails>((resolve, reject) => {
    energyAssetsApi.energyAssetsConsumptionSitesCreate(payload, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateConsumptionSiteData (id: string, payload: Partial<IConsumptionSiteDetails>) {
  return new Promise<IConsumptionSiteDetails>((resolve, reject) => {
    const opts = {
      patchedConsumptionSite: payload,
    };

    energyAssetsApi.energyAssetsConsumptionSitesPartialUpdate(id, opts, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteConsumptionSiteData (id: string) {
  return new Promise<null>((resolve, reject) => {
    energyAssetsApi.energyAssetsConsumptionSitesDestroy(id, (errors: any, _data: any, response: any) => {
      if (errors) {
        reject(errors.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
