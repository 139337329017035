


















































































































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Notification } from "element-ui";

import { cloneDeep } from "lodash";
import { IUserDetails } from "@/lib/types/user";
import { createPersonData, updatePersonData } from "@/api/people";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import { OrganizationTypes } from "@/lib/types/base";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "UserCrudForm",
  components: {
    FormBase,
    FormItem,
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    organization: {
      type: String,
      default: "",
      required: true,
    },
    operation: {
      type: String,
      default: null,
      required: true,
      validator: function (val: string) {
        return ["create", "update"].indexOf(val) !== -1;
      },
    },
    id: {
      type: String,
      default: null,
      required: true,
    },
  },
  setup(props: { id: string; organization: string; details: IUserDetails; operation: string; }, vm: any) {
    const { root } = rootInstance();
    const editLocationModal = ref(false);
    const propsDetails = cloneDeep(props.details);
    let changeForm = false;

    const formData = reactive<Partial<IUserDetails>>({
      id: propsDetails.id || undefined,
      first_name: propsDetails.first_name || "",
      last_name: propsDetails.last_name || "",
      // username: propsDetails.username || "",
      email: propsDetails.email || "",
      phone_number: propsDetails.phone_number || "",
      password1: propsDetails.password1 || "",
      password2: propsDetails.password2 || "",
      organization: props.organization,
      is_staff: true,
      is_organization_admin: propsDetails.is_organization_admin !== undefined ? propsDetails.is_organization_admin : false,
      is_active: propsDetails.is_active !== undefined ? propsDetails.is_active : true,
    });

    const initialForm = Object.freeze({ ...formData });

    const state = reactive({
      error: {},
    });

    const formRef = ref(null);

    const formSubmitted = ref(false);

    const createUserFn = async () => {
      state.error = {};
      formSubmitted.value = true;

      // @ts-ignore
      // console.log(root.$auth);

      // @ts-ignore
      // root.$auth.checkSession(
      //   {
      //     audience: "https://api.greenlink.energy/",
      //     scope: 'read:current_user',
      //   },
      //   (err, res) => {
      //     err && console.log(err);
      //     res && console.log(res);
      //   }
      // );

      await createPersonData(formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "User created successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          if (err.statusCode >= 500) {
            state.error = {
              errors: { internal_error: "An error occured on our side. Please contact support via support@blok-z.com" },
            };
          } else if (err.body) {
            state.error = { ...err.body };
          }
        });

      formSubmitted.value = false;
    };

    const updateUserFn = async () => {
      state.error = {};
      formSubmitted.value = true;

      const patchedUser: Partial<IUserDetails> = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone_number,
        is_staff: formData.is_staff,
        is_organization_admin: formData.is_organization_admin,
        is_active: formData.is_active,
      };

      // initialFormData.username !== formData.username && (patchedUser.username = formData.username);

      await updatePersonData(propsDetails.id, { patchedUser } as any)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "User updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          state.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    onMounted(() => {
      watch(
        () => formData,
        newVal => {
          changeForm = false;
          for (const key of Object.keys(newVal)) {
            if (key !== "location" && (newVal as any)[key] !== (initialForm as any)[key]) {
              changeForm = true;
            }
          }
          vm.emit("changeForm", changeForm);
        },
        { deep: true }
      );
    });

    return {
      formData,
      editLocationModal,
      createUserFn,
      updateUserFn,
      state,
      userRoles: root.$store.getters["user/roles"],
      formSubmitted,
      formRef,
      changeForm,
      initialForm,
      OrganizationTypes,
    };
  },
});
