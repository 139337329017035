



































































































































































import { randomId } from "@/utils";
import { defineComponent } from "@vue/composition-api";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import CountrySelect from "../selectbox/CountrySelect.vue";
import CitySelect from "../selectbox/CitySelect.vue";
import GeocodeWrapper from "../GeocodeWrapper.vue";
import { ILocation } from "@/lib/types/location";

export default defineComponent({
  name: "LocationForm",
  components: {
    FormBase,
    FormItem,
    CountrySelect,
    CitySelect,
    GeocodeWrapper,
  },
  model: {
    prop: "form",
    event: "update:change",
  },
  props: {
    form: {
      type: Object,
      default: () => ({
        latitude: null,
        longitude: null,
        country: "" as ILocation["country"],
        city: "",
        province_name: "",
        province_code: "",
        district_name: "",
        district_code: "",
        neighborhood_name: "",
        neighborhood_code: "",
        street_name: "",
        street_code: "",
        building_number: "",
        independent_section_code: "",
        updated_at: "",
        created_at: "",
        id: "",
      }),
    },
    submit: {
      type: Function,
      default: () => { },
    },
    id: {
      type: String,
      default: randomId(),
    },
    headline: {
      type: String,
      default: "",
    },
    errors: {
      type: [Array, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSearchAddress: false,
    };
  },
  methods: {
    getCoordinates(e: Record<string, any>) {
      this.form.latitude = e.lat;
      this.form.longitude = e.lng;
      this.form.country = e.country;
      this.form.city = e.city;
      if (e.state) {
        this.form.district_name = e.state;
      } else {
        this.form.district_name = "";
      }
      if (e.neighborhood) {
        this.form.neighborhood_name = e.neighborhood;
      } else {
        this.form.neighborhood_name = "";
      }
      if (e.street) {
        this.form.street_name = e.street;
      } else {
        this.form.street_name = "";
      }
      if (e.streetNumber) {
        this.form.building_number = e.streetNumber;
      } else {
        this.form.building_number = "";
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.$emit("newLocation", newVal);
      },
    },
  },

});
