import { EnergySources, TechnologyTypes } from "../lib/types/base";

export default [
  // ! ALL TYPES
  {
    value: TechnologyTypes.Unspecified,
    label: TechnologyTypes.Unspecified,
    source: [
      EnergySources.WIND,
      EnergySources.THERMAL,
      EnergySources.SOLID,
      EnergySources.SOLAR,
      EnergySources.MARINE,
      EnergySources.LIQUID,
      EnergySources.HYDRO,
      EnergySources.GASEOUS,
    ],
  },
  // ! WIND
  {
    value: TechnologyTypes.Onshore,
    label: TechnologyTypes.Onshore,
    source: [
      EnergySources.WIND,
    ],
  },
  {
    value: TechnologyTypes.Offshore,
    label: TechnologyTypes.Offshore,
    source: [
      EnergySources.WIND,
    ],
  },
  // ! SOLAR
  {
    value: TechnologyTypes.Photovoltaic,
    label: TechnologyTypes.Photovoltaic,
    source: [
      EnergySources.SOLAR,
    ],
  },
  {
    value: TechnologyTypes["Photovoltaic - roof mounted"],
    label: TechnologyTypes["Photovoltaic - roof mounted"],
    source: [
      EnergySources.SOLAR,
    ],
  },
  {
    value: TechnologyTypes["Photovoltaic - ground mounted"],
    label: TechnologyTypes["Photovoltaic - ground mounted"],
    source: [
      EnergySources.SOLAR,
    ],
  },
  {
    value: TechnologyTypes["Photovoltaic - classic silicon"],
    label: TechnologyTypes["Photovoltaic - classic silicon"],
    source: [
      EnergySources.SOLAR,
    ],
  },
  {
    value: TechnologyTypes["Photovoltaic - Aggregated Group"],
    label: TechnologyTypes["Photovoltaic - Aggregated Group"],
    source: [
      EnergySources.SOLAR,
    ],
  },
  {
    value: TechnologyTypes.Concentration,
    label: TechnologyTypes.Concentration,
    source: [
      EnergySources.SOLAR,
    ],
  },
  // ! HYDRO
  {
    value: TechnologyTypes["Run-of-river head installation"],
    label: TechnologyTypes["Run-of-river head installation"],
    source: [
      EnergySources.HYDRO,
    ],
  },
  {
    value: TechnologyTypes["Storage head installation"],
    label: TechnologyTypes["Storage head installation"],
    source: [
      EnergySources.HYDRO,
    ],
  },
  {
    value: TechnologyTypes["Pure pumped storage head installation"],
    label: TechnologyTypes["Pure pumped storage head installation"],
    source: [
      EnergySources.HYDRO,
    ],
  },
  {
    value: TechnologyTypes["Mixed pumped storage head"],
    label: TechnologyTypes["Mixed pumped storage head"],
    source: [
      EnergySources.HYDRO,
    ],
  },
  // ! MARINE
  {
    value: TechnologyTypes.Tidal,
    label: TechnologyTypes.Tidal,
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes["Tidal - inshore"],
    label: TechnologyTypes["Tidal - inshore"],
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes["Tidal - offshore"],
    label: TechnologyTypes["Tidal - offshore"],
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes.Wave,
    label: TechnologyTypes.Wave,
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes["Wave - onshore"],
    label: TechnologyTypes["Wave - onshore"],
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes["Wave - offshore"],
    label: TechnologyTypes["Wave - offshore"],
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes.Currents,
    label: TechnologyTypes.Currents,
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes.Pressure,
    label: TechnologyTypes.Pressure,
    source: [
      EnergySources.MARINE,
    ],
  },
  {
    value: TechnologyTypes.Thermal,
    label: TechnologyTypes.Thermal,
    source: [
      EnergySources.MARINE,
    ],
  },
  // ! THERMAL
  {
    value: TechnologyTypes["Steam Turbine Open Cycle with CHP"],
    label: TechnologyTypes["Steam Turbine Open Cycle with CHP"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Steam Turbine Open Cycle without CHP"],
    label: TechnologyTypes["Steam Turbine Open Cycle without CHP"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Steam Turbine Closed Cycle with CHP"],
    label: TechnologyTypes["Steam Turbine Closed Cycle with CHP"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Steam Turbine Closed Cycle without CHP"],
    label: TechnologyTypes["Steam Turbine Closed Cycle without CHP"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Internal Combustion Engine"],
    label: TechnologyTypes["Internal Combustion Engine"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Organic Rankine Cycle"],
    label: TechnologyTypes["Organic Rankine Cycle"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  {
    value: TechnologyTypes["Sterling Engine"],
    label: TechnologyTypes["Sterling Engine"],
    source: [
      EnergySources.THERMAL,
    ],
  },
  // ! SOLID
  {
    value: TechnologyTypes["Municipal waste"],
    label: TechnologyTypes["Municipal waste"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Municipal waste - biogenic only"],
    label: TechnologyTypes["Municipal waste - biogenic only"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Industrial and commercial waste - biogenic only"],
    label: TechnologyTypes["Industrial and commercial waste - biogenic only"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes.Wood,
    label: TechnologyTypes.Wood,
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Wood - forestry products"],
    label: TechnologyTypes["Wood - forestry products"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Wood - forestry by-products & waste"],
    label: TechnologyTypes["Wood - forestry by-products & waste"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Animal fats"],
    label: TechnologyTypes["Animal fats"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Biomass from agriculture"],
    label: TechnologyTypes["Biomass from agriculture"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Biomass from agriculture - agricultural products"],
    label: TechnologyTypes["Biomass from agriculture - agricultural products"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Biomass from agriculture - agricultural by-products & waste"],
    label: TechnologyTypes["Biomass from agriculture - agricultural by-products & waste"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - wood"],
    label: TechnologyTypes["Co-fired with fossil fuel - wood"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - wood (forestry products)"],
    label: TechnologyTypes["Co-fired with fossil fuel - wood (forestry products)"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - wood (forestry by-products & waste)"],
    label: TechnologyTypes["Co-fired with fossil fuel - wood (forestry by-products & waste)"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - animal fats"],
    label: TechnologyTypes["Co-fired with fossil fuel - animal fats"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture"],
    label: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture (agricultural products)"],
    label: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture (agricultural products)"],
    source: [
      EnergySources.SOLID,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture (agricultural by-products and waste)"],
    label: TechnologyTypes["Co-fired with fossil fuel - biomass from agriculture (agricultural by-products and waste)"],
    source: [
      EnergySources.SOLID,
    ],
  },
  // ! LIQUID
  {
    value: TechnologyTypes["Municipal biodegradable waste"],
    label: TechnologyTypes["Municipal biodegradable waste"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Black liquor"],
    label: TechnologyTypes["Black liquor"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Pure plant oil"],
    label: TechnologyTypes["Pure plant oil"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Waste plant oil"],
    label: TechnologyTypes["Waste plant oil"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Refined vegetable oil"],
    label: TechnologyTypes["Refined vegetable oil"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Refined vegetable oil - biodiesel (mono-alkyl ester)"],
    label: TechnologyTypes["Refined vegetable oil - biodiesel (mono-alkyl ester)"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  {
    value: TechnologyTypes["Refined vegetable oil - biogasoline (C6-C12 hydrocarbon)"],
    label: TechnologyTypes["Refined vegetable oil - biogasoline (C6-C12 hydrocarbon)"],
    source: [
      EnergySources.LIQUID,
    ],
  },
  // ! GASOUS
  {
    value: TechnologyTypes["Landfill gas"],
    label: TechnologyTypes["Landfill gas"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Sewage gas"],
    label: TechnologyTypes["Sewage gas"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Agricultural gas"],
    label: TechnologyTypes["Agricultural gas"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Agricultural gas - animal manure"],
    label: TechnologyTypes["Agricultural gas - animal manure"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Agricultural gas - energy crops"],
    label: TechnologyTypes["Agricultural gas - energy crops"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Gas from organic waste digestion"],
    label: TechnologyTypes["Gas from organic waste digestion"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Process gas - biogenic"],
    label: TechnologyTypes["Process gas - biogenic"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes.Geothermal,
    label: TechnologyTypes.Geothermal,
    source: [
      EnergySources.GASEOUS,
    ],
  },
  {
    value: TechnologyTypes["Co-fired with fossil fuel - solar thermal"],
    label: TechnologyTypes["Co-fired with fossil fuel - solar thermal"],
    source: [
      EnergySources.GASEOUS,
    ],
  },
];
