import Vue from "vue";
import i18n from "../i18n";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
  });
});

// Add a rule.
extend("latitude", {
  validate: value => {
    return /^(\+|-)?(?:90(?:(?:\.0{1,15})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(value) === true;
  },
  message: field => {
    return i18n.t("validations.latitude", { field });
  },
  // message: "{_field_} must be a valid latitude value",
});

extend("longitude", {
  validate: value => {
    return /^(\+|-)?(?:180(?:(?:\.0{1,15})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(value) === true;
  },
  message: field => {
    return i18n.t("validations.longitude", { field });
  },
  // message: "{_field_} must be a valid longitude value",
});

extend("passwordC", {
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#$%^&*])(?=.{9,})");
    return strongRegex.test(value);
  },
  message: field => {
    return i18n.t("validations.password", { field });
  },
});

// Register it globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
