
















import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import { IConsumptionAllHourlyData } from "@/lib/types/energy-data/consumption_data";
import { IProductionAllHourlyData } from "@/lib/types/energy-data/production_data";
import { rootInstance } from "@/helpers/composition";
import { arrSum, randomId, seperateThousand } from "@/utils";
import { chartColors } from "@/helpers/chart";

interface IChartData {
  x: Date | string;
  y: number;
}
export default defineComponent({
  name: "StackedAreaChart",
  props: {
    chartType: {
      type: String,
      default: "consumption",
      required: false,
    },
    sourceType: {
      type: String,
      default: "matched",
      required: false,
    },
    consumptionData: {
      type: Array,
      default: () => [],
      required: false,
    },
    productionData: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  setup(props, vm) {
    const { root } = rootInstance();
    const id = ref(randomId());

    const state = reactive({
      isDarkTheme: localStorage.getItem("theme") === "dark",
      type: props.chartType.length
        ? props.chartType
        : props.consumptionData.length && !props.productionData.length
          ? "consumption"
          : !props.consumptionData.length && props.productionData.length
            ? "production"
            : "consumption",
      /*  */
      consumption: [...props.consumptionData] as IConsumptionAllHourlyData[],
      production: [...props.productionData] as IProductionAllHourlyData[],
      empty: false,
      //   theme: computed < string > (() => root.$store.state.theme),
    });

    const chartOptions = ref({
      chart: {
        type: "area",
        stacked: true,
        height: "100%",
        animations: {
          enabled: false,
          speed: 800,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 800,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
        },
        pattern: {
          strokeWidth: 1,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        itemMargin: {
          horizontal: 4,
          vertical: 16,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        title: {
          text: "kWh",
          style: {
            color: state.isDarkTheme ? "white" : undefined,
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 400,
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
          formatter: (val: number) => seperateThousand(val),
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: state.isDarkTheme ? "white" : undefined,
          fontSize: "14px",
          fontFamily: "inherit",
        },
      },
      tooltip: {
        x: {
          show: true,
          format: "dd.MM.yyyy HH:mm",
        },
        style: {
          fontFamily: "inherit",
        },
      },
    });
    const datasets = ref<any>([]);
    watch(
      () => [state.consumption, state.production, state.type],
      async () => {
        if (state.type === "consumption") {
          const hour = [] as Array<string | Date>;
          const consumed = [] as number[];
          const solar = [] as IChartData[];
          const wind = [] as IChartData[];
          const hydro = [] as IChartData[];
          const marine = [] as IChartData[];
          const thermal = [] as IChartData[];
          const solid = [] as IChartData[];
          const liquid = [] as IChartData[];
          const gaseous = [] as IChartData[];
          const matched = [] as IChartData[];
          const unmatched = [] as any;

          state.consumption.forEach((data: IConsumptionAllHourlyData) => {
            hour.push(data.date);
            consumed.push(data.total_amount_consumed / 1000);
            solar.push({
              x: data.date,
              y: data.total_amount_matched_solar / 1000,
            });
            wind.push({
              x: data.date,
              y: data.total_amount_matched_wind / 1000,
            });
            hydro.push({
              x: data.date,
              y: data.total_amount_matched_hydro / 1000,
            });
            marine.push({
              x: data.date,
              y: data.total_amount_matched_marine / 1000,
            });
            thermal.push({
              x: data.date,
              y: data.total_amount_matched_thermal / 1000,
            });
            solid.push({
              x: data.date,
              y: data.total_amount_matched_solid / 1000,
            });
            liquid.push({
              x: data.date,
              y: data.total_amount_matched_liquid / 1000,
            });
            gaseous.push({
              x: data.date,
              y: data.total_amount_matched_gaseous / 1000,
            });
            /*  */
            matched.push({
              x: data.date,
              y:
                data.total_amount_matched_solar / 1000 +
                data.total_amount_matched_wind / 1000 +
                data.total_amount_matched_hydro / 1000 +
                data.total_amount_matched_marine / 1000 +
                data.total_amount_matched_thermal / 1000 +
                data.total_amount_matched_solid / 1000 +
                data.total_amount_matched_liquid / 1000 +
                data.total_amount_matched_gaseous / 1000,
            });
            unmatched.push({
              x: data.date,
              y:
                data.total_amount_consumed / 1000 -
                (data.total_amount_matched_solar / 1000 +
                  data.total_amount_matched_wind / 1000 +
                  data.total_amount_matched_hydro / 1000 +
                  data.total_amount_matched_marine / 1000 +
                  data.total_amount_matched_thermal / 1000 +
                  data.total_amount_matched_solid / 1000 +
                  data.total_amount_matched_liquid / 1000 +
                  data.total_amount_matched_gaseous / 1000),
            });
          });

          if (
            arrSum(matched.map((data: any) => data.y)) +
            arrSum(unmatched.map((data: any) => data.y)) ===
            0
          ) {
            state.empty = true;
          } else state.empty = false;

          if (arrSum(solar.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.solar") as string,
              data: solar,
              color: chartColors.Solar,
            });
          }
          if (arrSum(wind.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.wind") as string,
              data: wind,
              color: chartColors.Wind,
            });
          }
          if (arrSum(hydro.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.hydro") as string,
              data: hydro,
              color: chartColors["Hydro-electric Head"],
            });
          }
          if (arrSum(marine.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.marine") as string,
              data: marine,
              color: chartColors.Marine,
            });
          }
          if (arrSum(thermal.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.thermal") as string,
              data: thermal,
              color: chartColors.Thermal,
            });
          }
          if (arrSum(solid.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.solid") as string,
              data: solid,
              color: chartColors.Solid,
            });
          }
          if (arrSum(liquid.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.liquid") as string,
              data: liquid,
              color: chartColors.Liquid,
            });
          }
          if (arrSum(gaseous.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.gaseous") as string,
              data: gaseous,
              color: chartColors.Gaseous,
            });
          }
          if (arrSum(unmatched.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.unmatched") as string,
              data: unmatched,
              color: chartColors.Unmatched,
            });
          }
          /**
           * ! PRODUCTION
           **/
        } else if (state.type === "production") {
          const hour = [] as Array<string | Date>;
          const matched = [] as IChartData[];
          const unmatched = [] as IChartData[];

          state.production.forEach((data: IProductionAllHourlyData) => {
            hour.push(data.date);
            matched.push({
              x: (data.date),
              y: data.total_amount_matched / 1000,
            });
            unmatched.push({
              x: (data.date),
              y: data.total_amount_produced / 1000 - data.total_amount_matched / 1000,
            });
          });

          if (
            arrSum(matched.map((data: any) => data.y)) +
            arrSum(unmatched.map((data: any) => data.y)) ===
            0
          ) {
            state.empty = true;
          } else state.empty = false;

          if (arrSum(matched.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.matched") as string,
              data: matched,
              color: chartColors[props.sourceType] || chartColors.Matched,
            });
          }
          if (arrSum(unmatched.map((data: any) => data.y))) {
            datasets.value.push({
              name: root.$i18n.t("common.unmatched") as string,
              data: unmatched,
              color: chartColors.Unmatched,
            });
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return { ...toRefs(state), chartOptions, datasets };
  },
});
