<template>
  <nav class="navbar">
    <div class="container d-flex align-items-center justify-content-between">
      <div
        class="navbar__brand d-flex align-items-center justify-content-center"
      >
        <router-link
          :to="{ name: 'Overview' }"
          class="d-flex"
        >
          <span class="sr-only">GreenLink</span>
          <AppLogo />
        </router-link>
      </div>

      <ul class="navbar__list flex-1">
        <li
          class="navbar__list__item"
          data-cy="link-overview"
        >
          <router-link :to="{ name: 'Overview' }">
            {{ $t("common.overview") }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          data-cy="link-producer"
          v-if="['Retailer', 'GridOperator'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'ProducersList' }">
            {{ $tc("common.producer", 2) }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          data-cy="link-consumer-organization"
          v-if="['Retailer'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'ConsumersList' }">
            {{ $tc("common.consumer", 2) }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          v-if="['Consumer'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'ConsumptionSitesList' }">
            {{ $tc("common.consumption_site", 2) }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          v-if="['Consumer'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'Certificates' }">
            {{ $tc("common.certificate", 2) }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          data-cy="link-match"
          v-if="['Retailer'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'Match' }">
            {{ $tc("common.match") }}
          </router-link>
        </li>
        <li
          class="navbar__list__item"
          data-cy="link-logs"
          v-if="['Retailer', 'Consumer'].includes(roles.organizationType)"
        >
          <router-link :to="{ name: 'Logs' }">
            {{ $tc("common.logs") }}
          </router-link>
        </li>
      </ul>

      <div class="navbar__options">
        <GlobalDatePicker data-cy="datepicker" />
        <UserDropdown data-cy="user-dropdown" />
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import UserDropdown from "./UserDropdown.vue";
import GlobalDatePicker from "./GlobalDatePicker.vue";
import AppLogo from "./AppLogo.vue";

export default defineComponent({
  components: { UserDropdown, GlobalDatePicker, AppLogo },
  name: "Navbar",
  computed: {
    roles() {
      return this.$store.getters["user/roles"];
    },
  },
});
</script>

<style lang="scss">
.el-dropdown-menu {
  z-index: 2001 !important;
}
</style>
