<template>
  <FormItem
    :id="id"
    :label="$t('labels.issuer')"
    :options="options"
    :placeholder="$t('placeholders.select_sth', { msg: $t('labels.issuer') })"
    :vv-name="$t('labels.issuer')"
    :value="value"
    @input="handleChange($event)"
  >
    <template #options="slot">
      <el-option
        label="-"
        :value="null"
      >
      </el-option>
      <el-option
        v-for="item in slot.options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </template>
  </FormItem>
</template>

<script>
import { getIssuersList } from "@/api/organizations/issuer";
import { randomId } from "@/utils";

export default {
  name: "GridOperatorSelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      options: [],
      id: randomId(),
    };
  },
  mounted () {
    getIssuersList()
      .then(res => {
        this.options = res.results;
      })
      .then(() => {
        this.$emit("issuerCount", this.options.length);
      });
  },
  methods: {
    handleChange (e) {
      this.$emit("input", e);
    },
  },
};
</script>
