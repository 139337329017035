<template>
  <div class="d-flex flex-column align-items-center justify-content-center p-4 not-found">
    <!-- <img
      src="../assets/img/404.png"
      alt="Not Found"
      height="320"
    > -->

    <h1 class="text-center mt-0 mb-4">
      <span>404</span> |
      <span>Not Found</span>
    </h1>

    <p class="m-0 mb-2">
      The page you are looking for does not exist or you do not have a permission to visit
    </p>
    <p class="m-0 mb-4 text-md">
      ¯\_(ツ)_/¯
    </p>

    <el-button
      type="primary"
      @click="$router.push('/')"
    >
      Return Home
    </el-button>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
