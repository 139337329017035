/* eslint-disable @typescript-eslint/no-var-requires */
import { getCookie } from "../utils";

const GreenLinkApi = require("green_link_api");
const greenlinkClient = GreenLinkApi.ApiClient.instance;
const basePath = process.env.VUE_APP_API_PATH;

// Variables
const csrftoken = getCookie("csrftoken");
// Client Modification
greenlinkClient.basePath = basePath;
greenlinkClient.enableCookies = true;
greenlinkClient.timeout = "2000000";
greenlinkClient.defaultHeaders = {
  ...greenlinkClient.defaultHeaders,
  "X-CSRFToken": csrftoken,
  // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  // "Access-Control-Allow-Origin": "*",
};

// Users API
const usersApi = new GreenLinkApi.UsersApi();
usersApi.apiClient = greenlinkClient;

// Organizations API
const organizationsApi = new GreenLinkApi.OrganizationsApi();
organizationsApi.apiClient = greenlinkClient;

// Locations API
const locationsApi = new GreenLinkApi.LocationsApi();
locationsApi.apiClient = greenlinkClient;

// Energy Assets API
const energyAssetsApi = new GreenLinkApi.EnergyAssetsApi();
energyAssetsApi.apiClient = greenlinkClient;

// Energy Data API
const energyDataApi = new GreenLinkApi.EnergyDataApi();
energyDataApi.apiClient = greenlinkClient;

// Energy Data API
const epiasApi = new GreenLinkApi.LicensedProductionSitesOnEpiasApi();
epiasApi.apiClient = greenlinkClient;

export {
  greenlinkClient,
  usersApi,
  organizationsApi,
  locationsApi,
  energyAssetsApi,
  energyDataApi,
  epiasApi
};
