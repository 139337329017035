import Vue from "vue";

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Drawer,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Loading,
  Message,
  MessageBox,
  Notification,
  Option,
  Pagination,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Slider,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  Descriptions,
  DescriptionsItem
} from "element-ui";

Vue.use(Alert);
Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(Drawer);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Slider);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);

Vue.use(Loading.directive);

Vue.prototype.$ELEMENT = { zIndex: 500 };
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
