import { OrganizationTypes } from "@/lib/types/base";

import store from "../store";

export async function setOrganizationDetailsByType() {
  const organizationType = store.state.user.details.organization_type;

  if (organizationType === OrganizationTypes.RETAILER) {
    await store.dispatch("retailer/setRetailerDetails");
    /*  */
  } else if (organizationType === OrganizationTypes.CONSUMER) {
    await store.dispatch("consumer/setConsumerDetails");
    /*  */
  } else {
    alert("The organization type could not be recognized!");
  }
}
