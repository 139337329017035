var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}]},[(_vm.error)?[_c('el-alert',{attrs:{"type":"error"}},[_vm._v(" An error occured. Please refresh the content. ")])]:[_c('div',{staticClass:"mb-4 row"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v(_vm._s(_vm.$t("labels.filter_by_category")))]),_c('el-select',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},[_c('el-option',{key:'empty',attrs:{"label":'-',"value":''}}),_vm._l((Object.entries(_vm.categories)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('el-option',{key:key,attrs:{"label":value,"value":key}})})],2)],1),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v(_vm._s(_vm.$t("labels.filter_by_type")))]),_c('el-select',{model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}},[_c('el-option',{key:'empty',attrs:{"label":'-',"value":''}}),_vm._l((Object.entries(_vm.methods)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('el-option',{key:key,attrs:{"label":value,"value":key}})})],2)],1),_c('div',{staticClass:"col-4"},[_c('label',{staticStyle:{"opacity":"0"}},[_vm._v("Actions")]),_c('div',[_c('el-button',{on:{"click":_vm.clearFilters}},[_vm._v(" Clear Filters ")])],1)])]),_c('el-table',{attrs:{"fit":true,"data":_vm.data.results,"cell-class-name":_vm.cellClassName},on:{"cell-click":_vm.onCellClick}},[_c('el-table-column',{attrs:{"label":_vm.$t('labels.users'),"prop":"user","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(row.user)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.organization'),"prop":"user","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(row.organization)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.event'),"prop":"event","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(row.event)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.status'),"width":"142","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-align-center"},[(row.status)?_c('el-tag',{staticClass:"w-80",attrs:{"type":['failure', 'error'].includes(row.status.toLowerCase())
                  ? 'danger'
                  : ['started'].includes(row.status.toLowerCase())
                    ? 'warning'
                    : 'success',"effect":"dark"}},[_vm._v(" "+_vm._s(row.status)+" ")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.date'),"prop":"date_time","width":"266","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.formatDate(row.date_time, "D MMM YYYY, HH:mm Z"))+" ")]),_c('span',{staticClass:"d-block text-xs font-medium"},[_vm._v(" "+_vm._s(_vm.timeAgo(row.date_time))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.category'),"prop":"model_name","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.model_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.message'),"prop":"details.message","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.details.message)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.related_task'),"prop":"related_log_type_name","show-overflow-tooltip":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(row.related_log_type_name)?_c('svg',{staticClass:"UserActivity__related-task-icon",attrs:{"width":"20","height":"20","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"fill":"currentColor","d":"M18 6C11.373 6 6 11.375 6 18c0 6.6289 5.373 12 12 12s12-5.3711 12-12c0-6.625-5.373-12-12-12zm0 21.6774c-5.3483 0-9.67742-4.3273-9.67742-9.6774 0-5.3465 4.32932-9.67742 9.67742-9.67742 5.3463 0 9.6774 4.32922 9.6774 9.67742 0 5.3482-4.3273 9.6774-9.6774 9.6774zm0-16.3548c1.1224 0 2.0323.9099 2.0323 2.0322 0 1.1224-.9099 2.0323-2.0323 2.0323-1.1224 0-2.0323-.9099-2.0323-2.0323 0-1.1223.9099-2.0322 2.0323-2.0322zm2.7097 12.2903c0 .3207-.26.5806-.5807.5806h-4.258c-.3207 0-.5807-.2599-.5807-.5806v-1.1613c0-.3206.26-.5806.5807-.5806h.5806v-3.0968h-.5806c-.3207 0-.5807-.26-.5807-.5807v-1.1612c0-.3207.26-.5807.5807-.5807h3.0967c.3207 0 .5807.26.5807.5807v4.8387h.5806c.3207 0 .5807.26.5807.5806v1.1613z"}})]):_vm._e(),_vm._v(" "+_vm._s(row.related_log_type_name)+" ")]}}])})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-4"},[_c('div',[_c('el-pagination',{staticClass:"ml-4",attrs:{"current-page":_vm.page,"background":"","layout":"pager","page-size":_vm.perPage,"total":_vm.data.count},on:{"current-change":_vm.currentChange}})],1)])],(_vm.relatedLogId)?_c('el-drawer',{attrs:{"visible":_vm.isDrawerOpen,"with-header":false,"direction":"rtl","size":"50%","destroy-on-close":""},on:{"update:visible":function($event){_vm.isDrawerOpen=$event}}},[_c('div',{staticClass:"p-4"},[_c('RelatedLog',{attrs:{"id":_vm.relatedLogId}})],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }