















import { defineComponent, reactive, toRefs } from "@vue/composition-api";
import { getProductionSitesList } from "@/api/energy-assets/production_site";
import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "ProductionSitesCard",
  components: {
    AnimatedNumber,
  },
  setup () {
    const state = reactive({
      sitesCount: 0,
    });

    getProductionSitesList({
      limit: 24,
      offset: 0,
    })
      .then(res => (state.sitesCount = res.count))
      // .catch(err => alert(err));
      .catch(() => alert("ProductionSitesCard.vue getProductionSitesList"));

    return { ...toRefs(state) };
  },
});
