/**
 * Official Documentation: https://day.js.org/docs/en/installation/typescript
 * Localized Format:       https://day.js.org/docs/en/display/format
 */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import objectSupport from "dayjs/plugin/objectSupport";
import { capitalize } from "lodash";

require("dayjs/locale/en");
require("dayjs/locale/tr");
require("dayjs/locale/de");

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

(() => {
  if (new RegExp(/^en/).test(navigator.language.toLowerCase())) dayjs.locale("en");
  else if (new RegExp(/^tr/).test(navigator.language.toLowerCase())) dayjs.locale("tr");
  else if (new RegExp(/^de/).test(navigator.language.toLowerCase())) dayjs.locale("de");
  else dayjs.locale("en");
})();

const localTimezone = dayjs.tz.guess();
// dayjs.tz.setDefault(localTimezone);
console.log("time offset", new Date().getTimezoneOffset() / 60 * -1);

/**
 * @param date - ex. new Date() or '1993-20-04'
 * @param format - [format='ddd, D MMM YYYY, HH:mm Z'] - ex. 'dddd, MMM D'
 * @returns - ex. Tuesday, Apr 20
 */
const formatDate = (date: Date | string, format = "ddd, D MMM YYYY, HH:mm Z"): string => dayjs(date).format(format);

const timeAgo = (startDate: Date | string, currentDate: Date | string = new Date()): string => {
  const a = dayjs(startDate);
  const b = dayjs(currentDate);

  return capitalize(a.from(b));
};

export { localTimezone, formatDate, timeAgo };
