<template>
  <div
    :id="`map-${id}-${mapKey}`"
    :key="mapKey"
    ref="mapRef"
  ></div>
</template>

<script>
import { randomId } from "@/utils";
import { defineComponent, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
import { homeIcon, consumerIcon, solarIcon, windIcon, hydroIcon, marineIcon, thermalIcon, solidIcon, liquidIcon, gaseousIcon } from "@/helpers/leaflet";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "Map",
  props: {
    center: {
      type: Object,
      default: () => ({
        lat: 0,
        lng: 0,
      }),
      required: true,
    },
    sites: {
      type: Array,
      default: () => ([]),
      required: false,
    },
  },
  setup(props) {
    const { root } = rootInstance();
    const L = window.L;
    const accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
    const styleLayer = process.env.VUE_APP_MAPBOX_STYLELAYER;

    const state = reactive({
      id: randomId(),
      markers: [],
      map: null,
      mapRef: null,
      mapKey: 0,
      attr: '©<a href="https://apps.mapbox.com/feedback/">Mapbox</a> ©<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> ©<a href="https://about.google/">Google</a>',
    });

    const { latitude: lat, longitude: lng } = props.center;

    const initMap = () => {
      L.mapbox.accessToken = accessToken;

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: state.attr,
      });

      const mapboxTile = L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`, {
        attribution: state.attr,
      });

      /* Google Satellite Tile Layer */
      const googleTile = L.tileLayer(
        "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
        {
          attribution: state.attr,
        }
      );

      state.map = L.map(state.mapRef, {
        center: [lat, lng],
        zoom: 100,
        layers: [mapboxTile],
        fullscreenControl: {
          pseudoFullscreen: false, // if true, fullscreen to page width and height
        },
      });

      const tiles = {
        Simple: mapboxTile,
        Satellite: googleTile,
      };

      L.mapbox.styleLayer(styleLayer).addTo(state.map);
      L.control.layers(tiles, null, { collapsed: false }).addTo(state.map);

      /* Set Home Icon */
      const homeMarker = L.marker([lat, lng], { icon: homeIcon }).addTo(state.map);

      /*
      ** SET PRODUCERS
      */
      const markers = [];
      let marker;

      if (props.sites) {
        props.sites.forEach(site => {
          const { latitude: lat, longitude: lng } = site.location;

          const iconType = () => {
            if (site.energy_source) {
              return (
                site.energy_source.toLowerCase() === "solar" ? solarIcon
                  : site.energy_source.toLowerCase() === "wind" ? windIcon
                    : site.energy_source.toLowerCase() === "hydro-electric head" ? hydroIcon
                      : site.energy_source.toLowerCase() === "marine" ? marineIcon
                        : site.energy_source.toLowerCase() === "thermal" ? thermalIcon
                          : site.energy_source.toLowerCase() === "solid" ? solidIcon
                            : site.energy_source.toLowerCase() === "liquid" ? liquidIcon
                              : site.energy_source.toLowerCase() === "gaseous" ? gaseousIcon
                                : null
              );
            } else {
              return consumerIcon;
            }
          };

          marker = L.marker([lat, lng], { icon: iconType() })
            .bindPopup(`${site.name}`, { closeOnClick: false, autoClose: false })
            .addTo(state.map);

          markers.push(marker);
        });

        state.map.fitBounds(L.featureGroup([homeMarker, ...markers]).getBounds(), { padding: [64, 64], animate: false });
      }

      handlePopup();
    };

    const handlePopup = () => {
      for (let i = 0; i < state.markers.length; i++) {
        state.markers[i].forEach(e => e.openPopup());
      }
    };

    const renderMap = () => {
      state.mapKey++;
      root.$nextTick(() => initMap());
    };

    onMounted(() => {
      initMap();
    });

    watch(
      () => props.sites,
      () => { renderMap() },
      { lazy: true }
    );

    return { ...toRefs(state) };
  },
});
</script>

<style lang="scss">
@import "../assets/styles/abstracts/_variables.scss";

[id*="map-"] {
  height: 200px;

  .leaflet-container .leaflet-control-attribution {
    font-size: 0.625rem;
  }

  .leaflet-popup-content-wrapper,
  .map-legends,
  .map-tooltip {
    background-color: $primary;
    color: white;
  }

  .leaflet-fade-anim .leaflet-popup {
    transition: opacity 0s linear;
  }

  .leaflet-popup-content {
    padding: 0.5rem;
    text-align: center;
  }

  .leaflet-popup-tip-container {
    display: none;
    margin: 0;
    position: relative;
    transform: translateX(-50%);
    transform-origin: center;
}

  .leaflet-popup-tip {
    border-top-color: #5ac167;
  }

  .leaflet-popup-close-button {
    width: 0;
    height: 0;
  }

  .leaflet-touch .leaflet-bar,
  .leaflet-touch .leaflet-control-layers {
    border: 1px solid $border;
    border-radius: $border-radius;
  }
}
</style>
