import { v4 as uuidv4 } from "uuid";
import svgInjector from "svg-injector";
import lodash from "lodash";

/**
 ** Functions Start
 */
export function isDevMode() {
  return process.env.VUE_APP_DEV_MODE === "true";
}

export function randomId(): string {
  return uuidv4();
}

export function injectSvg(selector: string): void {
  svgInjector(document.querySelectorAll(`${selector}`));
}

export function cloneDeep(object: Record<any, any>): Record<any, any> {
  return lodash.cloneDeep(object);
}

export function awaiter(ms: number): Promise<unknown> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function arrSum(arr: number[]): number {
  return arr.reduce((a: number, b: number) => a + b, 0);
}

export function sortObj(x: Record<string, number>): Record<string, number> {
  return Object.entries(x)
    .sort(([, a], [, b]) => (b as any) - (a as any))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
}

export function sliceObj(
  obj: Record<any, any>,
  start = 0,
  index: number
): Record<any, any> {
  return Object.keys(obj)
    .slice(start, start + index)
    .reduce((result: Record<any, any>, key: string) => {
      result[key] = obj[key];

      return result;
    }, {});
}

export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ãàáäâáº½èéëêıìíïîõòóöôùúüûñçşğ·/_,:;";
  const to = "aaaaaeeeeeiiiiiooooouuuuncsg------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

/* eslint-disable */
export function tpTrUpperCase(string: string) {
  const letters: { [key: string]: any } = {
    i: "İ",
    ş: "Ş",
    ğ: "Ğ",
    ü: "Ü",
    ö: "Ö",
    ç: "Ç",
    ı: "I",
  };
  string = string.replace(/(([iışğüçö]))+/g, function (letter) {
    return letters[letter];
  });
  return string.toUpperCase();
}

export function toTrLowerCase(string: string) {
  const letters: { [key: string]: any } = {
    İ: "i",
    I: "ı",
    Ş: "ş",
    Ğ: "ğ",
    Ü: "ü",
    Ö: "ö",
    Ç: "ç",
  };
  string = string.replace(/(([İIŞĞÜÇÖ]))+/g, function (letter) {
    return letters[letter];
  });
  return string.toLowerCase();
}
/* eslint-enable */

/**
 * This function allows you to convert long numbers to comma seperated strings
 * ex. 20000.22 -> 20,000.22
 */
export function seperateThousand(val: number, digit = 2): string {
  return new Intl.NumberFormat("en", {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: digit,
  }).format(val);
}

export function capitalize(text: string): string {
  const wordsArray: string[] = text.split(" ");
  const capsArray: string[] = [];

  wordsArray.forEach(word => {
    capsArray.push(word[0].toUpperCase() + word.slice(1));
  });

  return capsArray.join(" ");
}

export function dynamicSort(property: string): any {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any): number {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function snakeToTitle(str: string): string {
  return capitalize(str.split("_").join(" "));
}

export function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
}

export function camelToSplit(str: string): string {
  return str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
}

/**
 * * COOKIES
 **/
export function getCookie(cname: string): string {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname: string, cvalue: string, exdays = 365): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function clearCookies(): void {
  document.cookie.split(";").forEach(c => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

/* eslint-disable */
/**
 * https://www.geodatasource.com/developers/javascript
 * @param lat1 - Latitude of point 1 (in decimal degrees)
 * @param lon1 - Longitude of point 1 (in decimal degrees)
 * @param lat2 - Latitude of point 2 (in decimal degrees)
 * @param lon2 - Longitude of point 2 (in decimal degrees)
 * @param unit - 'M' is statute miles, 'K' is kilometers (default), 'N' is nautical miles
 * @return - Calculates the distance between two points
 */
export function distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string = "k"
): number {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit.toLowerCase() === "k") {
      dist = dist * 1.609344;
    }
    if (unit.toLowerCase() === "n") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}
/* eslint-enable */
