// eslint-disable-next-line
// @ts-ignore
import { organizationsApi } from "../../plugins/greenlink";
import { IConsumerDetails } from "@/lib/types/organizations/consumer";
import { IFilterQuery, IListResponse } from "@/lib/types/base";

export function getConsumersList (opts?: Partial<IConsumerDetails> & IFilterQuery) {
  return new Promise<IListResponse<IConsumerDetails>>((resolve, reject) => {
    organizationsApi.organizationsConsumersList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getConsumerData (id: string) {
  return new Promise<IConsumerDetails>((resolve, reject) => {
    organizationsApi.organizationsConsumersRetrieve(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function createConsumerData (payload: Partial<IConsumerDetails>) {
  return new Promise<IConsumerDetails>((resolve, reject) => {
    organizationsApi.organizationsConsumersCreate(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateConsumerData (id: string, payload: Partial<IConsumerDetails>) {
  return new Promise<IConsumerDetails>((resolve, reject) => {
    organizationsApi.organizationsConsumersUpdate(id, payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteConsumerData (id: string) {
  return new Promise<IConsumerDetails>((resolve, reject) => {
    organizationsApi.organizationsConsumersDestroy(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
