<template>
  <FormItem
    :id="id"
    :label="$tc('labels.consumer', 1)"
    :options="options"
    :placeholder="$t('placeholders.select_sth', { msg: $tc('labels.consumer', 1) })"
    :vv-name="$tc('labels.consumer', 1)"
    :value="value"
    :required="required"
    :disabled="disabled"
    name="consumer-select"
    @input="handleChange($event)"
    v-bind="attrs"
  >
    <template #options="slot">
      <el-option
        label="-"
        :value="null"
      >
      </el-option>
      <el-option
        v-for="item in slot.options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </template>
  </FormItem>
</template>

<script>
import { getConsumersList } from "@/api/organizations/consumer";
import { randomId } from "@/utils";

export default {
  name: "ConsumerSelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: [],
      id: randomId(),
      attrs: {},
    };
  },
  watch: {
    "$props.required": {
      handler (newVal) {
        if (newVal !== null && newVal !== undefined) {
          this.attrs = {
            "vv-rules": "required",
          };
        } else {
          this.attrs = null;
        }
      },
      immediate: true,
    },
  },
  mounted () {
    getConsumersList()
      .then(res => {
        this.options = res.results;
      });
  },
  methods: {
    handleChange (e) {
      this.$emit("input", e);
    },
  },
};
</script>
