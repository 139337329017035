















import { defineComponent, reactive, toRefs } from "@vue/composition-api";
import { getConsumptionSitesList } from "@/api/energy-assets/consumption_site";
import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "ConsumptionSitesCard",
  components: {
    AnimatedNumber,
  },
  setup() {
    const state = reactive({
      sitesCount: 0,
    });

    getConsumptionSitesList({
      limit: 24,
      offset: 0,
    })
      .then(res => (state.sitesCount = res.count))
      // .catch(err => alert(err));
      .catch(() => console.error("ConsumptionSitesCard.vue getConsumptionSitesList"));
    return { ...toRefs(state) };
  },
});
