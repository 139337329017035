<template>
  <main
    class="main"
    v-loading="loading"
  >
    <div class="container">
      <div class="main__header">
        <div class="row">
          <div class="fake-col flex-1">
            <slot name="title">
              <h1>
                {{ title }}
              </h1>
            </slot>
          </div>

          <div class="fake-col">
            <slot name="actions" />
          </div>
        </div>
      </div>

      <div class="main__section">
        <slot />
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "PageLayout",
  props: {
    title: {
      type: String,
      default: "...",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
