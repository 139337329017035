import Vue from "vue";
import SvgInjector from "./SvgInjector.vue";
import PageLayout from "./PageLayout.vue";
import Card from "./Card.vue";
import Clipboard from "./Clipboard.vue";
import IconText from "./IconText.vue";
import HoverPopper from "./HoverPopper.vue";

Vue.component("SvgInjector", SvgInjector);
Vue.component("PageLayout", PageLayout);
Vue.component("Card", Card);
Vue.component("Clipboard", Clipboard);
Vue.component("IconText", IconText);
Vue.component("HoverPopper", HoverPopper);
