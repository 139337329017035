import Vue from "vue";
import tippy from "tippy.js";

Vue.directive("focus", {
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
});

Vue.directive("tippy", {
  inserted: function (el, binding) {
    tippy(el, {
      placement: "bottom",
      arrow: false,
      hideOnClick: false,
      ...binding.value,
    });
  },
});

Vue.directive("uppercase", {
  inserted: function (el) {
    el.addEventListener(
      "input",
      (e: any) => {
        const start = e.target.selectionStart;
        const end = e.target.selectionEnd;
        e.target.value = e.target.value.toLocaleUpperCase();
        e.target.setSelectionRange(start, end);
      },
      false
    );
  },
});
