




























import axios from "axios";
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs
} from "@vue/composition-api";
import { rootInstance } from "@/helpers/composition";
import { setOrganizationDetailsByType } from "@/helpers/userOrganization";
import { greenlinkClient } from "@/plugins/greenlink";
import { getFirebaseUser, signOut } from "@/plugins/firebase";
import EmailVerificationRequiredMessage from "@/components/EmailVerificationRequiredMessage.vue";

type AppData = {
  isLoading: boolean;
  emailVerified: boolean | null;
  publicPath?: string;
};

export default defineComponent({
  name: "AppLayout",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    EmailVerificationRequiredMessage,
  },
  mounted() {
    this.$watch(
      () => this.$store.getters.isEnerjisa,
      isEnerjisa => {
        let iconEl: HTMLLinkElement | null =
          document.head.querySelector("link[rel~='icon']");

        if (!iconEl) {
          iconEl = document.createElement("link");
          iconEl.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(iconEl);
        }

        if (isEnerjisa) {
          iconEl.href = `${this.publicPath}static/icons/enerjisa.ico?v=1`;
        } else {
          iconEl.href = `${this.publicPath}static/icons/favicon.ico?v=2`;
        }
      },
      { immediate: true }
    );
  },
  setup() {
    const state = reactive<AppData>({
      isLoading: true,
      emailVerified: null,
      publicPath: process.env.BASE_URL,
    });

    const { root } = rootInstance();

    onMounted(async () => {
      try {
        state.isLoading = true;
        const user = await getFirebaseUser();

        if (user) {
          const accessToken = await user.getIdToken();

          state.emailVerified = user.emailVerified;
          greenlinkClient.defaultHeaders.Authorization = `Bearer ${accessToken}`;
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          axios.defaults.withCredentials = true;

          await root.$store
            .dispatch("user/setUserDetails")
            .then(async () => {
              await setOrganizationDetailsByType();
            })
            .catch(() => {
              signOut();
              root.$router.push({ name: "LoginPage" });
              // Error exception => if firebase passes but greenlink fails
              root.$message.error("Invalid e-mail or password");
            });
        }
      } catch (e) {
        console.error(`Getting token issue": ${e}`);
      } finally {
        state.isLoading = false;
      }
    });

    return {
      ...toRefs(state),
    };
  },
});
