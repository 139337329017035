














































































































































import { defineComponent } from "@vue/composition-api";

import { OrganizationTypes } from "@/lib/types/base";
import { dynamicSort, slugify } from "@/utils";
import { deleteCertificate, getCertificates, ICertificate } from "@/api/certificates";
import SvgInjector from "../SvgInjector.vue";
import { Notification } from "element-ui";
import { formatDate } from "@/plugins/dayjs";

export default defineComponent({
  name: "ConsumerCertificates",
  components: {
    CertificateUploadForm: () => import("@/components/forms/CertificateUploadForm.vue"),
    SvgInjector,
  },
  props: {
    organization: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      certificates: [] as ICertificate[] | null,
      certificatesLoaded: false,
      createModalVisible: false,
      search: "",
      isRouterAlive: true,
      userCrudFormValue: false,
    };
  },
  computed: {
    roles(): { [key: string]: string } {
      return this.$store.getters["user/roles"];
    },
    allowed() {
      // @ts-ignore
      return (this.roles.superuser || this.roles.admin) && [OrganizationTypes.RETAILER].includes(this.roles.organizationType as OrganizationTypes);
    },
    filteredList(): ICertificate[] {
      let results = this.certificates
        ? [...(this.certificates as ICertificate[]).sort(dynamicSort("-created_at"))]
        : [];

      if (this.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.name).includes(slugify(this.search)) ||
            res.id.includes(slugify(this.search))
          );
        });
      }

      return results;
    },
  },
  async mounted() {
    await this.fetchCertificates();
  },
  methods: {
    async fetchCertificates() {
      this.certificatesLoaded = false;

      await getCertificates({ consumer: this.$route.params.id })
        .then(res => {
          this.certificates = res.results;
        })
        .catch(err => console.error(err));

      this.certificatesLoaded = true;
    },

    async removeCertificate(cert: ICertificate) {
      this.$confirm(
        /* Message */
        `Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${cert.name}</code>?`,
        /* Title */
        this.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: this.$i18n.t("labels.delete") as string,
          cancelButtonText: this.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteCertificate(cert.id)
                .then(async res => {
                  Notification({
                    title: "Operation successful",
                    message: `The certificate ${res.name} deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  await this.fetchCertificates();
                })
                .catch(err => {
                  this.$message({
                    message: `${Object.values(err.response.data.errors)[0]}`,
                    center: true,
                    type: "error",
                  });
                })
                .finally(() => {
                  instance.confirmButtonLoading = false;
                  done();
                });
              /*  */
            } else {
              done();
            }
          },
        });
    },

    certificateAdded() {
      this.fetchCertificates();
      this.createModalVisible = false;
    },

    changeF(newVal: any) {
      this.userCrudFormValue = newVal;
    },

    closeDialog() {
      if (this.userCrudFormValue) {
        this.$confirm(
          /* Message */
          this.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          this.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: this.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                this.isRouterAlive = false;
                this.$nextTick(() => {
                  this.isRouterAlive = true;
                });
                this.createModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.createModalVisible = false;
        this.userCrudFormValue = false;
      }
    },
  },
  setup() {
    return { OrganizationTypes, formatDate };
  },
});
