var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-inline-flex align-items-center justify-content-start flex-wrap"},[_c('span',{staticClass:"d-inline-block mr-2 flex-1"},[_vm._v(" "+_vm._s(_vm.show ? _vm.value : _vm.walletSub(_vm.value, "***"))+" ")]),_c('button',{class:[
      'clipboard--show',
      _vm.show ? 'text-primary' : 'text-dark-l3'
    ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.show = !_vm.show}}},[_c('svg-injector',{attrs:{"src":require('@/assets/img/icons/eye.svg'),"height":"24px","width":"24px"}})],1),_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Copy to clipboard' }),expression:"{ content: 'Copy to clipboard' }"}],class:[
      'clipboard--copy',
      _vm.success ? 'text-success' : 'text-dark-l3'
    ],attrs:{"disabled":_vm.success},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyToClipboard.apply(null, arguments)}}},[_c('svg-injector',{attrs:{"src":_vm.success ? require('@/assets/img/icons/copy.svg') : require('@/assets/img/icons/copy.svg'),"height":"24px","width":"24px"}})],1),(_vm.pageUrl)?_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Visit Blockchain Explorer' }),expression:"{ content: 'Visit Blockchain Explorer' }"}],class:[
      'clipboard--visit',
      'text-dark-l3'
    ],attrs:{"href":("" + (_vm.pageUrl + _vm.value)),"target":"blank"}},[_c('svg-injector',{attrs:{"src":require('@/assets/img/icons/external.svg'),"height":"20px","width":"20px"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }