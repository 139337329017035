








































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { IRetailerDetails } from "@/lib/types/organizations/retailer";
import { updateRetailerData } from "@/api/organizations/retailer";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import { Notification } from "element-ui";
import { rootInstance } from "@/helpers/composition";
import { isEqual } from "lodash";

import VueScrollTo from "vue-scrollto";

export default defineComponent({
  name: "RetailerSettingsForm",
  components: {
    FormBase,
    FormItem,
    LocationForm: () => import("./LocationForm.vue"),
  },
  setup (props, vm) {
    const { root } = rootInstance();

    const retailer: IRetailerDetails = root.$store.state.retailer.details;

    const formData = reactive({
      name: retailer.name,
      website_url: retailer.website_url,
      is_registrant: retailer.is_registrant,
      is_active: retailer.is_active,
      location: retailer.location,
    });

    const fault = reactive({
      error: {},
    });

    const formRef = ref(null);

    const formSubmitted = ref(false);

    const updateRetailerFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await updateRetailerData(retailer.id, { ...formData })
        .then(() => {
          root.$store.dispatch("retailer/setRetailerDetails");

          Notification({
            title: "Operation successful",
            message: "Details updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    onMounted(() => {
      watch(
        () => root.$store.state.retailer.details,
        (newVal, oldVal) => {
          if (!isEqual(newVal, oldVal)) {
            formData.name = newVal.name;
            formData.website_url = newVal.website_url;
            formData.is_registrant = newVal.is_registrant;
            formData.is_active = newVal.is_active;
            formData.location = newVal.location;
          }
        },
        { deep: true }
      );

      watch(
        () => fault.error,
        () => {
          if (Object.keys(fault.error).length) {
            Object.keys(fault.error).forEach(item => {
              const formItem = document.querySelector(`.form__item[data-name="${item}"`);

              if (formItem) {
                formItem.classList.add("not-valid");
              }
            });

            const wrapper = (formRef.value as any).$el.parentNode.parentNode.parentNode;
            VueScrollTo.scrollTo(".el-dialog__body", 500, { container: wrapper });
          } else {
            document.querySelectorAll(".form__item").forEach(item => item.classList.remove("not-valid"));
          }
        },
        { deep: true }
      );
    });

    return { formData, formRef, updateRetailerFn, fault, formSubmitted };
  },
});
