














































import { rootInstance } from "@/helpers/composition";
import { formatDate } from "@/plugins/dayjs";
import { defineComponent, reactive, ref } from "@vue/composition-api";
import axios from "axios";
import { Notification } from "element-ui";

export default defineComponent({
  name: "GetFromEpias",
  components: {
    FormBase: () => import("../FormBase.vue"),
    DateTimePicker: () => import("../DateTimePicker.vue"),
  },
  props: {
    powerPlantId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { root } = rootInstance();

    const epiasForm = reactive({
      power_plant_id: props.powerPlantId,
      start_date: root.$store.state.period[0],
      end_date: root.$store.state.period[1].replace("23:59", "00:00"),
    });

    const fault = reactive({
      error: {},
    });

    const formSubmitted = ref(false);
    const formRef = ref(null);

    const getFromEpiasFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await axios({
        method: "post",
        url:
          process.env.VUE_APP_API_PATH +
          "/energy-data/production-data/get_from_epias_and_insert/",
        data: {
          ...epiasForm,
        },
      })
        .then(res => {
          Notification({
            title: "Operation successful",
            message: res.data.msg,
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          epiasForm.start_date = "";
          epiasForm.end_date = "";

          emit("onSuccess", true);
        })
        .catch(err => {
          const error = err.response.data;
          fault.error = { ...error };
        })
        .finally(() => {
          formSubmitted.value = false;
        });
    };

    return {
      epiasForm,
      fault,
      formSubmitted,
      formRef,
      getFromEpiasFn,
      formatDate,
    };
  },
});
