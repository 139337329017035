























































import { defineComponent } from "@vue/composition-api";
import { randomId } from "@/utils";
import { epiasApi } from "@/plugins/greenlink.js";

type EpiasBody = {
  eic: string;
  id: string;
  name: string;
  shortName: string;
}

export default defineComponent({
  name: "EpiasSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      epiasSite: null,
      options: [] as unknown as EpiasBody[],
      id: randomId(),
    };
  },
  async beforeMount() {
    await this.epiasData();
  },
  methods: {
    querySearch(queryString: string, cb: any) {
      const options = [...this.options];
      const results = queryString ? options.filter(this.createFilter(queryString)) : options;
      // call callback function to return suggestion objects
      cb(results);
    },
    createFilter(queryString: string) {
      return (site: any) => (site.shortName.toLowerCase().includes(queryString.toLowerCase()));
    },
    handleChange(e: any) {
      this.$emit("input", e);
    },
    handleSelect(e: any) {
      this.$emit("selected", e);
    },
    epiasData() {
      return new Promise<any>((resolve, reject) => {
        epiasApi.licensedProductionSitesOnEpiasRetrieve((error: any, _data: any, response: any) => {
          if (error) {
            reject(error.response);
          } else {
            this.options = response.body;
          }
        });
      });
    },
  },
});
