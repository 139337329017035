// eslint-disable-next-line
// @ts-ignore
import { usersApi } from "../plugins/greenlink.js";
import { IUserDetails } from "@/lib/types/user";
// import { BaseApiReturn } from "@/lib/types/base";

export async function getUserData () {
  return new Promise<IUserDetails>((resolve, reject) => {
    usersApi.usersUserDetailsRetrieve((error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function updateUserData (payload: Partial<IUserDetails>) {
  return new Promise<IUserDetails>((resolve, reject) => {
    usersApi.usersUserDetailsUpdate(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export async function deleteUserData (payload: string) {
  return new Promise<IUserDetails>((resolve, reject) => {
    usersApi.usersDestroy(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
