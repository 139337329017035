import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import "./directives";
import "./plugins/firebase";
import "./plugins/composition-api.js";
import "./plugins/vee-validate.js";
import "./plugins/vue-meta.js";
import "./plugins/element.js";
import "./plugins/dayjs";
import "./plugins/vue2-datepicker.js";
import "./plugins/portal-vue.js";
import "./plugins/vue-scrollto.js";
import "./plugins/apex-charts";
import "./components/components.js";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// import SentryRRWeb from "@sentry/rrweb";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import "vue2-datepicker/index.css";
import "./assets/styles/main.scss";

// Sentry implementation
// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new SentryRRWeb(),
//   ],
//   // beforeSend(event, hint) {
//   //   console.log(event, hint);
//   //   // Check if it is an exception, and if so, show the report dialog
//   //   // if (event.exception) {
//   //   //   Sentry.showReportDialog({ eventId: event.event_id });
//   //   // }
//   //   return event;
//   // },
//   environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
//   logErrors: process.env.VUE_APP_SENTRY_ENVIRONMENT !== "production",
//   denyUrls: ["auth.greenlink.energy"],
//   trackComponents: true,
//   attachProps: true,
//   timeout: 5000,
//   tracesSampleRate: process.env.VUE_APP_SENTRY_ENVIRONMENT === "production" ? 0.5 : 1.0,
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
