



























































































































































































import { rootInstance } from "@/helpers/composition";
import { defineComponent, reactive, ref, toRefs, watch, onMounted } from "@vue/composition-api";
import axios from "axios";
import { Upload } from "element-ui";
import XLSX from "xlsx";

interface IElementFile {
  name: string;
  percentage: number;
  raw: File;
  size: number;
  status: string;
  uid: number;
}

export default defineComponent({
  name: "ProductionSiteUploadForm",
  components: {
    FormBase: () => import("../FormBase.vue"),
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    producerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props: { producerId: string; }, { emit }) {
    const { root } = rootInstance();
    const uploadRef = ref<Upload | null>(null);
    const selectedFile = ref();

    const state = reactive({
      fileList: [],
      workbook: {} as XLSX.WorkBook,
      sheetName: "",
      sheetTitles: [] as string[],
    });
    let changeForm = false;
    const initialSelectedFile = ref();
    const form = reactive<{ [key: string]: any }>({
      producer: props.producerId,
      name_column_name: "",
      eic_column_name: "",
      production_start_column_name: "",
      amount_consumed_column_name: "",
      loading: false,
    });

    const progress = reactive<{ loaded: number; total: number; percentage: number; done: boolean; }>({
      loaded: 0,
      total: 0,
      percentage: 0,
      done: false,
    });

    const fault = reactive({
      error: {},
    });

    /**
     * ? reads the "XLSX" file
     * ? sets the "workbook" in the state
    **/
    const handleImport = (e: IElementFile) => {
      const f = e.raw;
      const reader = new FileReader();

      selectedFile.value = f;
      state.sheetName = "";

      reader.onload = e => {
        try {
          const data = new Uint8Array((e.target as any).result);
          const workbook = XLSX.read(data, { type: "array" });

          state.workbook = workbook;
        } catch {
          alert("An error occured during the file reading process");
        }
      };

      reader.readAsArrayBuffer(f);
    };

    /**
     * ? creates FormData(),
     * ? uploads the data via axios,
     * ? sets upload progress
    **/
    const handleUpload = async () => {
      form.loading = true;
      progress.done = false;

      const formData = new FormData();

      for (const item of Object.keys(form)) {
        formData.append(item, form[item]);
      }

      formData.append("file_list", selectedFile.value);

      await axios({
        method: "post",
        baseURL: process.env.VUE_APP_API_PATH + "/energy-data/production-data/upload-file/",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: function (progressEvent: ProgressEvent) {
          // Do whatever you want with the native progress event
          if (progressEvent.lengthComputable) {
            progress.total = progressEvent.total;
            progress.loaded = progressEvent.loaded;
            progress.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        },
      })
        .then((res: any) => {
          root.$alert(res.data.response.status, "Success", {
            confirmButtonText: "OK",
            type: "success",
            callback: () => {
              // location.reload();
              emit("onSuccess", true);
              progress.done = true;
              handleRemove();
            },
          });
        })
        .catch(err => {
          // fault.error = err.response.data;
          root.$message({
            message: err.response.data.errors.custom[0],
            center: true,
            type: "error",
          });

          progress.done = false;
        })
        .finally(() => {
          progress.percentage = 0;
          form.loading = false;
        });
    };

    const handleRemove = () => {
      for (const _i of Object.keys(progress)) {
        const i = _i as keyof typeof progress;

        if (typeof progress[i] === "number") (progress as any)[i] = 0;
        else if (typeof progress[i] === "boolean") (progress as any)[i] = false;
      }

      selectedFile.value = undefined;
      state.workbook = {} as XLSX.WorkBook;
      state.sheetTitles.length = 0;
      if (uploadRef.value !== null) uploadRef.value.clearFiles();
    };

    /**
     * ? checks the first row and gets the titles
     * ? pushes the titles into the sheetTitles array
    **/
    const handleSheetTitles = () => {
      const reg = new RegExp(/[a-zA-Z]1$/, "i");
      const sheetContent = state.workbook.Sheets[state.sheetName];

      Object.keys(sheetContent).forEach(key => {
        reg.test(key) && state.sheetTitles.push(sheetContent[key].v);
      });
    };
    onMounted(() => {
      emit("changeForm", changeForm);
      watch(
        () => state.sheetName,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            state.sheetTitles.length = 0;

            (() => {
              Object.keys(form).forEach(item => {
                if (!["producer", "file", "loading"].includes(item)) {
                  form[item] = "";
                }
              });
            })();

            handleSheetTitles();
          }
        }
      );
      watch(() => [selectedFile],
        ([newFile]) => {
          if ((newFile as any).value !== (initialSelectedFile as any).value) {
            changeForm = true;
          }
          // for (const key of Object.keys(newForm)) {
          //   if ((newForm as any)[key] !== (initialForm as any)[key]) {
          //     changeForm = true;
          //   }
          // }
          emit("changeForm", changeForm);
          changeForm = false;
        },
        { deep: true }
      );
    });
    return { ...toRefs(state), form, fault, handleImport, handleUpload, handleRemove, uploadRef, selectedFile, progress, changeForm, initialSelectedFile };
  },
});
