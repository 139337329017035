<template>
  <div class="datalist datalist--aggregated">
    <el-tabs v-model="activeTab">
      <el-tab-pane
        :label="$t('common.consumption')"
        name="consumption"
        v-if="consumptionData.length"
      >
        <el-table
          :data="consumptionData"
          row-key="id"
          max-height="650"
          class="aligned"
        >
          <el-table-column
            prop="hour"
            :label="$t('labels.hour')"
            sortable
          >
            <template v-slot:default="{ row }">
              {{ row.hour }}
              <!-- {{ ($index &lt; 23)
                  ? `${labels[$index]} - ${labels[$index + 1]}`
                  : `${labels[$index]} - ${labels[0]}`
              }} -->
            </template>
          </el-table-column>

          <el-table-column
            prop="total_amount_consumed"
            :label="`${$t('labels.sum_consumed')} [kW]`"
            sortable
          >
            <template v-slot:default="{ row }">
              {{ formatThousand(row.total_amount_consumed) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="`${$t('labels.sum_matched')} [kW]`"
          >
            <template v-slot:default="{ row }">
              {{ formatThousand(row.total_amount_matched_solar + row.total_amount_matched_wind + row.total_amount_matched_hydro + row.total_amount_matched_marine + row.total_amount_matched_thermal + row.total_amount_matched_solid + row.total_amount_matched_liquid + row.total_amount_matched_gaseous) }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('common.production')"
        name="production"
        v-if="productionData.length"
      >
        <el-table
          :data="productionData"
          max-height="650"
          row-key="id"
          class="aligned"
        >
          <el-table-column
            prop="hour"
            :label="$t('labels.hour')"
            sortable
          >
            <template v-slot:default="{ row }">
              {{ row.hour }}
              <!-- {{ calcLabel($index) }} -->
            </template>
          </el-table-column>

          <el-table-column
            prop="total_amount_produced"
            :label="`${$t('labels.sum_produced')} [kW]`"
            sortable
          >
            <template v-slot:default="{ row }">
              {{ formatThousand(row.total_amount_produced) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="total_amount_matched"
            :label="`${$t('labels.sum_matched')} [kW]`"
            sortable
          >
            <template v-slot:default="{ row }">
              {{ formatThousand(row.total_amount_matched) }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { seperateThousand } from "@/utils";

export default {
  name: "AggregatedDatalist",
  props: {
    consumptionData: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    productionData: {
      type: Array,
      default: () => ([]),
      required: false,
    },
  },
  data () {
    return {
      labels: ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"],
      activeTab:
        (this.consumptionData.length && !this.productionData.length)
          ? "consumption"
          : (!this.consumptionData.length && this.productionData.length)
            ? "production"
            : "consumption",
    };
  },
  methods: {
    formatThousand (val) {
      return seperateThousand(val);
    },
    calcLabel($index) {
      return (
        ($index < 23)
          ? `${this.labels[$index]} - ${this.labels[$index + 1]}`
          : `${this.labels[$index]} - ${this.labels[0]}`
      );
    },
  },
};
</script>
