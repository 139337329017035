






























import { capitalize, randomId } from "@/utils";
import { EnergySources } from "@/lib/types/base";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "EnergySourceSelect",
  components: {
    FormItem: () => import("../FormItem.vue"),
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      options: [] as { id: string; name: string }[],
      id: randomId(),
      attrs: {},
    };
  },
  watch: {
    "$props.required": {
      handler (newVal) {
        if (newVal !== null && newVal !== undefined) {
          this.attrs = {
            "vv-rules": "required",
          };
        } else {
          this.attrs = {};
        }
      },
      immediate: true,
    },
  },
  mounted () {
    Object.keys(EnergySources).forEach(source => {
      this.options.push({
        id: source,
        name: capitalize((EnergySources as Record<string, string>)[source]),
      });
    });
  },
  methods: {
    handleChange (e: string) {
      this.$emit("input", e);
    },
  },
});
