import { Commit, Dispatch, StoreOptions } from "vuex";
import { IConsumerState } from "./organizations/consumer";
import { IGridOperatorState } from "./organizations/grid_operator";
import { IRetailerState } from "./organizations/retailer";
import { IUserState } from "./user";

interface Obj {
  [key: string]: string
}

// TODO: REMOVE AFTER BACKEND CORRECTION
export interface IError {
  eic?: string;
  code: string;
  errors: {
    [key: string]: string | Obj;
  };
  name: string;
  status: number;
}

export enum Countries {
  TR = "Turkey",
  DE = "Germany"
}

export enum OrganizationTypes {
  RETAILER = "Retailer",
  PRODUCER = "Producer",
  GRIDOPERATOR = "GridOperator",
  CONSUMER = "Consumer",
  ISSUER = "Issuer",
  NA = "N/A"
}

export enum EnergySources {
  SOLAR = "Solar",
  WIND = "Wind",
  HYDRO = "Hydro-electric Head",
  MARINE = "Marine",
  THERMAL = "Thermal",
  SOLID = "Solid",
  LIQUID = "Liquid",
  GASEOUS = "Gaseous"
}

export enum TechnologyTypes {
  "Unspecified" = "Unspecified",
  "Onshore" = "Onshore",
  "Offshore" = "Offshore",
  "Photovoltaic" = "Photovoltaic",
  "Photovoltaic - roof mounted" = "Photovoltaic - roof mounted",
  "Photovoltaic - ground mounted" = "Photovoltaic - ground mounted",
  "Photovoltaic - classic silicon" = "Photovoltaic - classic silicon",
  "Photovoltaic - Aggregated Group" = "Photovoltaic - Aggregated Group",
  "Concentration" = "Concentration",
  "Run-of-river head installation" = "Run-of-river head installation",
  "Storage head installation" = "Storage head installation",
  "Pure pumped storage head installation" = "Pure pumped storage head installation",
  "Mixed pumped storage head" = "Mixed pumped storage head",
  "Tidal" = "Tidal",
  "Tidal - inshore" = "Tidal - inshore",
  "Tidal - offshore" = "Tidal - offshore",
  "Wave" = "Wave",
  "Wave - onshore" = "Wave - onshore",
  "Wave - offshore" = "Wave - offshore",
  "Currents" = "Currents",
  "Pressure" = "Pressure",
  "Thermal" = "Thermal",
  "Steam Turbine Open Cycle with CHP" = "Steam Turbine Open Cycle with CHP",
  "Steam Turbine Open Cycle without CHP" = "Steam Turbine Open Cycle without CHP",
  "Steam Turbine Closed Cycle with CHP" = "Steam Turbine Closed Cycle with CHP",
  "Steam Turbine Closed Cycle without CHP" = "Steam Turbine Closed Cycle without CHP",
  "Internal Combustion Engine" = "Internal Combustion Engine",
  "Organic Rankine Cycle" = "Organic Rankine Cycle",
  "Sterling Engine" = "Sterling Engine",
  "Municipal waste" = "Municipal waste",
  "Municipal waste - biogenic only" = "Municipal waste - biogenic only",
  "Industrial and commercial waste - biogenic only" = "Industrial and commercial waste - biogenic only",
  "Wood" = "Wood",
  "Wood - forestry products" = "Wood - forestry products",
  "Wood - forestry by-products & waste" = "Wood - forestry by-products & waste",
  "Animal fats" = "Animal fats",
  "Biomass from agriculture" = "Biomass from agriculture",
  "Biomass from agriculture - agricultural products" = "Biomass from agriculture - agricultural products",
  "Biomass from agriculture - agricultural by-products & waste" = "Biomass from agriculture - agricultural by-products & waste",
  "Co-fired with fossil fuel - wood" = "Co-fired with fossil fuel - wood",
  "Co-fired with fossil fuel - wood (forestry products)" = "Co-fired with fossil fuel - wood (forestry products)",
  "Co-fired with fossil fuel - wood (forestry by-products & waste)" = "Co-fired with fossil fuel - wood (forestry by-products & waste)",
  "Co-fired with fossil fuel - animal fats" = "Co-fired with fossil fuel - animal fats",
  "Co-fired with fossil fuel - biomass from agriculture" = "Co-fired with fossil fuel - biomass from agriculture",
  "Co-fired with fossil fuel - biomass from agriculture (agricultural products)" = "Co-fired with fossil fuel - biomass from agriculture (agricultural products)",
  "Co-fired with fossil fuel - biomass from agriculture (agricultural by-products and waste)" = "Co-fired with fossil fuel - biomass from agriculture (agricultural,by-products and waste)",
  "Municipal biodegradable waste" = "Municipal biodegradable waste",
  "Black liquor" = "Black liquor",
  "Pure plant oil" = "Pure plant oil",
  "Waste plant oil" = "Waste plant oil",
  "Refined vegetable oil" = "Refined vegetable oil",
  "Refined vegetable oil - biodiesel (mono-alkyl ester)" = "Refined vegetable oil - biodiesel (mono-alkyl ester)",
  "Refined vegetable oil - biogasoline (C6-C12 hydrocarbon)" = "Refined vegetable oil - biogasoline (C6-C12 hydrocarbon)",
  "Landfill gas" = "Landfill gas",
  "Sewage gas" = "Sewage gas",
  "Agricultural gas" = "Agricultural gas",
  "Agricultural gas - animal manure" = "Agricultural gas - animal manure",
  "Agricultural gas - energy crops" = "Agricultural gas - energy crops",
  "Gas from organic waste digestion" = "Gas from organic waste digestion",
  "Process gas - biogenic" = "Process gas - biogenic",
  "Geothermal" = "Geothermal",
  "Co-fired with fossil fuel - solar thermal" = "Co-fired with fossil fuel - solar thermal",
}

export interface IEnergySources {
  [EnergySources.SOLAR]: number[];
  [EnergySources.WIND]: number[];
  [EnergySources.HYDRO]: number[];
  [EnergySources.MARINE]: number[];
  [EnergySources.THERMAL]: number[];
  [EnergySources.SOLID]: number[];
  [EnergySources.LIQUID]: number[];
  [EnergySources.GASEOUS]: number[];
}

export type ChartDate = (string | Date)[];

export interface IListResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[] | null;
}

export interface IFilterQuery {
  limit?: number;
  offset?: number;
}

export interface IGlobalState {
  period: string[];
  theme: string;
  language: string;
  isOrganizationalMatching: boolean;
  txUrl: string;
  addressUrl: string;
  tokenUrl: string;
  user: IUserState;
  retailer?: IRetailerState;
  gridOperator?: IGridOperatorState;
  consumer?: IConsumerState;
}

// ! Base Types START
export type BaseObject<T> = { [key: string]: T };

export interface BaseApiReturn<T> {
  data: T;
  response: Response;
}

// ! Store Custom Types START
export type VuexState<T> = StoreOptions<T>["state"];

export interface VuexAction {
  dispatch: Dispatch;
  commit: Commit;
  state?: any;
  getters?: any;
  rootState?: any;
  rootGetters?: any;
}
