
























































import { defineComponent, onMounted, onUnmounted, reactive, toRefs } from "@vue/composition-api";

export default defineComponent({
  name: "Clipboard",
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
    pageUrl: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup (props, vm) {
    const state = reactive({
      buttonText: "Click to Copy",
      successText: "Copied!",
      copyText: "",
      show: false,
      success: false,
    });

    const onCopy = async () => {
      state.copyText = await navigator.clipboard.readText();
    };

    const write = (txt: string) => {
      state.copyText = txt;

      return navigator.clipboard.writeText(txt);
    };

    const copyToClipboard = () => {
      write(props.value);

      if (state.copyText === props.value) {
        state.success = true;
        vm.root.$message({
          type: "success",
          message: "The text is copied to the clipboard.",
          duration: 1000,
        });

        window.setTimeout(() => { state.success = false }, 1000);
      }
    };

    const showContent = () => {};

    const walletSub = (txt: string, truncator = "..."): string => {
      const first = txt.substring(0, 4);
      const mid = truncator.toString();
      const last = txt.length > 4 ? txt.substring(txt.length - 4, txt.length) : "";

      return first + mid + last;
    };

    onMounted(() => {
      window.addEventListener("copy", onCopy);
    });

    onUnmounted(() => {
      window.removeEventListener("copy", onCopy);
    });

    return { ...toRefs(state), copyToClipboard, showContent, walletSub };
  },
});
