import { getConsumerData } from "@/api/organizations/consumer";
import { STATE_CONSUMER } from "@/lib/constants";
import { VuexAction } from "@/lib/types/base";
import { IConsumerDetails, IConsumerState } from "@/lib/types/organizations/consumer";

export const moduleConsumer = {
  namespaced: true,

  // ! Do not mutate the constant (STATE_X) directly
  state: () => ({ ...STATE_CONSUMER }),

  mutations: {
    SET_CONSUMER_DETAILS (state: IConsumerState, payload: IConsumerDetails): void {
      state.details = { ...state.details, ...payload };
    },

    SET_CONSUMER_DETAILS_LOADED (state: IConsumerState, payload: boolean): void {
      state.details_loaded = payload;
    },
  },

  actions: {
    async setConsumerDetails ({ commit, rootState }: VuexAction): Promise<void> {
      const organizationId = rootState.user.details.organization;

      commit("SET_CONSUMER_DETAILS_LOADED", false);

      await getConsumerData(organizationId)
        .then(res => {
          commit("SET_CONSUMER_DETAILS", res);
        })
        .catch(() => {
          commit("SET_CONSUMER_DETAILS", { ...STATE_CONSUMER.details });
        })
        .finally(() => {
          commit("SET_CONSUMER_DETAILS_LOADED", true);
        });
    },
  },

  getters: {},
};
