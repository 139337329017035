// eslint-disable-next-line
// @ts-ignore
import { organizationsApi } from "../../plugins/greenlink";
import { IIssuerDetails } from "@/lib/types/organizations/issuer";
import { IListResponse } from "@/lib/types/base";

export function getIssuersList (opts?: Partial<IIssuerDetails>) {
  return new Promise<IListResponse<IIssuerDetails>>((resolve, reject) => {
    organizationsApi.organizationsIssuersList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getIssuerData (id: string) {
  return new Promise<IIssuerDetails>((resolve, reject) => {
    organizationsApi.organizationsIssuersRetrieve(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateIssuerData (id: string, patch: Partial<IIssuerDetails>) {
  return new Promise<IIssuerDetails>((resolve, reject) => {
    organizationsApi.organizationsIssuersUpdate(id, patch, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
