


































































import Vue from "vue";
import axios from "axios";
import { capitalize, snakeToTitle } from "@/utils";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { Message } from "element-ui";

type RelatedLogType = {
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  details: string;
  hasAdditionalFields: boolean;
  additionalFields: unknown;
};

type State = {
  isLoading: boolean;
  relatedLog: RelatedLogType | null;
};

const RelatedLogModel = data => {
  const {
    related_log_type_name,
    status,
    created_at,
    updated_at,
    details,
    ...additionalFields
  } = data;

  return {
    name: related_log_type_name,
    status,
    createdAt: created_at,
    updatedAt: updated_at,
    details,
    additionalFields,
    hasAdditionalFields: Object.keys(additionalFields).length > 0,
  };
};

const API = (() => {
  const basePath = process.env.VUE_APP_API_PATH;
  return {
    async getRelatedLog(relatedLogId: string): Promise<RelatedLogType | null> {
      try {
        const response = await axios({
          method: "get",
          url: `${basePath}/logs/activities/related_log/${relatedLogId}`,
        });
        return response ? RelatedLogModel(response.data) : null;
      } catch (e) {
        Message({
          message: "Error getting related log",
          center: true,
          type: "error",
        });
        return null;
      }
    },
  };
})();

export default Vue.extend({
  name: "RelatedLog",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): State {
    return {
      isLoading: true,
      relatedLog: null,
    };
  },
  async mounted() {
    this.relatedLog = await API.getRelatedLog(this.id);
    this.isLoading = false;
  },
  methods: {
    snakeToTitle,
    capitalize,
    formatDate,
    timeAgo,
  },
});
