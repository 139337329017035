import { ILocation } from "@/lib/types/location";

export const formattedAddress = (location: ILocation, format?: string) => {
  if (format !== "short") {
    return (
          `${location.building_number ? `${location.building_number}, ` : ""}` +
          `${location.street_name ? `${location.street_name}, ` : ""}` +
          `${location.neighborhood_name ? `${location.neighborhood_name}, ` : ""}` +
          `${location.district_name ? `${location.district_name}, ` : ""}` +
          `${location.province_name && location.province_name.toLowerCase() !== location.city.toLowerCase() ? `${location.province_name}, ` : ""}` +
          `${location.city ? `${location.city}, ` : ""}` +
          `${location.country ? `${location.country}` : ""}` +
          `${location.independent_section_code ? ` - ${location.independent_section_code}` : ""}`
    );
  } else {
    return (
          `${location.province_name && location.province_name.toLowerCase() !== location.city.toLowerCase() ? `${location.province_name}, ` : ""}` +
          `${location.city ? `${location.city}, ` : ""}` +
          `${location.country ? `${location.country}` : ""}` +
          `${location.independent_section_code ? ` - ${location.independent_section_code}` : ""}`
    );
  }
};
