<template>
  <el-popover
    placement="right"
    trigger="click"
    ref="popperRef"
    v-bind="$attrs"
  >
    <span class="hover-popper__content"><slot /></span>

    <button
      class="hover-popper__trigger"
      slot="reference"
    >
      {{ text }}
    </button>
  </el-popover>
</template>

<script>
export default {
  name: "HoverPopper",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
