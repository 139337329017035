export enum UserActivityCategories {
  "SmartContract" = "Smart Contract",
  "ConsumptionSite" = "Consumption Site",
  "ConsumptionSiteContract" = "Consumption Site Contract",
  "ProductionSite" = "Production Site",
  "ConsumptionDataPoint" = "Consumption Data Point",
  "ProductionDataPoint" = "Production Data Point",
  "EmissionFactor" = "Emission Factor",
  "MatchedDataPoint" = "Matched Data Point",
  "Location" = "Location",
  "UploadedCertificateFile" = "Uploaded Certificate File",
  "EnergySourcePreference" = "Energy Source Preference",
  "Retailer" = "Retailer",
  "Consumer" = "Consumer",
  "Producer" = "Producer",
  "GridOperator" = "Grid Operator",
  "Issuer" = "Issuer",
  "User" = "User",
}

export enum UserActivityMethods {
  "Added" = "Added",
  "Updated" = "Updated",
  "Deleted" = "Deleted",
}
