




































































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Notification } from "element-ui";

import FormBase from "../FormBase.vue";
import FormItem from "../FormItem.vue";
import { createConsumptionSiteData, updateConsumptionSiteData } from "@/api/energy-assets/consumption_site";
import { ILocation } from "@/lib/types/location";
import { IConsumptionSiteDetails } from "@/lib/types/energy-assets/consumption_site";
import { formattedAddress } from "../../helpers/formats";
import { cloneDeep } from "lodash";
import { rootInstance } from "@/helpers/composition";

export default defineComponent({
  name: "ConsumptionSiteCrudForm",
  components: {
    FormBase,
    FormItem,
    ConsumerSelect: () => import("@/components/selectbox/ConsumerSelect.vue"),
    GridOperatorSelect: () => import("@/components/selectbox/GridOperatorSelect.vue"),
    IssuerSelect: () => import("@/components/selectbox/IssuerSelect.vue"),
    LocationForm: () => import("./LocationForm.vue"),
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    operation: {
      type: String,
      default: null,
      required: true,
      validator: function (val: string) {
        return ["create", "update"].indexOf(val) !== -1;
      },
    },
    id: {
      type: String,
      default: null,
      required: true,
    },
    consumerId: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup (props: { details: IConsumptionSiteDetails; consumerId: string; }, vm: any) {
    const { root } = rootInstance();

    const editLocationModal = ref(false);
    const issuer_count = ref(0);
    const propsDetails = cloneDeep(props.details);

    const check = (payload: any) => {
      if (typeof payload === "string") return payload;
      else if (typeof payload === "object" && payload !== null) return payload.id;
      else return null;
    };

    const issuerCount = (e: number) => {
      issuer_count.value = e;
    };

    const formData = reactive<Partial<IConsumptionSiteDetails>>({
      name: propsDetails.name || "",
      eic: propsDetails.eic || "",
      consumer: props.consumerId, // check(propsDetails.consumer)
      grid_operator: check(propsDetails.grid_operator) || null,
      issuer: check(propsDetails.issuer),
      location: propsDetails.location || {
        latitude: null,
        longitude: null,
        country: "" as ILocation["country"],
        city: "",
        province_name: "",
        province_code: "",
        district_name: "",
        district_code: "",
        neighborhood_name: "",
        neighborhood_code: "",
        street_name: "",
        street_code: "",
        building_number: "",
        independent_section_code: "",
        updated_at: "",
        created_at: "",
        id: "",
      } as ILocation,
      is_active: propsDetails.is_active !== undefined ? propsDetails.is_active : true,
    });
    let initialForm = {};
    let changeForm = false;
    const fault = reactive({
      error: {},
    });

    const formSubmitted = ref(false);
    const formRef = ref(null);

    const createConsumptionSiteFn = async () => {
      fault.error = {};
      formSubmitted.value = true;
      vm.emit("formData", formData);
      await createConsumptionSiteData(formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Consumption site created successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });
          vm.emit("onSuccess", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    const updateConsumptionSiteFn = async () => {
      fault.error = {};
      formSubmitted.value = true;

      await updateConsumptionSiteData(root.$route.params.id, formData)
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Consumption site updated successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          vm.emit("onSuccess", true);
        })
        .catch(err => {
          fault.error = { ...err.body };
        });

      formSubmitted.value = false;
    };

    onMounted(() => {
      vm.emit("changeForm", changeForm);
      initialForm = cloneDeep(formData);
      watch(
        () => formData,
        newVal => {
          if (formData && formData.location) {
            if ((formData.location.latitude as unknown as string) === "") { formData.location.latitude = null }
            if ((formData.location.longitude as unknown as string) === "") { formData.location.longitude = null }
          }
          vm.emit("changeForm", JSON.stringify(newVal) !== JSON.stringify(initialForm));
          changeForm = false;
        },
        { deep: true }
      );
    });
    return { formData, formRef, issuer_count, issuerCount, editLocationModal, createConsumptionSiteFn, updateConsumptionSiteFn, fault, formSubmitted, formattedAddress, changeForm, initialForm };
  },
});
