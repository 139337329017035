var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"consumption-matching-table"},[(_vm.count)?_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.count)+" data found between "+_vm._s(_vm.dates)+". ")]):_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t('labels.no_results_found'))+" ")]),_c('el-table',{ref:"table",attrs:{"row-key":"id","data":_vm.results,"max-height":"638"},on:{"row-click":_vm.rowClick,"expand-change":_vm.expandChange}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ExpandDetails',{attrs:{"data":row}})]}}])}),_c('el-table-column',{staticClass:"text-left",attrs:{"prop":"consumption_start","label":_vm.$t('common.date'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(row.consumption_start))+" ")]),_c('div',{staticClass:"text-xs font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.formatDate(row.consumption_start))+" ")])]}}])}),_c('el-table-column',{attrs:{"class-name":"text-right","label":_vm.$t('labels.percentage_matched')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"percentage-box flex-1"},[_c('span',{staticClass:"d-inline-block",style:(("width: " + (((_vm.amountMatchedGreen(row) / (row.amount_consumed / 1000)) * 100)) + "%;"))})]),_c('span',{staticClass:"d-inline-block w-50"},[_vm._v(" "+_vm._s(row.amount_consumed > 0 ? ((((_vm.amountMatchedGreen(row) / (row.amount_consumed / 1000)) * 100).toFixed(2)) + " %") : "-")+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"amount_consumed","class-name":"text-right pr-2","label":((_vm.$t('common.consumption')) + " (kWh)")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.seperateThousand((row.amount_consumed / 1000)))+" ")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"pager","page-size":24,"total":_vm.count,"hide-on-single-page":""},on:{"current-change":_vm.currentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }