





























































































import VueScrollTo from "vue-scrollto";
import { capitalize, randomId } from "@/utils";
import { defineComponent, PropType } from "@vue/composition-api";
import { IError } from "@/lib/types/base";

export default defineComponent({
  name: "FormBase",
  model: {
    prop: "form",
    event: "update:change",
  },
  props: {
    id: {
      type: String,
      default: randomId(),
    },
    formClass: {
      type: [String, Array, Object],
      default: null,
    },
    headerClass: {
      type: [String, Array, Object],
      default: null,
    },
    buttonClass: {
      type: [String, Array, Object],
      default: null,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    headline: {
      type: String,
      default: "",
    },
    headlineTag: {
      type: String,
      default: "h1",
    },
    errors: {
      type: Object as PropType<IError>,
      default: () => ({}),
    },
    submit: {
      type: Function,
      default: () => ({}),
      required: true,
    },
    loading: {
      type: Boolean,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: "",
      required: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrapper: null as unknown as Element,
    };
  },
  computed: {
    errKeys(): string[] {
      return Object.keys(this.errors).length ? Object.keys((this.errors as IError).errors) : [];
    },
  },
  mounted() {
    const formRef = this.$refs.formRef;
    /* eslint-disable-next-line */
    const that = this;

    const listenerFn = function () {
      const invalidItem = (formRef as Element).querySelector(".not-valid");

      document.querySelectorAll(".el-dialog__wrapper").forEach(item => {
        if ((item as HTMLElement).style.display !== "none") {
          that.wrapper = item;
        }
      });

      if (invalidItem) {
        VueScrollTo.scrollTo(
          invalidItem,
          300,
          { container: that.wrapper }
        );
      }
    };

    if (formRef) {
      (formRef as Element).addEventListener("submit", listenerFn.bind(this), false);
    }
  },
  watch: {
    errors: {
      handler(errors) {
        if (errors.errors && Object.keys(errors.errors).length) {
          Object.keys(errors.errors).forEach(item => {
            const formItem = document.querySelector(`#${this.id} .form__item[data-name="${item}"`);

            if (formItem) {
              formItem.classList.add("not-valid");
            }
          });

          VueScrollTo.scrollTo(
            this.$refs.formRef as Element,
            300,
            { container: this.wrapper }
          );
        } else {
          document.querySelectorAll(".form__item").forEach(item => item.classList.remove("not-valid"));
        }
      },
    },
  },
  setup() {
    return { capitalize };
  },
});
