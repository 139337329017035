<template>
  <div class="theme-switcher d-inline-flex align-items-center justify-content-start">
    <el-switch
      active-value="dark"
      inactive-value="light"
      active-text="Dark"
      inactive-text="Light"
      v-model="theme"
    ></el-switch>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from "@vue/composition-api";

export default {
  name: "ThemeSwitcher",
  setup (props, vm) {
    const state = reactive({
      theme: vm.root.$store.state.theme,
    });

    watchEffect(() => {
      vm.root.$store.dispatch("setCurrentTheme", state.theme);
      localStorage.setItem("theme", state.theme);
    });

    return { ...toRefs(state) };
  },
};
</script>
