// eslint-disable-next-line
// @ts-ignore
import { organizationsApi } from "../../plugins/greenlink";
import { IProducerDetails } from "@/lib/types/organizations/producer";
import { IFilterQuery, IListResponse } from "@/lib/types/base";

export function getProducersList (opts?: Partial<IProducerDetails> & IFilterQuery) {
  return new Promise<IListResponse<IProducerDetails>>((resolve, reject) => {
    organizationsApi.organizationsProducersList(opts, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getProducerData (id: string) {
  return new Promise<IProducerDetails>((resolve, reject) => {
    organizationsApi.organizationsProducersRetrieve(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function createProducerData (payload: Partial<IProducerDetails>) {
  return new Promise<IProducerDetails>((resolve, reject) => {
    organizationsApi.organizationsProducersCreate(payload, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function updateProducerData (id: string, patch: Partial<IProducerDetails>) {
  return new Promise<IProducerDetails>((resolve, reject) => {
    organizationsApi.organizationsProducersUpdate(id, patch, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteProducerData (id: string) {
  return new Promise<IProducerDetails>((resolve, reject) => {
    organizationsApi.organizationsProducersDestroy(id, (error: any, _data: any, response: any) => {
      if (error) {
        reject(error.response);
      } else {
        resolve(response.body);
      }
    });
  });
}
