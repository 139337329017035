





































































































import { reactive, ref, defineComponent, computed, onMounted, nextTick } from "@vue/composition-api";
import { Notification } from "element-ui";
import axios from "axios";
import { capitalize, dynamicSort } from "@/utils";
import { IError } from "@/lib/types/base";

interface ICS {
  city: string;
  country: string;
  district: string;
  eic: string;
  latitude: number;
  longitude: number;
  name: string;
  selectable: boolean;
}

export default defineComponent({
  name: "ConsumptionSitesList",
  props: {
    sitesList: {
      type: Array,
      default: () => ([]),
      required: false,
    },
    consumer: {
      type: String,
      default: "",
      required: true,
    },
  },
  // @ts-ignore
  setup(props: { sitesList: ICS[]; consumer: string; }, { emit }) {
    const multipleTable = ref<any>(null);
    const multipleSelection: any = ref([]);
    const tableData = ref(props.sitesList.sort(dynamicSort("-selectable")));
    const addedBefore = computed(() => tableData.value.filter(site => !site.selectable));

    const state = reactive<{ error: Partial<IError["errors"]>, isLoading: boolean }>({
      error: {},
      isLoading: false,
    });

    const handleSelectionChange = (val: ICS[]) => {
      multipleSelection.value = [];

      if (val.length > 1) {
        val.forEach((element: any) => {
          if (element.selectable) {
            multipleSelection.value.push(element);
          }
        });
      } else {
        multipleSelection.value = val;
      }
    };

    const tableRowClassName = (row: any) => {
      if (!row.row.selectable) {
        return "site-disabled";
      }
      return "";
    };

    const createConsumptionSiteFn = async () => {
      state.isLoading = true;
      state.error = {};

      const mappedArrayData = multipleSelection.value.map((element: ICS) => {
        return {
          name: element.name,
          eic: element.eic,
          grid_operator: null,
          issuer: null,
          location: {
            country: element.country,
            city: element.city,
            district_name: element.district,
            longitude: element.longitude,
            latitude: element.latitude,
          },
          consumer: props.consumer,
          is_active: true,
        };
      });

      await axios({
        method: "post",
        url: process.env.VUE_APP_API_PATH + "/energy-assets/consumption-sites/many/",
        data: mappedArrayData,
      })
        .then(() => {
          Notification({
            title: "Operation successful",
            message: "Consumption sites created successfully",
            type: "success",
            duration: 5000,
            showClose: true,
            position: "bottom-left",
          });

          emit("input", true);
        })
        .catch(err => {
          const error = err.response.data;
          state.error = { ...error };
        })
        .finally(() => {
          state.isLoading = false;
        });
    };

    onMounted(() => {
      nextTick(() => {
        multipleTable.value.$el.querySelectorAll(".site-disabled").forEach(el => {
          el.querySelector("td:first-child").style.opacity = "0";
        });
      });
    });

    return {
      state,
      addedBefore,
      multipleTable,
      multipleSelection,
      handleSelectionChange,
      tableData,
      createConsumptionSiteFn,
      capitalize,
      tableRowClassName,
    };
  },
});
