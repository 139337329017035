<template>
  <div class="card">
    <div class="card__header">
      <slot name="title">
        <component
          :is="titleTag"
          class="card__title"
        >
          <template v-if="titleClick">
            <router-link :to="{ name: titleClick }">
              {{ title }}
            </router-link>
          </template>
          <template v-else-if=" titleType === 'chart' ">
            <el-select
              class="select"
              v-model="value"
              @change="$emit('chart-change', value)"
              size="small"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else>
            {{ title }}
          </template>
        </component>
      </slot>

      <div class="d-flex align-items-center justify-content-end">
        <slot name="actions"></slot>

        <button
          v-if="info"
          v-tippy="{
            content: info,
            trigger: 'click',
            hideOnClick: 'toggle',
            placement: 'left-start',
            maxWidth: 280,
            arrow: true,
            interactive: true,
            theme: 'material',
          }"
        >
          <svg-injector
            class="text-primary"
            :src="require('@/assets/img/icons/info.svg')"
            alt=""
            height="28px"
          ></svg-injector>
        </button>
      </div>
    </div>

    <div class="card__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    titleTag: {
      type: String,
      default: "h3",
    },
    titleClick: {
      type: String,
      default: null,
    },
    titleType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: "Stacked Area Chart",
      options: [
        {
          value: "Aggregated Matching Chart",
          label: "Aggregated Matching Chart",
        },
        {
          value: "Stacked Area Chart",
          label: "Stacked Area Chart",
        },
      ],
    };
  },
};
</script>
