import { IListResponse } from "@/lib/types/base";
import axios from "axios";

export interface ICertificate {
  id: string;
  name: string;
  upload: string;
  content_type: string;
  eac_type: string;
  consumer: string;
  producer: string;
  period_start: string;
  period_end: string;
  created_at: string;
  updated_at: string;
}

export type ICertificatePayload = Omit<ICertificate, "id" | "created_at" | "updated_at">;

export async function getCertificates(params: { consumer: string; }) {
  return new Promise<IListResponse<ICertificate>>((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_PATH + "/logs/certificates", { params })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function createCertificate(payload: ICertificatePayload) {
  return new Promise<ICertificate>((resolve, reject) => {
    axios.post(
      process.env.VUE_APP_API_PATH + "/logs/certificates",
      payload,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    )
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function deleteCertificate(id: string) {
  return new Promise<ICertificate>((resolve, reject) => {
    axios.delete(process.env.VUE_APP_API_PATH + `/logs/certificates/${id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
